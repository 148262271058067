const monthNumberToName = (month: number): string => {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    case 0:
      return "December";
    case -1:
      return "November";
    case -2:
      return "October";
    case -3:
      return "September";
    case -4:
      return "August";
    case -5:
      return "July";
    case -6:
      return "June";
    case -7:
      return "May";
    case -8:
      return "April";
    case -9:
      return "March";
    case -10:
      return "February";
    case -11:
      return "January";
  }

  throw new Error("Invalid month number");
};

export default monthNumberToName;
