import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";

import LoginForm from "./loginForm";
import useStyles from "./styles";
import AuthGraphQL from "@graphql/authentication.resolver";
import { setUserData } from "@redux/reducers/user.reducer";
import { useAppDispatch } from "@redux/hooks";

export interface LoginInput {
  email: string;
  password: string;
}

const Login: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState<string>();

  const [login, { loading, error, data }] = useLazyQuery(
    AuthGraphQL.queries.login,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      if (data.login?.accessToken?.accessToken) {
        dispatch(
          setUserData({
            ...data.login.user,
            ...data.login.accessToken,
          })
        );
        history.push("/dashboard");
      } else {
        setLoginError(
          data?.login?.messages?.[0] ||
            "Sorry, the email or password didn't work"
        );
      }
    }
  }, [data, dispatch, history, error]);

  const onSubmit = (values: LoginInput) => {
    setLoginError(undefined);
    login({
      variables: {
        user: {
          ...values,
          backoffice: true,
        },
      },
    });
  };

  const handleCloseError = () => {
    setLoginError(undefined);
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12} md={12}>
        <Typography variant="h2" className={classes.title}>
          Sign in with email
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <LoginForm
          onSubmit={onSubmit}
          loading={loading}
          loginError={loginError}
          handleClose={handleCloseError}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
