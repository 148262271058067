import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/ClearRounded";
import RefreshIcon from "@material-ui/icons/Refresh";
import debounce from "lodash/debounce";
import { isMobile } from "react-device-detect";

import { NestedObject } from "@models/common";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import { FiltersChips, FilterChipData } from "./FiltersChips";
import FiltersDialog from "./FiltersDialog";
import useStyles from "./styles";

interface FiltersProps {
  optionsGroups: FiltersOptionsGroup[];
  filters: NestedObject;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  searchBarLabel?: string;
  searchBarId?: string | string[];
  onChangeFilters: (filters: NestedObject) => void;
  onRefresh: () => void;
}

const Filters: React.FC<FiltersProps> = ({
  optionsGroups,
  filters,
  primaryButton,
  secondaryButton,
  searchBarLabel,
  searchBarId,
  onChangeFilters,
  onRefresh,
}) => {
  const classes = useStyles();

  let initialName: string | undefined;

  if (searchBarId) {
    if (typeof searchBarId === "string") {
      initialName = filters[searchBarId];
    } else {
      initialName = filters[searchBarId[0]];
    }
  }

  const [values, setValues] = useState<NestedObject>(filters);
  const [name, setName] = useState(initialName);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    updateFilters(values);
  }, [values]);

  const updateFilters = (values: NestedObject) => {
    onChangeFilters(values);
  };

  const setNameFilterDebounce = useRef(debounce(setValues, 1000));

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (searchBarId) {
      if (typeof searchBarId === "string") {
        setNameFilterDebounce.current({
          ...values,
          [searchBarId]: event.target.value,
        });
      } else {
        const searchValues: NestedObject = {};
        searchBarId.forEach((id) => {
          searchValues[id] = event.target.value;
        });
        setNameFilterDebounce.current({
          ...values,
          ...searchValues,
        });
      }
    }
  };

  const handleClickClear = () => {
    setName("");
    if (searchBarId) {
      if (typeof searchBarId === "string") {
        setValues({ ...values, [searchBarId]: "" });
      } else {
        const searchValues: NestedObject = {};
        searchBarId.forEach((id) => {
          searchValues[id] = "";
        });
        setValues({ ...values, ...searchValues });
      }
    }
  };

  const handleDeleteFilter = (chipToDelete: FilterChipData) => {
    if (typeof values[chipToDelete.filterId] === "string") {
      setValues({ ...values, [chipToDelete.filterId]: "" });
    } else if (Array.isArray(values[chipToDelete.filterId])) {
      setValues({
        ...values,
        [chipToDelete.filterId]: values[chipToDelete.filterId].filter(
          (item: string) => item !== chipToDelete.value
        ),
      });
    }
  };

  const handleShowFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = (newValues?: NestedObject) => {
    setAnchorEl(null);

    if (newValues) {
      setValues({ ...values, ...newValues });
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const open = Boolean(anchorEl);

  return (
    <Grid container>
      <Grid item xs={12} md={9}>
        <Grid container>
          <Grid item xs={12} md={12} className={classes.filtersContainer}>
            {searchBarId && (
              <FormControl variant="outlined" className={classes.searchBar}>
                <InputLabel htmlFor="users-filter-name">
                  {searchBarLabel}
                </InputLabel>
                <OutlinedInput
                  id="users-filter-name"
                  label={searchBarLabel}
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  endAdornment={
                    name && (
                      <InputAdornment position="end">
                        {name && (
                          <IconButton
                            aria-label="clear-name-filter"
                            onClick={handleClickClear}
                            onMouseDown={handleMouseDown}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            )}
            {optionsGroups.length > 0 && (
              <IconButton
                aria-describedby="filters-popover"
                className={classes.filtersBtn}
                color="primary"
                aria-label="show-filters"
                onClick={handleShowFilters}
                onMouseDown={handleMouseDown}
              >
                <FilterIcon />
              </IconButton>
            )}
            <IconButton
              className={classes.filtersBtn}
              color="primary"
              aria-label="refresh-data"
              onClick={onRefresh}
              onMouseDown={handleMouseDown}
            >
              <RefreshIcon />
            </IconButton>
            <FiltersDialog
              anchorEl={anchorEl || null}
              options={optionsGroups}
              open={open}
              onClose={handleCloseFilters}
              values={values}
            />
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item xs={12} md={3} className={classes.buttonsContainer}>
          {secondaryButton}
          {primaryButton}
        </Grid>
      )}
      <FiltersChips
        filters={filters}
        filtersGroups={optionsGroups}
        onDeleteFilter={handleDeleteFilter}
      />
    </Grid>
  );
};

export default Filters;
