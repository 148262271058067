import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import clsx from "clsx";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import DateTimePickerTextField from "@mui/material/TextField";
import { useParams } from "react-router";

import ShowcasesGraphQL from "@graphql/showcases.resolver";
import VideosGraphQL from "@graphql/videos.resolver";
import { ShowcaseCreate } from "@models/showcase.model";
// import { timeHelpers } from "@helpers";
import getShowcaseInputValues from "@helpers/getShowcaseInputValues";
import { ShowcaseFormProps } from "./showcaseCreate";
import useStyles from "./styles"; // showcaseEditStyles
import CollectionSelector from "@components/CollectionSelector";
import UserSelector from "@components/UserSelector";
import { SalesEvent } from "@models/salesEvent.model";
import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import ConfirmDialog from "@components/ConfirmDialog";
import Videos from "@components/Videos";
import InterestsSelector from "@components/InterestsSelector";
import { Interest } from "@models/interest.model";

export type VideoDto = {
  id: string;
  published: boolean;
  url: string;
};

const ShowcaseEditForm: React.FC<ShowcaseFormProps> = ({
  id,
  showcaseId,
  backButton,
  goBackToShowcases,
  dispatchAlert,
}) => {
  // const classes = showcaseEditStyles();
  const pageClasses = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [published, setPublished] = useState<boolean>(false);
  const [collectionId, setCollectionId] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [videos, setVideos] = useState<VideoDto[]>();
  const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const [startDateError, setStartDateError] = useState<boolean>(false);
  const salesEvents: SalesEvent[] = useAppSelector(
    (state: RootState) => state.salesEvents.salesEvents
  );
  const salesEvent = salesEvents.find(
    (salesEvent: SalesEvent) => salesEvent.id === params.id
  );

  const [
    getShowcase,
    { loading: loadingShowcase, error: errorShowcase, data: showcaseData },
  ] = useLazyQuery(ShowcasesGraphQL.queries.getShowcase, {
    fetchPolicy: "network-only",
  });

  const getData = () => {
    const variables = {
      variables: {
        id: showcaseId,
      },
    };
    getShowcase(variables);
  };

  useEffect(() => {
    getData();
  }, [showcaseId]);

  useEffect(() => {
    if (errorShowcase) {
      dispatchAlert("Error getting Showcase data.", "error");
    }
  }, [errorShowcase]);

  useEffect(() => {
    if (showcaseData) {
      if (!showcaseData.getShowcase?.error) {
        const {
          published,
          startDate,
          endDate,
          defaultCollection,
          userEmail,
          videos,
          interests,
        } = showcaseData.getShowcase;
        if (published) setPublished(published);
        if (startDate) setStartDate(startDate);
        if (endDate) setEndDate(endDate);
        if (defaultCollection) setCollectionId(defaultCollection);
        if (userEmail) setUserEmail(userEmail);
        if (videos) setVideos(videos);
        if (interests) setSelectedInterests(interests);
      }
      if (showcaseData.getShowcase?.error) {
        dispatchAlert(showcaseData.getShowcase.messages[0], "error");
      }
    }
  }, [showcaseData]);

  const [
    updateShowcase,
    {
      loading: updatingShowcase,
      error: errorUpdating,
      data: updateShowcaseData,
    },
  ] = useMutation(ShowcasesGraphQL.mutations.updateShowcase, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorUpdating) {
      dispatchAlert("Unexpected error updating the Showcase", "error");
    }
  }, [errorUpdating]);

  useEffect(() => {
    if (updateShowcaseData) {
      if (!updateShowcaseData.updateShowcase?.error) {
        dispatchAlert("Your Showcase has been sucesfully updated.", "success");
        goBackToShowcases();
      }
      if (updateShowcaseData.updateShowcase?.error) {
        dispatchAlert(updateShowcaseData.updateShowcase?.messages[0], "error");
      }
    }
  }, [updateShowcaseData]);

  const handleSaveShowcase = () => {
    const showcase: ShowcaseCreate = getShowcaseInputValues({
      startDate,
      endDate,
      published,
      collectionId,
      userEmail,
      interests: selectedInterests,
    });
    updateShowcase({
      variables: {
        input: {
          id: showcaseId,
          ...showcase,
        },
      },
    });
  };

  useEffect(() => {
    evalDates();
  }, [startDate, endDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue || new Date());
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue || new Date());
  };

  const evalDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  const togglePublished = () => {
    setPublished(!published);
  };

  const handleSelectCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  const handleSelectUser = (userEmail: string) => {
    setUserEmail(userEmail);
  };

  const deleteShowcaseRequest = () => {
    setOpenConfirmDialog(true);
  };

  const onCloseConfirmDialog = (confirm: boolean) => {
    setOpenConfirmDialog(false);
    if (confirm) {
      deleteShowcase({
        variables: {
          input: {
            id: showcaseId,
          },
        },
      });
    }
  };

  const [
    deleteShowcase,
    {
      loading: deletingShowcase,
      error: deleteShowcaseError,
      data: deleteShowcaseData,
    },
  ] = useMutation(ShowcasesGraphQL.mutations.deleteShowcase, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (deleteShowcaseError) {
      dispatchAlert("Error deleting showcase", "error");
    }
  }, [deleteShowcaseError]);

  useEffect(() => {
    if (deleteShowcaseData) {
      if (!deleteShowcaseData.deleteShowcase?.error) {
        dispatchAlert("Showcase has been succesfully deleted.", "success");
        history.push(`/sales-event/${params.id}/showcases`);
      }
      if (deleteShowcaseData.deleteShowcase?.error) {
        dispatchAlert(deleteShowcaseData.deleteShowcase?.messages[0], "error");
      }
    }
  }, [deleteShowcaseData]);

  const onToggleEnabled = (video: VideoDto) => {
    setVideoPublished({
      variables: {
        input: {
          id: video.id,
          published: !video.published,
        },
      },
    });
  };

  const [
    setVideoPublished,
    {
      loading: loadingSetVideoPublished,
      error: errorSetVideoPublished,
      data: dataSetVideoPublished,
    },
  ] = useMutation(VideosGraphQL.mutations.toggleEnabled, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorSetVideoPublished) {
      dispatchAlert("Error updating video published state", "error");
    }
  }, [errorSetVideoPublished]);

  useEffect(() => {
    if (dataSetVideoPublished) {
      if (!dataSetVideoPublished.setVideoPublished?.error) {
        dispatchAlert("Video has been succesfully updated.", "success");
        const { id, published } = dataSetVideoPublished.setVideoPublished;
        setVideos(
          videos?.map((video) => ({
            ...video,
            published: video.id === id ? published : video.published,
          }))
        );
      }
      if (dataSetVideoPublished.setVideoPublished?.error) {
        dispatchAlert(deleteShowcaseData.deleteShowcase?.messages[0], "error");
      }
    }
  }, [dataSetVideoPublished]);

  return loadingShowcase || deletingShowcase || loadingSetVideoPublished ? (
    <Grid item xs={12} className={pageClasses.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <>
      <Grid
        item
        xs={12}
        className={clsx(
          pageClasses.spacedContainer,
          pageClasses.smallBottomMargin
        )}
      >
        {backButton}
        <div className={pageClasses.toastContainer}>
          <Button
            className={pageClasses.deleteShowcase}
            variant="contained"
            type="button"
            onClick={deleteShowcaseRequest}
          >
            Delete Showcase
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={handleSaveShowcase}
            disabled={updatingShowcase || startDateError}
          >
            {!updatingShowcase ? "Save" : <CircularProgress size={25} />}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} className={pageClasses.bottomMargin}>
        <Grid container>
          <div className={pageClasses.row}>
            <Typography variant="h1" color="textPrimary" gutterBottom={true}>
              Edit a Showcase
            </Typography>
            <FormControlLabel
              className={pageClasses.switch}
              control={
                <Switch
                  checked={published}
                  onChange={togglePublished}
                  color="secondary"
                />
              }
              label={published ? "Published Showcase" : "Draft"}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={pageClasses.bottomMargin}>
          <Grid container spacing={5}>
            <UserSelector
              selectedUserEmail={userEmail}
              headerTitle="Select user for the showcase stream"
              onChangeSelectedUserEmail={handleSelectUser}
            />
          </Grid>
          <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
            <Videos playlist={videos} onToggleEnabled={onToggleEnabled} />
          </Grid>
          <Grid container spacing={5}>
            <InterestsSelector
              selectedInterests={selectedInterests}
              headerTitle="Select interests for event"
              onChangeSelection={(selection: Interest[]) => {
                setSelectedInterests(selection);
              }}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Grid
              className={clsx(
                pageClasses.bottomMargin,
                pageClasses.rowContainer
              )}
            >
              <div className={pageClasses.columnContainer}>
                <DateTimePicker
                  label="Start Date"
                  value={startDate}
                  minDateTime={salesEvent?.startDate}
                  maxDateTime={salesEvent?.endDate}
                  onChange={handleStartDateChange}
                  onAccept={evalDates}
                  disableMaskedInput
                  renderInput={(params) => (
                    <DateTimePickerTextField
                      {...params}
                      style={{
                        backgroundColor: "white",
                        marginRight: "60px",
                        marginTop: "1em",
                      }}
                    />
                  )}
                />
                <div className={pageClasses.timeError}>
                  {startDateError && `Start date should be before end date`}
                </div>
              </div>
              <div className={pageClasses.columnContainer}>
                <DateTimePicker
                  label="End Date"
                  value={endDate}
                  minDateTime={salesEvent?.startDate}
                  maxDateTime={salesEvent?.endDate}
                  onChange={handleEndDateChange}
                  onAccept={evalDates}
                  disableMaskedInput
                  renderInput={(params) => (
                    <DateTimePickerTextField
                      {...params}
                      style={{ backgroundColor: "white", marginTop: "1em" }}
                    />
                  )}
                />
                <div className={pageClasses.timeError}>
                  {startDateError && `End date should be after start date`}
                </div>
              </div>
            </Grid>
          </LocalizationProvider>
          <Grid container spacing={5}>
            <CollectionSelector
              selectedCollectionId={collectionId}
              headerTitle="Select collection for showcase"
              onChangeCollection={handleSelectCollection}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}></Grid>
      </Grid>
      <ConfirmDialog
        id="confirm-delete-showcase-dialog"
        open={!!openConfirmDialog}
        text={`Are you sure you want to delete this showcase? You will not be able to undo this.`}
        onClose={onCloseConfirmDialog}
      />
    </>
  );
};

export default ShowcaseEditForm;
