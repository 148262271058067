import { isMobile } from "react-device-detect";

import { createMuiTheme } from "@material-ui/core/styles";

export const AppTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#e8701c",
    },
    secondary: {
      main: "#000000",
    },
    grey: {
      // Main Backround
      "100": "#F6F6F7",
      // Selected menu item background
      "300": "#EBEBEB",
      // Dividers
      A100: "#D8D8D8",
      // Shadow
      A200: "#52599C",
      // Borders
      A400: "#E5E5E5",
    },
    info: {
      light: "rgba(4, 49, 101, 0.15)",
      main: "#000000",
      dark: "#000000",
    },
    text: {
      primary: "#4D4D4D",
    },
    success: {
      light: "#B7EDD7",
      main: "#33805B",
      dark: "#4D4D4D",
    },
    error: {
      light: "#FFE0E0",
      main: "#D02D23",
      dark: "#D02D23",
    },
    // To use for 'New' Status
    warning: {
      light: "#F6F6F7",
      main: "#4D4D4D",
      dark: "#4D4D4D",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Montserrat,SST,sans-serif,Roboto",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    h1: {
      fontSize: "30px",
      lineHeight: "36px",
      fontWeight: 400,
    },
    h2: {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    h3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 400,
    },
    button: {
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 700,
      textTransform: "capitalize",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 500,
    },
    overline: {
      fontSize: "9px",
      lineHeight: "12px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: "44px",
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "16px",
        fontFamily: "Montserrat,SST,sans-serif,Roboto",
        fontWeight: 700,
      },
    },
    MuiIconButton: {
      root: {
        padding: "8px",
      },
    },
    MuiListItem: {
      selected: {},
      root: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
      button: {
        paddingTop: "5px",
        paddingBottom: "5px",
        margin: "10px 4px 10px 12px",
        width: "calc(100% - 16px)",
        color: "#4d4d4d",
      },
    },
    MuiLink: {
      underlineNone: {
        cursor: "pointer",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
      },
    },
    MuiDialog: {
      paper: {
        padding: "30px",
      },
      paperWidthXs: {
        width: "385px",
      },
    },
    MuiFormControlLabel: {
      root: {
        overflow: "hidden",
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        width: `${isMobile ? "100%" : "520px"}`,
      },
    },
  },
});

export const CustomTheme = (primary_color?: string) => {
  const theme = createMuiTheme({
    ...AppTheme,
  });
  theme.palette.primary.main = primary_color
    ? primary_color
    : theme.palette.primary.main;
  theme.palette.secondary.main = primary_color
    ? `${primary_color}B3`
    : theme.palette.secondary.main;

  // to make the code easier on the eye
  const mainPalette = theme.palette.primary;
  const commonPalette = theme.palette.common;
  const greyPalette = theme.palette.grey;

  theme.overrides = {
    ...theme.overrides,
    MuiOutlinedInput: {
      root: {
        backgroundColor: commonPalette.white,
      },
      notchedOutline: {
        borderColor: greyPalette.A100,
      },
    },
    MuiButton: {
      ...theme.overrides?.MuiButton,
      containedPrimary: {
        "&:hover": {
          backgroundColor: mainPalette.main,
          opacity: 0.8,
        },
        "&:disabled": {
          color: commonPalette.white,
        },
      },
      outlinedPrimary: {
        "&:hover": {
          backgroundColor: greyPalette.A100,
        },
      },
    },
    MuiIconButton: {
      ...theme.overrides?.MuiIconButton,
      colorPrimary: {
        backgroundColor: mainPalette.main,
        color: commonPalette.white,
        "&:hover": {
          backgroundColor: mainPalette.main,
          opacity: 0.8,
        },
      },
    },
    MuiListItem: {
      ...theme.overrides?.MuiListItem,
      button: {
        ...theme.overrides?.MuiListItem?.button,
        "&$selected": {
          backgroundColor: greyPalette[300],
          color: mainPalette.main,
          borderRadius: "4px",
          "& .MuiListItemIcon-root": {
            color: mainPalette.main,
          },
        },
        "&$selected:hover": {
          backgroundColor: greyPalette[300],
          color: mainPalette.main,
          borderRadius: "4px",
          "& .MuiListItemIcon-root": {
            color: mainPalette.main,
          },
        },
        "&:hover": {
          backgroundColor: greyPalette[300],
          color: mainPalette.main,
          borderRadius: "4px",
          "& .MuiListItemIcon-root": {
            color: mainPalette.main,
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: greyPalette.A100,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: commonPalette.white,
      },
      deleteIcon: {
        color: mainPalette.main,
        "&:hover": {
          opacity: 0.8,
        },
      },
    },
    MuiTableContainer: {
      root: {
        backgroundColor: commonPalette.white,
        boxShadow: `0px 0px 9px 0px ${greyPalette.A200}14`,
        border: `1px solid ${greyPalette.A100}`,
        borderBottom: "none",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        "&::-webkit-scrollbar": {
          "-webkit-appearance": "none",
          width: "7px",
          height: "7px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        whiteSpace: "nowrap",
      },
      head: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        whiteSpace: "nowrap",
      },
      stickyHeader: {
        backgroundColor: commonPalette.white,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          color: mainPalette.main,
        },
      },
      active: {
        color: `${mainPalette.main} !important`,
      },
      icon: {
        fontSize: "18px",
      },
      iconDirectionAsc: {
        color: `${mainPalette.main} !important`,
      },
      iconDirectionDesc: {
        color: `${mainPalette.main} !important`,
      },
    },
    MuiMenuItem: {
      root: {
        color: mainPalette.main,
        margin: 0,
        "&:hover": {
          backgroundColor: greyPalette[100],
        },
      },
    },
    MuiSnackbar: {
      anchorOriginTopRight: {
        right: "60px",
        top: "14px",
      },
      anchorOriginBottomCenter: {
        bottom: "45px",
      },
    },
    MuiPaper: {
      elevation3: {
        backgroundColor: commonPalette.white,
        border: `1px solid ${greyPalette.A400}`,
        boxShadow: `0px 0px 9px 0px ${greyPalette.A200}14`,
        padding: "30px",
        [theme.breakpoints.down("sm")]: {
          padding: "20px",
        },
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: commonPalette.white,
        boxShadow: `0px 0px 9px 0px ${greyPalette.A200}14`,
        border: `1px solid ${greyPalette.A100}`,
        borderTop: "none",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        backgroundColor: "transparent",
        "&:checked": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiPopover: {
      paper: {
        padding: "30px",
      },
    },
    MuiMenu: {
      paper: {
        padding: "0",
      },
    },
    MuiFormHelperText: {
      root: {
        textAlign: "right",
      },
    },
    MuiInputLabel: {
      formControl: {
        lineHeight: "16px",
      },
    },
  };

  return theme;
};
