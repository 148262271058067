// import { isMobile } from "react-device-detect";

import { TableAction, TableColumn } from "@components/Table";
import CollectionParser from "@pages/users/collectionParser";

export const showcasesColumns: TableColumn[] = [
  {
    id: "status",
    label: "Status",
    type: "custom",
    parser: (item) => {
      if (new Date(item.startDate) < new Date()) {
        if (new Date() > new Date(item.endDate)) {
          return <div style={{ color: "red" }}>Concluded</div>;
        }
        return <div style={{ color: "green" }}>Ongoing</div>;
      } else {
        return <div style={{ color: "blue" }}>Upcoming</div>;
      }
    },
  },
  {
    id: "media",
    label: "Has Media",
    type: "custom",
    parser: (item) => {
      return item.videos?.length ? (
        <div style={{ color: "green" }}>YES</div>
      ) : (
        <div style={{ color: "red" }}>No</div>
      );
    },
  },
  {
    id: "started",
    label: "Live",
    type: "custom",
    parser: (item) => {
      return item.started ? (
        <div style={{ color: "green" }}>YES</div>
      ) : (
        <div style={{ color: "red" }}>No</div>
      );
    },
  },
  {
    id: "userEmail",
    label: "User",
    type: "text",
    // stickyColumn: !isMobile,
  },
  {
    id: "defaultCollection",
    label: "Default Collection",
    type: "custom",
    parser: (item) => {
      return <CollectionParser collection={item.defaultCollection} />;
    },
  },
  {
    id: "published",
    label: "Published",
    type: "custom",
    parser: (item) => {
      return item.published === true ? (
        <div style={{ color: "green" }}>YES</div>
      ) : (
        <div style={{ color: "red" }}>No (Draft)</div>
      );
    },
  },
  {
    id: "startDate",
    label: "Start Date",
    type: "date",
    // stickyColumn: !isMobile,
  },
  {
    id: "endDate",
    label: "End Date",
    type: "date",
    // stickyColumn: !isMobile,
  },
  {
    id: "actions",
    label: "Actions",
    type: "actions",
    actions: [
      {
        actionId: "id",
        actionType: "link",
        parentId: "eventId",
        label: "Edit Showcase",
        link: "/sales-event/<parentId>/showcases/edit/<actionId>",
      },
    ],
    // stickyColumn: !isMobile,
  },
];

export const showcasesActions: TableAction[] = [
  {
    actionId: "id",
    actionType: "link",
    parentId: "eventId",
    label: "Edit Showcase",
    link: "/sales-event/<parentId>/showcases/edit/<actionId>",
  },
];
