import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "@pages/userForm/styles";
import { setChosenUserStatus } from "@redux/reducers/userStatus.reducer";
import { UserStatus } from "@models/userStatus.model";
import { User } from "@models/user.model";

const UserStatusComponent: React.FC = () => {
  const pageClasses = useStyles();
  const radioGroupRef = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();

  const selectedUser: User = useAppSelector(
    (state: RootState) => state.users.selectedUser
  );

  const userStatus: UserStatus[] = useAppSelector(
    (state: RootState) => state.userStatus.userStatus
  );

  const [userStatusHandle, setUserStatusHandle] = useState<string>(
    selectedUser?.status ?? ""
  );

  useEffect(() => {
    if (selectedUser?.status) {
      setUserStatusHandle(selectedUser?.status);
      onChangeUserStatus(selectedUser?.status);
    }
  }, [selectedUser]);

  const changeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setUserStatusHandle(target.value);
    onChangeUserStatus(target.value);
  };

  const onChangeUserStatus = (handle: string) =>
    dispatch(
      setChosenUserStatus(
        userStatus.find((status) => status.handle === handle) ?? {}
      )
    );

  const renderUserStatusContent = (userStatus: UserStatus) => (
    <div>
      <Typography
        title={userStatus.title}
        className={pageClasses.roleOptionName}
        variant="body1"
        component="p"
      >
        {userStatus.title}
      </Typography>
    </div>
  );

  const renderUserStatusPaper = (userStatus: UserStatus) => (
    <FormControlLabel
      key={userStatus.handle}
      value={userStatus.handle}
      className={pageClasses.roleControlLabel}
      control={<Radio />}
      label={renderUserStatusContent(userStatus)}
    />
  );

  return (
    <Grid xs={12} md={6} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        Select user status
      </Typography>

      <RadioGroup
        ref={radioGroupRef}
        aria-label="user-status"
        name="user-status"
        value={userStatusHandle}
        onChange={changeStatus}
        className={pageClasses.rowContainer}
      >
        {userStatus.map((userStatus: UserStatus) =>
          renderUserStatusPaper(userStatus)
        )}
      </RadioGroup>
    </Grid>
  );
};

export default UserStatusComponent;
