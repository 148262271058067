import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import { Interest } from "@models/interest.model";
import CollectionSelector from "@components/CollectionSelector";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export interface InterestConfiguratorProps {
  interest: Interest;
  showMoveUp: boolean;
  showMoveDown: boolean;
  selectCollection: (collectionId: string | null) => void | undefined;
  moveUp: () => void;
  moveDown: () => void;
  updateInterestName: (newName: string) => void;
}

const InterestConfigurator: React.FC<InterestConfiguratorProps> = ({
  interest,
  showMoveUp,
  showMoveDown,
  selectCollection,
  moveUp,
  moveDown,
  updateInterestName,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(interest?.name || "");

  const handleSelectCollection = (collectionId: string | null) => {
    selectCollection(collectionId);
  };

  const clearCollection = () => {
    selectCollection(null);
  };

  const startEditing = () => {
    setEditing(true);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  const confirmEdit = () => {
    setEditing(false);
    updateInterestName(value);
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setValue(target.value);
  };

  return (
    <Grid
      container
      xs={12}
      md={12}
      style={{
        border: "1px solid black",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "10px",
      }}
      direction="row"
    >
      <Grid item xs={1} md={1}>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showMoveUp && (
            <div
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={moveUp}
            >
              <ArrowUpwardIcon />
            </div>
          )}
          <div>Order: {Number(interest.order) + 1}</div>
          {showMoveDown && (
            <div
              style={{ cursor: "pointer", marginTop: "10px" }}
              onClick={moveDown}
            >
              <ArrowDownwardIcon />
            </div>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={11}
        md={11}
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
        }}
      >
        <Grid container alignItems="flex-end">
          {editing ? (
            <input type="text" value={value} onChange={updateValue} />
          ) : (
            <Typography variant="h1" color="textPrimary">
              {interest.name}
            </Typography>
          )}

          {editing ? (
            <div style={{display: 'flex', flexDirection: 'row', marginLeft: '1em'}}>
              {value !== "" && (
                <div style={{ cursor: "pointer" }} onClick={confirmEdit}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ marginLeft: "1em" }}
                  >
                    Save
                  </Typography>
                </div>
              )}
              <div style={{ cursor: "pointer" }} onClick={cancelEdit}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{ marginLeft: "1em" }}
                >
                  Cancel
                </Typography>
              </div>
            </div>
          ) : (
            <div style={{ cursor: "pointer" }} onClick={startEditing}>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ marginLeft: "1em" }}
              >
                Edit
              </Typography>
            </div>
          )}
        </Grid>

        <CollectionSelector
          selectedCollectionId={interest?.collectionId || null}
          onChangeCollection={handleSelectCollection}
          showClearCollection={true}
          clearCollection={clearCollection}
        />
      </Grid>
    </Grid>
  );
};

export default InterestConfigurator;
