import { Interest } from "@models/interest.model";
import { ShowcaseCreate } from "@models/showcase.model";

const removeSeconds = (date: Date) => {
  const newDate = new Date(date);
  const updatedDate = new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate(),
    newDate.getHours(),
    newDate.getMinutes()
  );
  return updatedDate;
};

const getShowcaseInputValues = ({
  startDate,
  endDate,
  published,
  eventId,
  collectionId,
  userEmail,
  interests,
}: {
  startDate: Date;
  endDate: Date;
  published: boolean;
  eventId?: string;
  collectionId?: string;
  userEmail?: string;
  interests: Interest[];
}) => {
  const showcase: ShowcaseCreate = {
    startDate: removeSeconds(startDate),
    endDate: removeSeconds(endDate),
    published,
    eventId,
    defaultCollection: collectionId,
    userEmail,
    interests:
      interests.map((interest) => ({ id: interest.id, name: interest.name })) ||
      [],
  };

  return showcase;
};

export default getShowcaseInputValues;
