import React, { useState, useEffect } from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';

import { FiltersOptionsGroup } from '@components/Filters/FiltersDialog';
import { getFiltersChips } from '@helpers';
import useStyles from './styles';
import { NestedObject } from '@models/common';

export interface FilterChipData {
  key: number;
  label: string;
  filterId: string;
  value: string;
}

interface FiltersChipsProps {
  filtersGroups: FiltersOptionsGroup[];
  filters: NestedObject;
  onDeleteFilter: (data: FilterChipData) => void;
}

export const FiltersChips: React.FC<FiltersChipsProps> = ({
  filtersGroups,
  filters,
  onDeleteFilter,
}) => {
  const classes = useStyles();
  const [chipData, setChipData] = useState<FilterChipData[]>([]);

  useEffect(() => {
    const chips = getFiltersChips(filters, filtersGroups);
    setChipData(chips);
  }, [filters, setChipData, filtersGroups]);

  const handleDeleteFilter = (chipToDelete: FilterChipData) => () => {
    onDeleteFilter(chipToDelete);
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key),
    );
  };

  return chipData.length !== 0 ? (
    <Grid item xs={12} md={12} className={classes.chipsContainer}>
      <Typography variant="body1" component="span" color="textPrimary">
        Filters applied:
      </Typography>
      {chipData.map((data: FilterChipData) => (
        <Chip
          key={data.key}
          label={
            <Typography variant="body1" component="span" color="primary">
              {data.label}
            </Typography>
          }
          onDelete={handleDeleteFilter(data)}
          className={classes.chip}
        />
      ))}
    </Grid>
  ) : (
    <></>
  );
};
