import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import clsx from "clsx";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import DateTimePickerTextField from "@mui/material/TextField";

import SalesEventsGraphQL from "@graphql/salesEvents.resolver";
import { AlertType } from "@models/common";
import { SalesEventCreate } from "@models/salesEvent.model";
import getSalesEventInputValues from "@helpers/getSalesEventInputValues";
import FileSelector from "@components/Uploads/FileSelector";
import useStyles from "./styles";
import CollectionSelector from "@components/CollectionSelector";
import InterestsSelector from "@components/InterestsSelector";
import { Interest } from "@models/interest.model";

export interface SalesEventFormProps {
  id?: string;
  backButton: React.ReactElement;
  navigateOnSave?: (id?: string) => void;
  dispatchAlert: (message: string, type: AlertType) => void;
}

const SalesEventCreateForm: React.FC<SalesEventFormProps> = ({
  backButton,
  navigateOnSave,
  dispatchAlert,
}) => {
  const pageClasses = useStyles();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [file, setFile] = useState<File | null>();
  const [published, setPublished] = useState<boolean>(true);
  const [collectionId, setCollectionId] = useState<string>("");
  const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);

  const [startDateError, setStartDateError] = useState<boolean>(false);

  const [
    createSalesEvent,
    {
      loading: creatingSalesEvent,
      error: errorCreating,
      data: createSalesEventData,
    },
  ] = useMutation(SalesEventsGraphQL.mutations.createSalesEvent, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorCreating) {
      dispatchAlert("Unexpected error creating the sales event", "error");
    }
  }, [errorCreating]);

  useEffect(() => {
    if (createSalesEventData) {
      if (!createSalesEventData.createSalesEvent?.error) {
        dispatchAlert(
          "Your sales event has been sucesfully created.",
          "success"
        );
        navigateOnSave &&
          navigateOnSave(createSalesEventData.createSalesEvent.id);
      }
      if (createSalesEventData.createSalesEvent?.error) {
        dispatchAlert(
          createSalesEventData.createSalesEvent?.messages[0],
          "error"
        );
      }
    }
  }, [createSalesEventData]);

  const handleSaveSalesEvent = () => {
    const salesEvent: SalesEventCreate = getSalesEventInputValues({
      title,
      description,
      startDate,
      endDate,
      file: file || null,
      fileType: file?.type || null,
      published,
      collectionId,
      interests: selectedInterests,
    });
    createSalesEvent({
      variables: {
        input: salesEvent,
      },
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    evalDates();
  }, [startDate, endDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue || new Date());
    evalDates();
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue || new Date());
    evalDates();
  };

  const evalDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  const handleSelectHeroImage = (file: File | null) => {
    setFile(file);
  };

  const togglePublished = () => {
    setPublished(!published);
  };

  const handleSelectCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={clsx(
          pageClasses.spacedContainer,
          pageClasses.smallBottomMargin
        )}
      >
        {backButton}
        <div className={pageClasses.toastContainer}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={handleSaveSalesEvent}
            disabled={!title || creatingSalesEvent || startDateError}
          >
            {!creatingSalesEvent ? "Save" : <CircularProgress size={25} />}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} className={pageClasses.bottomMargin}>
        <div className={pageClasses.row}>
          <Typography variant="h1" color="textPrimary" gutterBottom={true}>
            Create a new Sales Event
          </Typography>
          <FormControlLabel
            className={pageClasses.switch}
            control={
              <Switch
                checked={published}
                onChange={togglePublished}
                color="secondary"
              />
            }
            label={published ? "Published Event" : "Draft"}
          />
        </div>
        <TextField
          variant="outlined"
          type="text"
          id="sales-event-name"
          label="Add Sales Event Name"
          value={title}
          onChange={handleTitleChange}
          className={pageClasses.salesEventTitleInput}
          autoComplete="off"
          inputProps={{
            maxLength: 200,
          }}
          helperText={`${title.length}/200`}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid>
          <TextField
            variant="outlined"
            label="Description"
            multiline
            rows={4}
            defaultValue=""
            inputProps={{
              maxLength: 200,
            }}
            onChange={handleDescriptionChange}
            helperText={`${title.length}/200`}
            className={pageClasses.salesEventTitleInput}
            required
            fullWidth
          />
        </Grid>
        <Grid container spacing={5}>
          <InterestsSelector
            selectedInterests={selectedInterests}
            headerTitle="Select interests for event"
            onChangeSelection={(selection: Interest[]) => {
              setSelectedInterests(selection);
            }}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Grid className={pageClasses.rowContainer}>
            <div className={pageClasses.columnContainer}>
              <DateTimePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                onAccept={evalDates}
                disableMaskedInput
                renderInput={(params) => (
                  <DateTimePickerTextField
                    {...params}
                    style={{
                      backgroundColor: "white",
                      marginRight: "60px",
                      marginTop: "1em",
                    }}
                  />
                )}
              />
              <span className={pageClasses.timeError}>
                {startDateError && `Start date should be before end date`}
              </span>
            </div>

            <div className={pageClasses.columnContainer}>
              <DateTimePicker
                label="End Date"
                value={endDate}
                disableMaskedInput
                onChange={handleEndDateChange}
                onAccept={evalDates}
                renderInput={(params) => (
                  <DateTimePickerTextField
                    {...params}
                    style={{ backgroundColor: "white", marginTop: "1em" }}
                  />
                )}
              />
              <span className={pageClasses.timeError}>
                {startDateError && `End date should be after start date`}
              </span>
            </div>
          </Grid>
        </LocalizationProvider>
        <Grid>
          <FileSelector onSelectFile={handleSelectHeroImage} />
        </Grid>
        <Grid container spacing={5}>
          <CollectionSelector
            selectedCollectionId={collectionId}
            headerTitle="Select collection for event"
            onChangeCollection={handleSelectCollection}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SalesEventCreateForm;
