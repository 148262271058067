import React, { useEffect } from "react";
import {
  Grid,
  Hidden,
  Paper,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useLazyQuery } from "@apollo/client";

import useStyles from "./styles";
import logo from "@images/logo.png";
import { RootState } from "@redux/store";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { logoutUser } from "@redux/reducers/user.reducer";
import { NestedObject } from "@models/common";
import AuthGraphQL from "@graphql/authentication.resolver";

interface AccessProps {
  component: React.FC;
  path: string;
  backToLogin?: boolean;
  location?: NestedObject;
  showLogo?: boolean;
}

const Access: React.FC<AccessProps> = ({
  component: Component,
  location,
  backToLogin = true,
  showLogo = true,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    isLoggedIn,
    user: { status },
  } = useAppSelector((state: RootState) => state.user);

  const [logout, { loading: _loading, error, data }] = useLazyQuery(
    AuthGraphQL.queries.logout
  );

  useEffect(() => {
    if (error) {
      console.log("ERROR LOGOUT QUERY");
    }

    if (data || error) {
      dispatch(logoutUser());
      history.push("/login");
    }
  }, [data, error, logoutUser, history]);

  const goToLogin = () => {
    if (!isLoggedIn) {
      history.push("/login");
    } else {
      logout();
    }
  };

  const renderContent = (isMobile = false) => (
    <Grid
      container
      direction="column"
      justify="center"
      className={isMobile ? classes.contentMobile : classes.content}
    >
      {!isMobile && backToLogin && (
        <Button
          className={classes.backButton}
          color="primary"
          variant="text"
          type="button"
          startIcon={<ChevronLeftIcon />}
          onClick={goToLogin}
        >
          <Typography variant="h3" color="primary">
            Back to login
          </Typography>
        </Button>
      )}
      {showLogo && (
        <Grid item>
          <img className={classes.logo} src={logo} alt="onekin-logo" />
        </Grid>
      )}
      <Grid item>
        <Component />
      </Grid>
    </Grid>
  );

  if (isLoggedIn && status !== "DISABLED") {
    return (
      <Redirect
        to={{
          pathname: `${
            location?.state?.pathname ? location?.state.pathname : "/"
          }`,
          state: { from: location?.location },
        }}
      />
    );
  } else {
    return (
      <Grid className={classes.body} container direction="row">
        <Hidden only={["md", "lg", "xl"]}>
          <Grid item xs={12} md={12}>
            {backToLogin && (
              <IconButton
                className={classes.backIcon}
                aria-label="back to login"
                onClick={goToLogin}
                size="medium"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <div
              className={clsx(classes.background, classes.backgroundMobile)}
            />
            <Paper className={classes.contentContainerMobile}>
              {renderContent(true)}
            </Paper>
          </Grid>
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={6} xs={12} className={classes.background} />
          <Grid item md={6} xs={12}>
            {renderContent()}
          </Grid>
        </Hidden>
      </Grid>
    );
  }
};

export default Access;
