import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: "40px",
      maxWidth: "1200px",
    },
    tableFooter: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "black",
    },
    tableCells: {
      fontSize: "12px",
    },
    alignedCells: {
      textAlign: "center",
      maxWidth: "400px",
    },
    leftRow: {
      width: "fit-content",
      maxWidth: "400px",
    },
    noCollectionMessage: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "16px",
    },
  })
);

export default useStyles;
