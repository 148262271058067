import gql from "graphql-tag";

export const getSettings = gql`
  query settings {
    settings {
      settings {
        id
        value
      }
    }
  }
`;

export const updateSetting = gql`
  mutation addOrUpdate($input: CreateOrUpdateSettingInput!) {
    addOrUpdate(input: $input) {
      id
      value
    }
  }
`;

const settingsResolver = {
  queries: {
    getSettings,
  },
  mutations: {
    updateSetting,
  },
};

export default settingsResolver;
