import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "@pages/userForm/styles";
import { setChosenRole } from "@redux/reducers/roles.reducer";
import { Role } from "@models/role.model";
import { User } from "@models/user.model";

const UserRole: React.FC = () => {
  const pageClasses = useStyles();
  const radioGroupRef = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();

  const selectedUser: User = useAppSelector(
    (state: RootState) => state.users.selectedUser
  );

  const roles: Role[] = useAppSelector((state: RootState) =>
    state.roles.roles.filter((role) => role.available)
  );

  const [roleHandle, setRoleHandle] = useState<string>(
    selectedUser?.role ?? ""
  );

  useEffect(() => {
    if (selectedUser?.role) {
      setRoleHandle(selectedUser?.role);
      onChangeRole(selectedUser?.role);
    }
  }, [selectedUser]);

  const changeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setRoleHandle(target.value);
    onChangeRole(target.value);
  };

  const onChangeRole = (handle: string) =>
    dispatch(setChosenRole(roles.find((role) => role.handle === handle) ?? {}));

  const renderRoleContent = (role: Role) => (
    <div>
      <Typography
        title={role.title}
        className={pageClasses.roleOptionName}
        variant="body1"
        component="p"
      >
        {role.title}
      </Typography>
    </div>
  );

  const renderRolePaper = (role: Role) => (
    <FormControlLabel
      key={role.handle}
      value={role.handle}
      className={pageClasses.roleControlLabel}
      control={<Radio />}
      label={renderRoleContent(role)}
    />
  );

  const showRoles = true //roles
    // .map((role) => role.handle)
    // .includes(selectedUser.role);

  return (
    <Grid xs={12} md={6} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        {showRoles ? "Select user role" : `User Role: ${selectedUser.role}`}
      </Typography>

      {showRoles && (
        <RadioGroup
          ref={radioGroupRef}
          aria-label="user-role"
          name="user-role"
          value={roleHandle}
          onChange={changeRole}
          className={pageClasses.rowContainer}
        >
          {roles.map((role: Role) => renderRolePaper(role))}
        </RadioGroup>
      )}
    </Grid>
  );
};

export default UserRole;
