import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textInput: {
      marginBottom: "40px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    submitButton: {
      [theme.breakpoints.up("md")]: {
        width: "fit-content",
        padding: "6px 40px",
      },
      marginBottom: "20px",
    },
    linkButton: {
      width: "fit-content",
    },
  })
);

export default useStyles;
