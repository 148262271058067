import { Interest } from "@models/interest.model";
import { SalesEventCreate } from "@models/salesEvent.model";

const removeSeconds = (date: Date) => {
  const newDate = new Date(date);
  const updatedDate = new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate(),
    newDate.getHours(),
    newDate.getMinutes()
  );
  return updatedDate;
};

const getSalesEventInputValues = ({
  title,
  description,
  startDate,
  endDate,
  file,
  fileType,
  published,
  collectionId,
  interests,
}: {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  file: File | null;
  fileType: string | null;
  published: boolean;
  collectionId?: string;
  interests: Interest[];
}) => {
  const salesEvent: SalesEventCreate = {
    title: title.trim(),
    description: description.trim(),
    startDate: removeSeconds(startDate),
    endDate: removeSeconds(endDate),
    file: file || null,
    fileType: fileType || null,
    published,
    defaultCollection: collectionId,
    interests:
      interests.map((interest) => ({ id: interest.id, name: interest.name })) ||
      [],
  };

  return salesEvent;
};

export default getSalesEventInputValues;
