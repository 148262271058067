import React from "react";
import { useHistory, useParams } from "react-router";
import { Grid, Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import useStyles from "./styles";
import ShowcaseCreateForm from "./showcaseCreate";
import ShowcaseEditForm from "./showcaseEdit";
import { AlertType } from "@models/common";
import { useAppDispatch } from "@redux/hooks";
import { setAlert } from "@redux/reducers/alert.reducer";

const ShowcaseForm: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const params = useParams<{ id: string; showcaseId: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  const goBackToShowcasesForEvent = () => {
    history.push(`/sales-event/${params.id}/showcases`);
  };

  const backButton = (
    <Button
      className={classes.backButton}
      color="primary"
      variant="text"
      type="button"
      startIcon={<ChevronLeftIcon />}
      onClick={goBackToShowcasesForEvent}
    >
      <Typography variant="body1" color="primary">
        Back to Showcases for the Event
      </Typography>
    </Button>
  );

  return (
    <Grid container>
      {params.showcaseId ? (
        <ShowcaseEditForm
          id={params.id}
          showcaseId={params.showcaseId}
          backButton={backButton}
          goBackToShowcases={goBackToShowcasesForEvent}
          dispatchAlert={dispatchAlert}
        />
      ) : (
        <ShowcaseCreateForm
          backButton={backButton}
          goBackToShowcases={goBackToShowcasesForEvent}
          dispatchAlert={dispatchAlert}
        />
      )}
    </Grid>
  );
};

export default ShowcaseForm;
