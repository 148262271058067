import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallBottomMargin: {
      marginBottom: "16px",
    },
    bottomMargin: {
      marginBottom: "30px",
    },
    backButton: {
      width: "fit-content",
      paddingLeft: "0",
    },
    spacedContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
    },
    centeredAlign: {
      alignItems: "center",
    },
    roleOptionName: {
      marginBottom: "0px",
    },
    roleControlLabel: {
      marginRight: "14px",
    },
    drawer: {
      "& .MuiDrawer-paper": {
        width: "500px",
      },
    },
    drawerSection: {
      padding: "30px 50px",
    },
    sliderContainer: {
      paddingBottom: "0",
    },
    drawerSectionHeader: {
      marginBottom: "16px",
    },
    drawerIcon: {
      marginRight: "15px",
    },
    drawerInput: {
      marginBottom: "16px",
    },
    closeDrawerIcon: {
      width: "32px",
      height: "32px",
    },
    chipsContainer: {
      marginBottom: "20px",
    },
    chip: {
      marginRight: "10px",
      marginTop: "5px",
    },
    paperContainer: {
      padding: "30px",
      backgroundColor: theme.palette.common.white,
    },
    toastContainer: {
      display: "flex",
      alignItems: "center",
    },
    toast: {
      marginRight: "30px",
    },
    parentWidth: {
      width: "100%",
    },
  })
);

export default useStyles;
