import React, { useRef, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactHlsPlayer from "react-hls-player";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import HighlightOff from "@material-ui/icons/HighlightOff";

import useStyles from "./styles";
import { VideoDto } from "@pages/showcaseForm/showcaseEdit";

export interface VideosProps {
  playlist?: {
    published: boolean;
    url: string;
    id: string;
  }[];
  onToggleEnabled: (video: VideoDto) => void;
}

const Videos: React.FC<VideosProps> = ({ playlist, onToggleEnabled }) => {
  const pageClasses = useStyles();
  const playerRef = useRef();

  const [current, setCurrent] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>();

  const selectVideo = (video: string, index: number) => {
    setCurrent(index);
    setVideoUrl(video);
  };

  const toggleVideoEnabled = (video: VideoDto) => {
    onToggleEnabled(video);
  };

  return (
    <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        Videos
      </Typography>

      {playlist && playlist?.length > 0 ? (
        <Grid item xs={12} md={12}>
          <div className={pageClasses.videoContainer}>
            <div className={pageClasses.playlistContainer}>
              <div className={pageClasses.playlistHeader}>Playlist</div>

              <div className={pageClasses.listContainer}>
                {playlist.map((video, index) => (
                  <div className={pageClasses.rowContainer}>
                    <div
                      key={`${video.id}-enabler`}
                      className={
                        current === index
                          ? pageClasses.enabledListItemActive
                          : pageClasses.enabledListItemInactive
                      }
                      onClick={() => toggleVideoEnabled(video)}
                    >
                      {video.published ? (
                        <CheckCircleOutlined color="primary" />
                      ) : (
                        <HighlightOff color="secondary" />
                      )}
                    </div>
                    <div
                      key={`${video.id}-player`}
                      className={
                        current === index
                          ? pageClasses.listItemActive
                          : pageClasses.listItemInactive
                      }
                      onClick={() => selectVideo(video.url, index)}
                    >
                      Part {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              {videoUrl && (
                <ReactHlsPlayer
                  // @ts-ignore
                  playerRef={playerRef}
                  src={videoUrl}
                  autoPlay={true}
                  controls={true}
                  // width="100%"
                  height="400px"
                  // width="226px"
                  style={{ maxWidth: "300px" }}
                />
              )}
            </div>
          </div>
        </Grid>
      ) : (
        <div>No videos yet</div>
      )}
    </Grid>
  );
};

export default Videos;
