import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallBottomMargin: {
      marginBottom: "16px",
    },
    bottomMargin: {
      marginBottom: "30px",
    },
    rowWrapContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    collectionFilter: {
      maxWidth: "510px",
      marginBottom: "12px",
    },
    collectionOptionName: {
      marginTop: "6px",
      marginBottom: "10px",
    },
    collectionControlLabel: {
      width: "100%",
    },
    collectionContainer: {
      padding: "6px",
      marginRight: "16px",
    },
    parentWidth: {
      width: "100%",
    },

    videoContainer: {
      display: "flex",
      flexDirection: "row",
      height: "400px",
    },
    playlistContainer: {
      width: "200px",
      overflow: "scroll",
      backgroundColor: "white",
      position: "relative",
      border: "1px solid black",
    },
    playlistHeader: {
      position: "absolute",
      height: "30px",
      backgroundColor: "white",
      width: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      borderBottom: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "black",
    },
    listContainer: {
      borderTop: "1px",
      borderTopColor: "black",
      borderTopStyle: "solid",

      marginTop: "29px",
      width: "200px",
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    listItemActive: {
      height: "30px",
      paddingLeft: "7px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: "darkgray",
      color: "white",
      borderBottom: "1px",
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      fontWeight: "bold",
      flex: 1,
    },
    listItemInactive: {
      height: "30px",
      paddingLeft: "7px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px",
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      fontWeight: "bold",
      flex: 1,
    },
    enabledListItemActive: {
      height: "30px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: "darkgray",
      color: "white",
      borderBottom: "1px",
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      fontWeight: "bold",
      width: "40px",
      paddingLeft: "10px",
    },
    enabledListItemInactive: {
      height: "30px",
      paddingLeft: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px",
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      fontWeight: "bold",
      width: "40px",
    },
  })
);

export default useStyles;
