import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';

import useStyles from './styles';

interface Props {
  id: string;
  open: boolean;
  text: string;
  onClose: (confirm: boolean) => void;
}

const ConfirmDialog: React.FC<Props> = ({ id, open, text, onClose }) => {
  const classes = useStyles();

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      id={id}
      open={open}
      maxWidth="sm"
      aria-labelledby="confirmation-dialog"
      keepMounted={false}
      // PaperProps={{
      //   elevation: 3,
      // }}
    >
      <DialogContent className={classes.content}>
        <Typography variant="h2" color="textPrimary" gutterBottom={true}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          variant="outlined"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={handleOk}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
