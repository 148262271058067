import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import ProspectsGraphQL from "@graphql/prospects.resolver";
import SharedTable from "@components/Table";
import { prospectColumns } from "./columns";
import initialOptions from "./initialFilters";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import { setProspects } from "@redux/reducers/prospects.reducer";
import { useLazyQuery } from "@apollo/client";
import ProspectStatusGraphQL from "@graphql/prospectStatus.resolver";
import { AlertType } from "@models/common";
import { setAlert } from "@redux/reducers/alert.reducer";
import { useAppDispatch } from "@redux/hooks";
import { setProspectStatus } from "@redux/reducers/prospectStatus.reducer";

const ProspectsPage: React.FC<Record<string, unknown>> = () => {
  const [filter] = useState<FiltersOptionsGroup[]>(initialOptions);
  const dispatch = useAppDispatch();

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  // Initialize Status
  const [getStatus, { loading, error, data }] = useLazyQuery(
    ProspectStatusGraphQL.queries.getProspectStatus,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    if (error) {
      dispatchAlert("Error getting prospect status data.", "error");
    }
  }, [error]);

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (data) {
      if (!data.prospectStatus?.error) {
        dispatch(setProspectStatus(data.prospectStatus));
      }

      if (data.error) {
        dispatchAlert(data?.messages[0], "error");
      }
    }
  }, [data]);

  return loading ? (
    <Grid item xs={12} md={6}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <SharedTable
      tableDisplayName="Prospects"
      tableName="assets"
      dataPath="prospects"
      columns={prospectColumns}
      filtersOptions={filter}
      searchBarLabel="Search by email"
      searchBarId="email"
      query={ProspectsGraphQL.queries.getProspects}
      tableDispatch={setProspects}
    />
  );
};

export default ProspectsPage;
