import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  // Paper,
  // Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";

import CollectionsGraphQL from "@graphql/collections.resolver";
import SettingsGraphQL from "@graphql/settings.resolver";
import { setCollections } from "@redux/reducers/collections.reducer";
import { setAlert } from "@redux/reducers/alert.reducer";
import { useAppDispatch } from "@redux/hooks";
import { AlertType } from "@models/common";

import useStyles from "./styles";
import CollectionSelector from "@components/CollectionSelector";

const SettingsPage: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  // Initialize collections
  const [
    getCollections,
    {
      loading: loadingCollections,
      error: errorCollections,
      data: collectionsData,
    },
  ] = useLazyQuery(CollectionsGraphQL.queries.getCollections, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorCollections) {
      dispatchAlert("Error getting collections data.", "error");
    }
  }, [errorCollections]);

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  // Initialize component
  useEffect(() => {
    getCollections();
    getSettings();
  }, []);

  // Fetch settings info
  const [
    getSettings,
    { loading: loadingSettings, error: errorSettings, data: settingsData },
  ] = useLazyQuery(SettingsGraphQL.queries.getSettings, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorSettings) {
      dispatchAlert("Error getting settings data.", "error");
    }
  }, [errorSettings]);

  useEffect(() => {
    if (settingsData) {
      // console.log("settingsData", settingsData);
      if (settingsData.settings?.settings) {
        let localSettings = {};
        settingsData.settings?.settings.forEach(
          (setting: { id: string | number; value: any }) => {
            if (setting.id && setting.value) {
              //@ts-ignore
              localSettings[`${setting.id}`] = setting.value;
            }
          }
        );
        //@ts-ignore
        setCollectionId(localSettings?.homepageCollection || "");
      }

      if (settingsData?.error) {
        dispatchAlert(settingsData?.messages[0], "error");
      }
    }
  }, [settingsData]);

  // Save settings
  const [
    addOrUpdateSetting,
    { loading: loadingSave, error: errorSave, data: saveData },
  ] = useMutation(SettingsGraphQL.mutations.updateSetting, {

    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorSave) {
      dispatchAlert("Unexpected error saving settings", "error");
    }
  }, [errorSave]);

  useEffect(() => {
    if (saveData) {
      if (!saveData.addOrUpdate?.error) {
        dispatchAlert("Settings saved sucesfully.", "success");
        getSettings();
      }
      if (saveData.addOrUpdate?.error) {
        dispatchAlert(saveData.addOrUpdate?.messages[0], "error");
      }
    }
  }, [saveData]);

  // Collection info collected
  const [collectionId, setCollectionId] = useState<string>("");
  const handleSelectCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  // Local handlers
  const saveSettings = () => {
    addOrUpdateSetting({
      variables: {
        input: {
          id: "homepageCollection",
          value: collectionId,
        },
      },
    });
  };

  const deleteHomepageColection = () => {
    addOrUpdateSetting({
      variables: {
        input: {
          id: "homepageCollection",
          value: "",
        },
      },
    });
  };

  return loadingCollections || loadingSettings || loadingSave ? (
    <Grid item xs={12} className={classes.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <Grid container>
      <Grid container alignItems="center">
        <Grid container justify="space-between">
          <Typography variant="h1" color="textPrimary" gutterBottom={true}>
            Settings
          </Typography>
          <div>
            <Button
              style={{marginRight: '20px'}}
              variant="contained"
              type="button"
              color="secondary"
              onClick={deleteHomepageColection}
            >
              Remove Home Page Collection
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={saveSettings}
              disabled={loadingCollections}
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid container spacing={5}>
          <CollectionSelector
            selectedCollectionId={collectionId}
            headerTitle="Select collection for home page"
            onChangeCollection={handleSelectCollection}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
