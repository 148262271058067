import { TableColumn } from "@components/Table";

export const prospectColumns: TableColumn[] = [
  {
    id: "status",
    label: "Status",
    type: "text",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
  },
  {
    id: "lastUpdateDate",
    label: "Last Update Date",
    type: "date",
    sortId: "lastUpdateDate",
  },
  {
    id: "annualSales",
    label: "Annual Sales",
    type: "text",
  },
  {
    id: "businessCategory",
    label: "Business Category",
    type: "custom",
    parser: (item) => {
      return <div>{item.businessCategory.join(",")}</div>;
    },
  },
  {
    id: "businessDescription",
    label: "Business Description",
    type: "text",
  },
  {
    id: "businessName",
    label: "Business Name",
    type: "text",
  },
  {
    id: "businessType",
    label: "Business Type",
    type: "text",
  },
  {
    id: "companyWebsite",
    label: "Company Website",
    type: "text",
  },
  { id: "contactFirstName", label: "Contact First Name", type: "text" },
  { id: "contactLastName", label: "Contact Last Name", type: "text" },
  { id: "jobTitle", label: "Job Title", type: "text" },
  { id: "mailingList", label: "Mailing List", type: "text" },
  {
    id: "marketplaces",
    label: "Marketplaces",
    type: "custom",
    parser: (item) => {
      return <div>{item.marketplaces ? item.marketplaces.join(",") : ""}</div>;
    },
  },
  { id: "otherRetailers", label: "Other Retailers", type: "text" },
  { id: "productOrigin", label: "Product Origin", type: "text" },
  { id: "prospectOther", label: "Prospect Other", type: "text" },
  { id: "socialFollowing", label: "Social Following", type: "text" },
  {
    id: "actions",
    label: "Actions",
    type: "actions",
    actions: [
      {
        actionId: "id",
        actionType: "link",
        label: "Edit prospect",
        link: "edit-prospect/<actionId>",
      },
    ],
  },
];
