import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  ThemeProvider,
  StylesProvider,
  CssBaseline,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import { RootState } from "@redux/store";
import ResponsiveDrawer from "./Drawer";
import { CustomTheme } from "@App/Theme";
import { useAppSelector } from "@redux/hooks";
import useStyles from "./styles";

interface DashboardLayoutProps {
  component: React.FC;
  path: string;
  location?: any;
  loading?: boolean;
  exact?: boolean;
  withToast?: boolean;
  topPadding?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  component: Component,
  loading,
  location,
  withToast = true,
  topPadding = true,
  ...rest
}) => {
  const classes = useStyles();
  const { isLoggedIn } = useAppSelector((state: RootState) => state.user);

  const renderComponent = () =>
    loading ? (
      <Grid container className={classes.rootContainer}>
        <CircularProgress size={75} thickness={5} />
      </Grid>
    ) : isLoggedIn ? (
      <StylesProvider injectFirst>
        <ThemeProvider theme={CustomTheme()}>
          <CssBaseline />
          <ResponsiveDrawer
            component={Component}
            path={location.pathname}
            withToast={withToast}
            topPadding={topPadding}
          />
        </ThemeProvider>
      </StylesProvider>
    ) : (
      <Redirect
        to={{ pathname: "/login", state: { pathname: location?.pathname } }}
      />
    );

  return <Route location={location} children={renderComponent()} {...rest} />;
};

export default DashboardLayout;
