import { ProspectStatus } from "@models/prospectStatus.model";

const ProspectStatusTypes = {
  SET_PROSPECTS_STATUS: "SET_PROSPECTS_STATUS",
  SET_ACTUAL_PROSPECT_STATUS: "SET_ACTUAL_PROSPECT_STATUS",
};

export const setProspectStatus = (prospectStatuses: ProspectStatus[]) => ({
  type: ProspectStatusTypes.SET_PROSPECTS_STATUS,
  data: prospectStatuses,
});

export const setChosenStatus = (prospectStatuses: ProspectStatus) => ({
  type: ProspectStatusTypes.SET_ACTUAL_PROSPECT_STATUS,
  data: prospectStatuses,
});

export type ProspectsStatusState = {
  prospectStatus: ProspectStatus[];
  chosenProspectStatus: ProspectStatus;
};

// INITIAL STATE
export const prospectStatusInitialState: ProspectsStatusState = {
  prospectStatus: [],
  chosenProspectStatus: {},
};

export type ProspectsStatusAction = {
  type: string;
  data: ProspectStatus[] | ProspectStatus;
};

const prospectsStatusReducer = (
  state = prospectStatusInitialState,
  action: ProspectsStatusAction
) => {
  switch (action.type) {
    case ProspectStatusTypes.SET_PROSPECTS_STATUS:
      return {
        ...state,
        prospectStatus: action.data as ProspectStatus[],
      };

    case ProspectStatusTypes.SET_ACTUAL_PROSPECT_STATUS:
      return {
        ...state,
        chosenProspectStatus: action.data as ProspectStatus,
      };

    default:
      return state;
  }
};

export default prospectsStatusReducer;
