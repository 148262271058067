import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";

import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "./styles";
import { Interest } from "@models/interest.model";

export interface InterestsSelectorProps {
  headerTitle?: string;
  selectedInterests: Interest[];
  onChangeSelection?: (interests: Interest[]) => void;
}

const InterestsSelector: React.FC<InterestsSelectorProps> = ({
  headerTitle,
  selectedInterests,
  onChangeSelection,
}) => {
  const pageClasses = useStyles();

  const interests: Interest[] = useAppSelector(
    (state: RootState) => state.interests.interests
  );

  const handleChange = (interest: Interest, checked: boolean) => {
    let newSelectedInterests: Interest[];
    if (!checked) {
      newSelectedInterests = [...selectedInterests, interest];
    } else {
      newSelectedInterests = selectedInterests.filter(
        (i: Interest) => i.id !== interest.id
      );
    }
    onChangeSelection?.(newSelectedInterests);
  };

  return (
    <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        {headerTitle}
      </Typography>

      <FormGroup className={pageClasses.formGroup}>
        {interests.map((interest: Interest, idx: number) => {
          const checked = !!selectedInterests?.find(
            (el) => el.id === interest.id
          );
          return (
            <FormControlLabel
              key={`interest-${interest.id}-${idx}`}
              control={<Checkbox />}
              label={interest.name}
              checked={checked}
              onChange={() => handleChange(interest, checked)}
            />
          );
        })}
      </FormGroup>
    </Grid>
  );
};

export default InterestsSelector;
