import React, { useEffect } from "react";
import { useQuery } from "@apollo/client/react";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

// import AuthGraphQL from "@graphql/authentication.resolver";
import { AppTheme } from "./Theme";
import { useAppSelector } from "@redux/hooks";
// import { setUserData } from "@redux/reducers/user.reducer";

import Access from "@components/Layouts/Access";
import DashboardLayout from "@components/Layouts/Dashboard";
import Login from "@pages/login";
import RecoverPassword from "@pages/recoverPassword";
import IntakeForm from "@pages/intakeForm";
import DashboardPage from "@pages/dashboard";
import UsersPage from "@pages/users";
import UserForm from "@pages/userForm";
import SalesEventsPage from "@pages/salesEvents";
import SalesEventForm from "@pages/salesEventForm";
import ShowcaseListPage from "@pages/showcaseList";
import ShowcaseForm from "@pages/showcaseForm";
import SettingsPage from "@pages/settings";
import { RootState } from "@redux/store";
import SalesPage from "@pages/sales";
import AnalyticsPage from "@pages/analytics";
import VideoArchivePage from "@pages/videoArchive";
import ProspectsPage from "@pages/prospects";
import ProspectForm from "@pages/prospectForm";
import IntakeCheck from "@pages/intakeCheck";
import InterestsManagerPage from "@pages/interestsManager";
import TrendingShowcasesPage from "@pages/trendingShowcases";

const App: React.FC = () => {
  // const dispatch = useAppDispatch();
  // const { loading, error, data } = useQuery(AuthGraphQL.queries.me);

  // if (error) {
  //   console.log(error);
  // }

  // useEffect(() => {
  //   debugger
  //   if (data && data.me) {
  //     debugger
  //     console.log('here 1', data)
  //     debugger
  //     dispatch(
  //       setUserData({
  //         ...data.me?.user,
  //       })
  //     );
  //   }
  // }, [data, dispatch]);

  const {
    isLoggedIn,
    user: { role, status },
  } = useAppSelector((state: RootState) => state.user);

  console.log("user stats", status);
  // LINKS (links.tsx) need to match roles here
  return (
    <ThemeProvider theme={AppTheme}>
      <Router>
        <Switch>
          {/* Logged out routes */}
          {!isLoggedIn && (
            <Access path="/login" component={Login} backToLogin={false} />
          )}
          {!isLoggedIn && (
            <Access path="/recover-password" component={RecoverPassword} />
          )}
          {!isLoggedIn && (
            <Access
              path="/join-onekin"
              component={IntakeForm}
              showLogo={false}
              // backToLogin={false}
            />
          )}

          {/* Logged in routes */}
          {isLoggedIn && status === "DISABLED" && (
            <Access
              path="/intake-check"
              showLogo={false}
              component={IntakeCheck}
              // loading={loading}
            />
          )}
          {isLoggedIn && status !== "DISABLED" && (
            <DashboardLayout
              exact
              path="/dashboard"
              component={DashboardPage}
              // loading={loading}
            />
          )}

          {/* ADMIN ROUTES */}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout exact path="/users" component={UsersPage} />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout exact path="/edit-user/:id" component={UserForm} />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/sales-events"
              component={SalesEventsPage}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/new-sales-event"
              component={SalesEventForm}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/edit-sales-event/:id"
              component={SalesEventForm}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/sales-event/:id/showcases"
              component={ShowcaseListPage}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/sales-event/:id/showcases/new"
              component={ShowcaseForm}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/sales-event/:id/showcases/edit/:showcaseId"
              component={ShowcaseForm}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout exact path="/settings" component={SettingsPage} />
          )}

          {/* Shared routes Admin and Merchant */}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/prospects"
              component={ProspectsPage}
            />
          )}
          {isLoggedIn && !!role && role === "ADMIN" && (
            <DashboardLayout
              exact
              path="/edit-prospect/:id"
              component={ProspectForm}
            />
          )}
          {isLoggedIn &&
            !!role &&
            (role === "ADMIN" ||
              (role === "MERCHANT" && status !== "DISABLED")) && (
              <DashboardLayout
                exact
                path="/sales-reports"
                component={SalesPage}
              />
            )}
          {isLoggedIn &&
            !!role &&
            (role === "ADMIN" ||
              (role === "MERCHANT" && status !== "DISABLED")) && (
              <DashboardLayout
                exact
                path="/analytics"
                component={AnalyticsPage}
              />
            )}
          {isLoggedIn &&
            !!role &&
            (role === "ADMIN" ||
              (role === "MERCHANT" && status !== "DISABLED")) && (
              <DashboardLayout
                exact
                path="/replay-archive"
                component={VideoArchivePage}
              />
            )}
          {isLoggedIn &&
            !!role &&
            (role === "ADMIN" ||
              (role === "MERCHANT" && status !== "DISABLED")) && (
              <DashboardLayout
                exact
                path="/shop-interests"
                component={InterestsManagerPage}
              />
            )}
            {isLoggedIn &&
            !!role &&
            (role === "ADMIN" ||
              (role === "MERCHANT" && status !== "DISABLED")) && (
              <DashboardLayout
                exact
                path="/trending-showcases"
                component={TrendingShowcasesPage}
              />
            )}

          {/* Fallback routes for logged in and logged out */}
          {isLoggedIn && status !== "DISABLED" && (
            <Route exact path="*">
              <Redirect
                to={{
                  pathname: "/dashboard",
                }}
              />
            </Route>
          )}
          {isLoggedIn && status === "DISABLED" && (
            <Route exact path="*">
              <Redirect
                to={{
                  pathname: "/intake-check",
                }}
              />
            </Route>
          )}
          {!isLoggedIn && (
            <Route exact path="*">
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </Route>
          )}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
