import { removeToken, setToken } from '@config/storage';
import { User } from '@models/user.model';

export const UserTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  LOGOUT_USER: 'LOGOUT_USER',
};

export const setUserData = (user: User) => {
  setToken(user?.accessToken);
  return {
    type: UserTypes.SET_USER_DATA,
    data: user,
  };
};

export const logoutUser = () => {
  removeToken();
  return {
    type: UserTypes.LOGOUT_USER,
    data: {},
  };
};

export type UserState = {
  isLoggedIn: boolean;
  user: User;
};

// INITIAL STATE
const userInitialState: UserState = {
  isLoggedIn: false,
  user: {},
};

export type UserAction = {
  type: string;
  data: User;
};

const userReducer = (state = userInitialState, action: UserAction) => {
  switch (action.type) {
    case UserTypes.SET_USER_DATA:
      return {
        ...state,
        isLoggedIn: true,
        user: action.data,
      };

    case UserTypes.LOGOUT_USER:
      return {
        ...state,
        ...userInitialState,
      };

    default:
      return state;
  }
};

export default userReducer;
