import gql from "graphql-tag";

const fragments = {
  showcaseData: gql`
    fragment showcaseData on ShowcaseDto {
      id
      eventId
      userEmail
      streamUrl
      defaultCollection
      published
      startDate
      endDate
      videos {
        id
        published
        url
      }
      started
      interests {
        id
        name
      }
    }
  `,
  showcaseUpdate: gql`
    fragment showcaseUpdate on ShowcaseDto {
      id
      eventId
      userEmail
      streamUrl
      defaultCollection
      published
      startDate
      endDate
      interests {
        id
        name
      }
    }
  `,
};

export const getShowcases = gql`
  query getShowcases(
    $eventId: String
    $sortBy: String
    $sortDirection: String
    $page: Float
    $rowsPerPage: Float
    $published: String
    $status: String
  ) {
    showcases(
      input: {
        published: $published
        status: $status
        eventId: $eventId
        sort_by: $sortBy
        sort_direction: $sortDirection
        page: $page
        rowsPerPage: $rowsPerPage
      }
    ) {
      pagination {
        page
        rowsPerPage
        total
      }
      showcases {
        ...showcaseData
      }
      error
      messages
    }
  }
  ${fragments.showcaseData}
`;

export const getShowcase = gql`
  query getShowcase($id: String!) {
    getShowcase(id: $id) {
      ...showcaseData
      error
      messages
    }
  }
  ${fragments.showcaseData}
`;

export const createShowcase = gql`
  mutation createShowcase($input: CreateShowcaseInput!) {
    createShowcase(input: $input) {
      ...showcaseUpdate
    }
  }
  ${fragments.showcaseUpdate}
`;

export const updateShowcase = gql`
  mutation updateShowcase($input: UpdateShowcaseInput!) {
    updateShowcase(input: $input) {
      ...showcaseUpdate
    }
  }
  ${fragments.showcaseUpdate}
`;

export const deleteShowcase = gql`
  mutation deleteShowcase($input: DeleteShowcaseInput!) {
    deleteShowcase(input: $input) {
      error
    }
  }
`;

const showcaseResolver = {
  queries: {
    getShowcases,
    getShowcase,
  },
  mutations: {
    createShowcase,
    updateShowcase,
    deleteShowcase,
  },
};

export default showcaseResolver;
