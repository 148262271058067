import React from 'react';
import {
  TableRow,
  TableHead,
  TableSortLabel,
  TableCell,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

import useStyles from './styles';
import { TableColumn } from '.';

interface TableHeaderProps {
  columns: TableColumn[];
  onRequestSort: (property: string) => void;
  sortBy: string;
  sortDirection: string;
}

const SharedTableHeader: React.FC<TableHeaderProps> = ({
  columns,
  onRequestSort,
  sortBy,
  sortDirection,
}) => {
  const classes = useStyles();

  const createSortHandler = (property: string) => () => {
    onRequestSort(property);
  };

  const direction = (sortId?: string) => {
    return sortBy === sortId
      ? sortDirection === 'DESC'
        ? 'desc'
        : 'asc'
      : undefined;
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sortDirection={direction(column.sortId)}
            className={clsx({
              [classes.stickyCell]: column.stickyColumn,
              [classes.stickyHeader]: column.stickyColumn,
            })}
          >
            {column.sortId ? (
              <TableSortLabel
                active={sortBy === column.sortId}
                onClick={createSortHandler(column.sortId ?? '')}
                IconComponent={ArrowDropDownIcon}
                direction={direction(column.sortId)}
                hideSortIcon={false}
              >
                {column.label}
                {sortBy === column.sortId && (
                  <div className={classes.selectedBar} />
                )}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SharedTableHeader;
