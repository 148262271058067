import React from 'react';
import { Field } from 'redux-form';
import {
  OutlinedInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import clsx from 'clsx';

import { FieldProps } from './index';
import useStyles from './styles';

interface PasswordFieldProps extends FieldProps {
  endAdornment: React.ReactNode;
  showPassword: boolean;
}

const renderPasswordField: React.FC<PasswordFieldProps> = ({
  label,
  placeholder,
  id,
  endAdornment,
  helperText,
  input,
  ...resProps
}) => {
  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        label={label}
        placeholder={placeholder ? placeholder : label}
        endAdornment={endAdornment}
        {...input}
        {...resProps}
      />
      {helperText && (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const InputReduxPasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  placeholder,
  required = false,
  disabled = false,
  endAdornment,
  showPassword,
  inputClass,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Field
      type={showPassword ? 'text' : 'password'}
      name={name}
      label={label}
      placeholder={placeholder}
      component={renderPasswordField}
      required={required}
      disabled={disabled}
      fullWidth
      endAdornment={endAdornment}
      className={
        inputClass ? clsx(classes.textInput, inputClass) : classes.textInput
      }
      {...restProps}
    />
  );
};

export default InputReduxPasswordField;
