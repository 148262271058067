import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  status: "error" | "success";
}

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    snackBar: {
      [theme.breakpoints.up("md")]: {
        right: "60px",
      },
    },
    container: {
      backgroundColor: (props: Props) => theme.palette[props.status].light,
      padding: "8px 16px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: {
      color: (props: Props) => theme.palette[props.status].dark,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      color: (props: Props) => theme.palette[props.status].dark,
      marginRight: "5px",
      fontSize: "16px",
    },
    clearIconBtn: {
      marginLeft: "40px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    },
    clearIcon: {
      color: (props: Props) => theme.palette[props.status].light,
      backgroundColor: (props: Props) => theme.palette[props.status].dark,
      borderRadius: "50%",
      fontSize: "16px",
    },
  })
);

export default useStyles;
