import gql from "graphql-tag";

const fragments = {
  salesEventData: gql`
    fragment salesEventData on SalesEventDto {
      id
      title
      description
      published
      startDate
      endDate
      image
      defaultCollection
      interests {
        id
        name
      }
    }
  `,
  salesEventUpdate: gql`
    fragment salesEventUpdate on SalesEventDto {
      id
      title
      description
      published
      startDate
      endDate
      image
      defaultCollection
      interests {
        id
        name
      }
    }
  `,
};

export const getSalesEvents = gql`
  query getEvents(
    $title: String
    $sortBy: String
    $sortDirection: String
    $page: Float
    $rowsPerPage: Float
    $published: String
    $status: String
  ) {
    salesEvents(
      input: {
        title: $title
        published: $published
        status: $status
        sort_by: $sortBy
        sort_direction: $sortDirection
        page: $page
        rowsPerPage: $rowsPerPage
      }
    ) {
      pagination {
        page
        rowsPerPage
        total
      }
      salesEvents {
        ...salesEventData
      }
      error
      messages
    }
  }
  ${fragments.salesEventData}
`;

export const getSalesEvent = gql`
  query getSalesEvent($id: String!) {
    getSalesEvent(id: $id) {
      ...salesEventData
      error
      messages
    }
  }
  ${fragments.salesEventData}
`;

export const createSalesEvent = gql`
  mutation createSalesEvent($input: CreateSalesEventInput!) {
    createSalesEvent(input: $input) {
      ...salesEventUpdate
    }
  }
  ${fragments.salesEventUpdate}
`;

export const updateSalesEvent = gql`
  mutation updateSalesEvent($input: UpdateSalesEventInput!) {
    updateSalesEvent(input: $input) {
      ...salesEventUpdate
    }
  }
  ${fragments.salesEventUpdate}
`;

export const deleteSalesEvent = gql`
  mutation deleteSalesEvent($input: DeleteSalesEventInput!) {
    deleteSalesEvent(input: $input) {
      error
    }
  }
`;

const salesEventsResolver = {
  queries: {
    getSalesEvents,
    getSalesEvent,
  },
  mutations: {
    createSalesEvent,
    updateSalesEvent,
    deleteSalesEvent,
  },
};

export default salesEventsResolver;
