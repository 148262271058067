import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { getToken } from './storage';
import { graphqlURL } from '@constants';

const createAppLink = createUploadLink({
  uri: graphqlURL,
  credentials: 'include',
});

const httpLink = ApolloLink.from([createAppLink]);

const cache = new InMemoryCache({});

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = getToken();
    if (token) {
      operation.setContext({
        headers: {
          authorization: `${token}`,
        },
      });
    }

    return forward(operation);
  });

const useAppApolloClient = () => {
  return new ApolloClient({
    link: authMiddleware().concat(httpLink),
    cache,
  });
};

export default useAppApolloClient;
