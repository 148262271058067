import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import { prospectStatus } from "@constants";
import { getOptionsFromArray } from "@helpers/getFiltersOptions";

const initialOptions: FiltersOptionsGroup[] = [
  {
    title: "Status",
    groupId: "status",
    options: getOptionsFromArray(Object.keys(prospectStatus)),
    type: "radio",
  },
];

export default initialOptions;
