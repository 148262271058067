import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallBottomMargin: {
      marginBottom: "16px",
    },
    bottomMargin: {
      marginBottom: "30px",
    },
    rowWrapContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    collectionFilter: {
      maxWidth: "510px",
      marginBottom: "12px",
    },
    collectionOptionName: {
      marginTop: "6px",
      marginBottom: "10px",
    },
    collectionControlLabel: {
      width: "100%",
    },
    collectionContainer: {
      padding: "6px",
      marginRight: "16px",
    },
    parentWidth: {
      width: "100%",
    },
    formGroup: {
      display: "flex",
      width: "530px",
      flexWrap: "wrap",
      flexDirection: "row",
      border: '0px',
      justifyContent: "space-between",
    },
  })
);

export default useStyles;
