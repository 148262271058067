import { DateTime } from 'luxon';

const getHoursFromMins = (minutes: number) => {
  return `${Math.round(minutes / 60)} hrs.`;
};

const getMinsFromSecs = (seconds: number) => {
  const minutes = Math.round(seconds / 60);
  return minutes > 59 ? getHoursFromMins(minutes) : `${minutes} min.`;
};

// Returns date with format: Today - 12:20 PM  /  April 25 - 12:20 PM
const getDate = (date: string, isMillis = false) => {
  if (!date) {
    return '--';
  }
  const now = DateTime.now();
  const paramDate = isMillis
    ? DateTime.fromMillis(parseInt(`${date}000`))
    : DateTime.fromISO(date);
  if (now.hasSame(paramDate, 'day')) {
    return `Today - ${paramDate.toFormat('hh:mm a')}`;
  } else if (now.hasSame(paramDate, 'year')) {
    return paramDate.toFormat('LLLL d - hh:mm a');
  } else {
    return paramDate.toFormat('yyyy LLLL d - hh:mm a');
  }
};

const getPlainDate = (date: string, isMillis = false) => {
  if (!date) {
    return '--';
  }
  const paramDate = isMillis
    ? DateTime.fromMillis(parseInt(`${date}000`))
    : DateTime.fromISO(date);
  return paramDate.toFormat('dd/MM/yyyy');
};

const timeHelpers = {
  getInterval: (seconds: number) => {
    return getMinsFromSecs(seconds);
  },
  getDate: getDate,
  getPlainDate: getPlainDate,
};

export default timeHelpers;
