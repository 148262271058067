import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";

import RecoverPasswordForm from "./recoverPasswordForm";
import useStyles from "@pages/login/styles";
import AuthGraphQL from "@graphql/authentication.resolver";

export interface RecoverPasswordInput {
  email: string;
}

function RecoverPassword() {
  const classes = useStyles();
  const [recoverPassword, { loading, error, data }] = useLazyQuery(
    AuthGraphQL.queries.recoverPassword
  );

  const onSubmit = (values: RecoverPasswordInput) => {
    recoverPassword({
      variables: {
        user: values,
      },
    });
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12} md={12}>
        <Typography variant="h2" className={classes.title}>
          {data?.recoverPasswordEmail ? "Check your email" : "Forgot password?"}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {data?.recoverPasswordEmail
            ? "We have sent a password recover instructrions to your email"
            : "Enter your register email and we will send you an email with instructions to reset your password"}
        </Typography>
        {error && (
          <Typography
            variant="body1"
            className={classes.subtitle}
            color="error"
          >
            User not found. Please make sure that the email entered belongs to a
            registered user.
          </Typography>
        )}
      </Grid>
      {!data?.recoverPasswordEmail && (
        <Grid item xs={12} md={12}>
          <RecoverPasswordForm onSubmit={onSubmit} loading={loading} />
        </Grid>
      )}
    </Grid>
  );
}

export default RecoverPassword;
