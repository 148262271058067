import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import useStyles from "./styles";

interface FileSelectorProps {
  onSelectFile: (file: File | null) => void;
  defaultFile?: string | null;
}

const FileSelector: React.FC<FileSelectorProps> = ({
  onSelectFile,
  defaultFile,
}) => {
  const classes = useStyles();
  const fileInput = useRef(null);
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>();

  const showSelectFilePromptOrRemove = () => {
    if (file) {
      setFile(null);
      onSelectFile(null);
    } else {
      if (fileInput) {
        const element: HTMLElement =
          fileInput.current as unknown as HTMLElement;
        element.click();
      }
    }
  };

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.[0]) {
      const file = event.target?.files?.[0];
      onSelectFile(file);
      setFile(file);
    }
  };

  const displayImage = file ? preview : defaultFile;

  return (
    <div className={classes.fileSelector}>
      <div className={classes.fileDescription}>
        <div>Hero Image for the Event</div>
        <div>
          <Button
            color="primary"
            aria-label="show-filters"
            className={classes.selectFileButton}
            onClick={showSelectFilePromptOrRemove}
          >
            {file ? <HighlightOffIcon /> : <InsertDriveFileIcon />}
            <Typography color="primary" className={classes.selectFileText}>
              {file?.name || "Select File"}
            </Typography>
          </Button>
        </div>

        {!file?.name && (
          <input
            type="file"
            ref={fileInput}
            onChange={handleSelectFile}
            className={classes.hidden}
          />
        )}
      </div>
      {displayImage && (
        <div className={classes.filePreview}>
          <div className={classes.filePreviewTitle}>Preview:</div>
          <img src={displayImage} className={classes.filePreviewImage} />
        </div>
      )}
    </div>
  );
};

export default FileSelector;
