import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#000",
      maxHeight: '640px',
      overflow:'scroll',
      padding: '10px',
      border: '1px solid lightgrey'
    },
    calendly: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "left",
      fontWeight: "bolder",
      color: "#e8701c",
    },
    banner: {
      marginBottom: "40px",
      width: "100%",
    },
  })
);

export default useStyles;
