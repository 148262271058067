import React from "react";
import { useHistory } from "react-router";
import {
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import { RootState } from "@redux/store";
import { useAppSelector } from "@redux/hooks";
import { NestedObject } from "@models/common";
import { User } from "@models/user.model";
import { timeHelpers } from "@helpers";
import { ActionsCell } from "./Cells";
import useStyles from "./styles";
import { TableColumn } from ".";

interface TableBodyProps {
  columns: TableColumn[];
  rows: NestedObject[];
  loading: boolean;
}

const SharedTableBody: React.FC<TableBodyProps> = ({
  columns,
  rows,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const user: User = useAppSelector((state: RootState) => state.user.user);

  const goToLink = (path: string) => {
    history.push(path);
  };

  return (
    <TableBody>
      {!loading &&
        rows?.map((row: NestedObject, index: number) => (
          <TableRow key={`table-row-${index}`}>
            {columns.map((column: TableColumn) => {
              if (column.type === "actions") {
                return (
                  <ActionsCell
                    key={`table-cell-${index}-${column.id}`}
                    index={index}
                    actions={column.actions}
                    goToLink={goToLink}
                    row={row}
                  />
                );
              }

              return (
                <TableCell
                  key={`table-cell-${index}-${column.id}`}
                  className={clsx({
                    [classes.stickyCell]: column.stickyColumn,
                  })}
                >
                  <Typography variant="caption">
                    {column.type === "date" &&
                      timeHelpers.getDate(row[column.id])}
                    {/* {column.type === "text" && descend(row, column.id)}*/}
                    {column.type === "text" && row[column.id]}
                    {column.type === "custom" &&
                      column.parser &&
                      column.parser(row, { user })}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      {loading && (
        <TableRow>
          <TableCell colSpan={columns.length} className={classes.centeredCell}>
            <CircularProgress size={50} thickness={5} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default SharedTableBody;
