import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { reduxForm, InjectedFormProps } from "redux-form";

import { TextField } from "@components/Form";
import { RecoverPasswordInput } from "./recoverPassword";
import { AccessFormProps } from "@pages/login/loginForm";
import loginStyles from "@pages/login/styles";
import useStyles from "@components/Form/styles";

const RecoverPasswordForm: React.FC<
  AccessFormProps &
    InjectedFormProps<RecoverPasswordInput, AccessFormProps, string>
> = ({ handleSubmit, submitting, loading }) => {
  const classes = loginStyles();
  const formClasses = useStyles();
  return (
    <form onSubmit={handleSubmit} className={formClasses.formContainer}>
      <TextField
        name="email"
        label="Email Address"
        required={true}
        meta={{
          variant: "outlined",
        }}
        inputClass={classes.accessInput}
      />
      <Button
        className={formClasses.submitButton}
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitting || loading}
      >
        {!loading ? "Send email" : <CircularProgress size={25} />}
      </Button>
    </form>
  );
};

const RecoverPasswordReduxForm = reduxForm<
  RecoverPasswordInput,
  AccessFormProps
>({
  form: "LoginForm",
})(RecoverPasswordForm);

export default RecoverPasswordReduxForm;
