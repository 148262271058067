import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    searchBar: {
      minWidth: '385px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '1px',
        width: 'calc(100vw - 140px)',
      },
    },
    filtersBtn: {
      marginLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    popoverContent: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    filterGroupContainer: {
      marginRight: '30px',
      '&:last-child': {
        marginRight: '0',
      },
    },
    filterGroup: {
      flexDirection: 'column',
    },
    filterGroupTile: {
      marginBottom: '20px',
    },
    filtersBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
    },
    chipsContainer: {
      marginTop: '15px',
    },
    chip: {
      marginLeft: '15px',
    },
  }),
);

export default useStyles;
