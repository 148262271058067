import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import { useLazyQuery } from "@apollo/client";
import dashboardResolver from "@graphql/dashboard.resolver";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  getDashboardMonthLabels,
  monthNumberToName,
  parseSalesTableData,
} from "@helpers";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setAlert } from "@redux/reducers/alert.reducer";
import SalesTable, { TableData } from "./SalesTable";
import { RootState } from "@redux/store";
import { isDashboardEnabled } from "@constants";

export type CharData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
};

export type Merchant = {
  email: string;
  title: string;
  grossSales: (string | number)[][];
  taxes: (string | number)[][];
  orderedProductQty: (string | number)[][];
};

export type DashboardAdminData = {
  merchants: Merchant[];
  error: string | null;
  messages: string[] | null;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Sales",
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const labels = getDashboardMonthLabels();

const backgroundColor = "#e8701c";

const optionValueToTitle = (value: string) => {
  switch (value) {
    case "grossSales":
      return "Gross sales";
    case "orderedProductQty":
      return "Units sold";
    case "taxes":
      return "Taxes";
  }

  throw new Error("Invalid value");
};

const DashboardPage: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [chartData, setChartData] = useState<CharData>();
  const [tableData, setTableData] = useState<TableData>();
  const [noDefaultCollection, setNoDefaultCollection] = useState<boolean>();
  const [getDashboardData, { loading, error, data }] = useLazyQuery(
    dashboardResolver.queries.getDashboardData,
    {
      fetchPolicy: "network-only",
    }
  );
  const [
    getDashboardAdminData,
    { loading: adminLoading, error: adminError, data: adminData },
  ] = useLazyQuery(dashboardResolver.queries.getDashboardAdminData, {
    fetchPolicy: "network-only",
  });
  const [fieldSelected, setFieldSelected] = useState<
    "grossSales" | "orderedProductQty" | "taxes"
  >("grossSales");
  const [max, setMax] = useState<number>();
  const {
    user: { role },
  } = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  if (error) {
    console.log("ERROR getData QUERY");
  }

  useEffect(() => {
    if (isDashboardEnabled) {
      getDashboardData();
    }
  }, []);

  useEffect(() => {
    if (role === "ADMIN") {
      getDashboardAdminData();
    }
  }, [role]);

  useEffect(() => {
    if (data?.getDashboardData) {
      if (!data.getDashboardData.error) {
        const newChartData = {
          labels,
          datasets: [
            {
              label: optionValueToTitle(fieldSelected),
              data: labels.map((label) => {
                const selectedData = data.getDashboardData?.[
                  fieldSelected
                ]?.find(
                  ([date]: string[]) =>
                    monthNumberToName(new Date(date).getMonth() + 1) === label
                );

                if (selectedData) return Number(selectedData[1]);
                return 0;
              }),
              backgroundColor,
            },
          ],
        };

        let newMax: number = 0;

        data.getDashboardData?.[fieldSelected]?.forEach(
          ([, amount]: (string | number)[]) => {
            if (Number(amount) > newMax) newMax = Number(amount);
          }
        );

        setChartData(newChartData);
        setMax(Math.floor(newMax * 1.1));
      } else if (
        data.getDashboardData.messages.includes(
          "Please contact the admin to set up a collection"
        )
      ) {
        setNoDefaultCollection(true);
      } else {
        dispatch(
          setAlert({
            message: "Failed on getting dashboard data",
            type: "error",
          })
        );
      }
    }
  }, [data, fieldSelected]);

  useEffect(() => {
    if (adminData?.getDashboardAdminData && chartData) {
      if (!adminData.getDashboardAdminData.error) {
        const { merchants }: DashboardAdminData =
          adminData.getDashboardAdminData;

        const data = parseSalesTableData(
          merchants,
          fieldSelected,
          chartData.datasets[0].data
        );

        setTableData(data);
      } else {
        dispatch(
          setAlert({
            message: "Failed on getting dashboard admin data",
            type: "error",
          })
        );
      }
    }
  }, [adminData, fieldSelected, chartData]);

  const handleOnDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    setFieldSelected(
      event.target.value as unknown as
        | "grossSales"
        | "taxes"
        | "orderedProductQty"
    );
  };

  return isDashboardEnabled ? (
    <Grid container>
      {isMobile && <Grid item xs={12} md={12}></Grid>}
      <Grid item xs={12} md={12}>
        <Grid container spacing={isMd ? 2 : 4}></Grid>
        {noDefaultCollection && (
          <Typography
            variant="body1"
            color="textSecondary"
            gutterBottom={true}
            className={classes.noCollectionMessage}
          >
            There are no sales available to display. <br />
            Please contact the administrator to finish setting up your product
            portfolio
          </Typography>
        )}

        {chartData && (
          <>
            <select value={fieldSelected} onChange={handleOnDropdownChange}>
              <option value="grossSales">Product sales</option>
              <option value="taxes">Taxes</option>
              <option value="orderedProductQty">Units sold</option>
            </select>
            <Bar
              options={{
                ...options,
                scales: { y: { ...options.scales.y, max } },
              }}
              data={chartData}
              width="1200px"
              height="280px"
            />
          </>
        )}

        {tableData && (
          <SalesTable
            tableData={tableData}
            currencySymbol={
              fieldSelected !== "orderedProductQty" ? "$" : undefined
            }
            decimals={fieldSelected !== "orderedProductQty" ? 2 : 0}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default DashboardPage;
