import gql from "graphql-tag";

export const getDashboardData = gql`
  query getDashboardData {
    getDashboardData {
      grossSales
      netSales
      orderedProductQty
      taxes
      error
      messages
    }
  }
`;

export const getDashboardAdminData = gql`
  query getDashboardAdminData {
    getDashboardAdminData {
      merchants {
        email
        title
        grossSales
        orderedProductQty
        taxes
      }
      error
      messages
    }
  }
`;

const dashboardResolver = {
  queries: {
    getDashboardData,
    getDashboardAdminData,
  },
};

export default dashboardResolver;
