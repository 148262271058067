import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import { roles } from "@constants";
import { getOptionsFromArray } from "@helpers/getFiltersOptions";

const initialOptions: FiltersOptionsGroup[] = [
  {
    title: "Role",
    groupId: "role",
    options: getOptionsFromArray(Object.keys(roles)),
    type: "radio",
  },
];

export default initialOptions;
