import React, { useEffect, useState, useRef } from 'react';
import {
  Popover,
  RadioGroup,
  Radio,
  FormGroup,
  FormControlLabel,
  Button,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { remove } from 'lodash';

import { NestedObject } from '@models/common';
import useStyles from './styles';
import { noFiltersToShow } from '@constants';

export interface FiltersOption {
  label: string;
  value: string;
}

export interface FiltersOptionsGroup {
  title: string;
  groupId: string;
  options: FiltersOption[];
  type: 'radio' | 'checkbox';
}

interface FiltersDialogProps {
  open: boolean;
  classes?: Record<'paper', string>;
  values: NestedObject;
  options: FiltersOptionsGroup[];
  anchorEl: HTMLButtonElement | null;
  onClose: (newValues?: NestedObject) => void;
}

const FiltersDialog: React.FC<FiltersDialogProps> = ({
  open,
  values: valueProp,
  options,
  anchorEl,
  onClose,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef?.current) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (type: 'radio' | 'checkbox') => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.target as HTMLInputElement;
    if (type === 'checkbox') {
      const newValue = [...value[target.name]];
      if (target.checked) {
        newValue.push(target.value);
      } else {
        remove(newValue, (current) => {
          return current === target.value;
        });
      }
      setValue({
        ...value,
        [target.name]: newValue,
      });
    } else {
      setValue({ ...value, [target.name]: target.value });
    }
  };

  return (
    <Popover
      id="filters-popover"
      onEntering={handleEntering}
      onBackdropClick={handleCancel}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.popoverContent}>
        {options.map((optionsGroup: FiltersOptionsGroup, index: number) => (
          <div
            key={`filter-group-${index}`}
            className={classes.filterGroupContainer}
          >
            <Typography variant="button" className={classes.filterGroupTile}>
              {optionsGroup.title}
            </Typography>
            {optionsGroup.type === 'radio' && (
              <RadioGroup
                ref={radioGroupRef}
                className={classes.filterGroup}
                aria-label={`filter-group-${optionsGroup.groupId}`}
                name={`${optionsGroup.groupId}`}
                value={value[optionsGroup.groupId]}
                onChange={handleChange('radio')}
              >
                {optionsGroup.options.map((option: FiltersOption) => (
                  <FormControlLabel
                    value={option.value}
                    key={option.value}
                    control={<Radio />}
                    label={
                      <Typography
                        title={option.label}
                        variant="body1"
                        component="div"
                      >
                        {option.label}
                      </Typography>
                    }
                  />
                ))}
                {optionsGroup.options.length === 0 && (
                  <Typography variant="body1">
                    {noFiltersToShow(optionsGroup.title)}
                  </Typography>
                )}
              </RadioGroup>
            )}
            {optionsGroup.type === 'checkbox' && (
              <FormGroup>
                {optionsGroup.options.map((option: FiltersOption) => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        checked={value[optionsGroup.groupId].includes(
                          option.value,
                        )}
                        onChange={handleChange('checkbox')}
                        name={optionsGroup.groupId}
                        value={option.value}
                      />
                    }
                    label={option.label}
                  />
                ))}
                {optionsGroup.options.length === 0 && (
                  <Typography variant="body1">
                    {noFiltersToShow(optionsGroup.title)}
                  </Typography>
                )}
              </FormGroup>
            )}
          </div>
        ))}
      </div>
      <div className={classes.filtersBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={handleOk}
        >
          Apply
        </Button>
      </div>
    </Popover>
  );
};

export default FiltersDialog;
