import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    fileSelector: {
      marginBottom: "18px",
      marginTop: "10px",
      borderRadius: "4px",
      border: "1px solid #d4d4d4",
      width: "520px",
      padding: "12px",
    },
    fileDescription: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    selectFileButton: {
      paddingLeft: "0px",
    },
    selectFileText: {
      fontWeight: "bold",
      marginLeft: "10px",
      paddingTop: "4px",
    },
    hidden: {
      display: "none",
    },
    filePreview: {
      width: "520px",
    },
    filePreviewTitle: {
      marginBottom: "10px"
    },
    filePreviewImage: {
      width: "490px"
    },
  })
);

export default useStyles;
