import { MappedObject } from "./models/common";

const apiURL = process.env.REACT_APP_API_URL;

export const graphqlURL = `${apiURL}graphql`;
export const toastTimer = 6000;

export const isDashboardEnabled =
  process.env.REACT_APP_DASHBOARD_STATUS === "ENABLED";

export const pagesTitlesMap: MappedObject = {
  dashboard: "Dashboard",
};

export const roles: MappedObject = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
  MERCHANT: "MERCHANT",
};

export const published: MappedObject = {
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFT",
};

export const status: MappedObject = {
  CONCLUDED: "CONCLUDED",
  LIVE: "LIVE",
  UPCOMING: "UPCOMING",
};

export const prospectStatus: MappedObject = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ONBOARDED: "ONBOARDED",
};

export const noFiltersToShow = (type: string) => `No ${type}s to filter`;
