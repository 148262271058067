import gql from "graphql-tag";

export const getProspectStatus = gql`
  query prospectStatus {
    prospectStatus {
      handle
      title
    }
  }
`;

const prospectStatusResolver = {
  queries: {
    getProspectStatus,
    // getUser,
  },
  //   mutations: {
  //     updateUser,
  //   },
};

export default prospectStatusResolver;
