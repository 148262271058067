import { MappedObject } from '@models/common';

const parseFilters = (filters: MappedObject) => {
  const result: MappedObject = {};
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const filter = filters[key];
      if (
        (typeof filter === 'string' && filter !== '') ||
        (Array.isArray(filter) && filter.length !== 0) ||
        typeof filter === 'number'
      ) {
        result[key] = filter;
      }
    }
  }
  return result;
};

export default parseFilters;
