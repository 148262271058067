import gql from "graphql-tag";

export const getProspects = gql`
  query getProspects(
    $status: String
    $sortBy: String
    $sortDirection: String
    $page: Float
    $rowsPerPage: Float
    $email: String
  ) {
    prospects(
      input: {
        status: $status
        sort_by: $sortBy
        sort_direction: $sortDirection
        page: $page
        rowsPerPage: $rowsPerPage
        email: $email
      }
    ) {
      assets {
        id
        status
        annualSales
        businessCategory
        businessDescription
        businessName
        businessType
        companyWebsite
        contactFirstName
        contactLastName
        email
        jobTitle
        lastUpdateDate
        mailingList
        marketplaces
        otherRetailers
        productOrigin
        prospectOther
        socialFollowing
      }
      pagination {
        page
        total
      }
    }
  }
`;

const addOrUpdateProspect = gql`
  mutation addOrUpdateProspect($id: String!, $status: String) {
    addOrUpdateProspect(input: { id: $id, status: $status }) {
      id
      status
    }
  }
`;

export const getIntakeStatus = gql`
  query getIntakeStatus {
    getIntakeStatus {
      status
      error
      messages
    }
  }
`;

const prospectResolver = {
  queries: {
    getProspects,
    getIntakeStatus,
  },
  mutations: {
    addOrUpdateProspect,
  },
};

export default prospectResolver;
