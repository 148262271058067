import { Theme, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      height: "calc(100vh - 300px)",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        maxHeight: "calc(100vh - 300px)",
      },
    },
    table: {
      width: "100%",
    },
    tablePagination: {
      width: "100%",
    },
    centeredCell: {
      textAlign: "center",
    },
    paginationsActions: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    selectedBar: {
      backgroundColor: theme.palette.primary.main,
      bottom: "-16px",
      width: "100%",
      height: "8px",
      position: "absolute",
      display: "flex",
      borderRadius: "4px",
    },
    filtersContainer: {
      marginBottom: "15px",
      display: "flex",
      flexDirection: "row",
    },
    createNewContainer: {
      marginLeft: "10px",
      marginBottom: "12px",
    },
    editContainer: {
      marginLeft: "10px",
      marginBottom: "12px",
    },
    firstButton: {
      marginRight: "15px",
    },
    listTypeButton: {
      color: theme.palette.text.primary,
      marginRight: "15px",
      "&:disabled": {
        color: theme.palette.primary.main,
      },
    },
    listTypeContainer: {
      marginBottom: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mapContainer: {
      padding: 0,
    },
    legendsContainer: {
      justifyContent: "flex-end",
      marginTop: "15px",
      padding: "0",
    },
    legendsInnerContainer: {
      display: "flex",
      padding: "15px 30px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },
    legendContainer: {
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        textAlign: "left",
        marginBottom: "5px",
      },
      [theme.breakpoints.up("lg")]: {
        // width: '14%',
        maxWidth: "14%",
        flexBasis: "14%",
      },
    },
    boldLink: {
      fontWeight: "bold",
    },
    stickyCell: {
      position: "sticky",
      zIndex: 0,
      left: 0,
      backgroundColor: theme.palette.common.white,
    },
    stickyHeader: {
      zIndex: 200,
    },
  })
);

export default useStyles;
