import { Merchant } from "@pages/dashboard/dashboard";
import getDashboardMonthLabels from "./getDashboardLabels";
import monthNumberToName from "./monthNumberToName";

const parseSalesTableData = (
  data: Merchant[],
  fieldSelected: "grossSales" | "taxes" | "orderedProductQty",
  totals: number[]
) => {
  const headers = getDashboardMonthLabels();

  const rows: (string | number)[][] = data.map((merchant) => {
    const row: (string | number)[] = [merchant.title];

    headers.forEach((month, index) => {
      const _index = merchant[fieldSelected].findIndex(
        ([date]) => monthNumberToName(new Date(date).getMonth() + 1) === month
      );

      if (_index === -1) {
        row[index + 1] = 0;
      } else {
        row[index + 1] = merchant[fieldSelected][_index][1];
      }
    });

    return row;
  });

  const lastRow: (string | number)[] = ["Others", ...totals];

  rows.forEach((row) => {
    for (let i = 1; i < row.length; i++) {
      lastRow[i] = Number(lastRow[i]) - Number(row[i]);
    }
  });

  rows.push(lastRow);

  const totalsToReturn = ["Total", ...totals];

  headers.unshift("");

  return { headers, rows, totals: totalsToReturn };
};

export default parseSalesTableData;
