import React from "react";

import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ChevronLeftOutlined from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlined from "@material-ui/icons/ChevronRightOutlined";
import { Video } from "@models/video.model";
import VideoViewer from "./VideoViewer";

export interface MiniVideoPreviewProps {
  videos: Video[];
  showData: boolean;
  reorderButtons: boolean;
  handleClick: (id: string) => void;
  handleReorder?: (idx: number, direction: "forward" | "back") => void;
  action: "add" | "remove";
}

const MiniVideoPreview: React.FC<MiniVideoPreviewProps> = ({
  videos,
  handleClick,
  reorderButtons,
  handleReorder,
  showData,
  action,
}) => {

  return (
    <>
      {videos.map((video: Video, idx: number) => {
        return (
          <div
            style={{
              position: "relative",
              maxWidth: "150px",
              width: "150px",
              marginBottom: reorderButtons ? "30px" : "0",
            }}
            key={video.id}
          >
            <VideoViewer video={video} />
            
            <IconButton
              aria-label="add-remove-trending"
              onClick={() => handleClick(video.id)}
              edge="end"
              style={{
                position: "absolute",
                top: "0",
                right: "10px",
              }}
            >
              {action === "add" ? (
                <AddCircleIcon color="primary" />
              ) : (
                <RemoveCircleIcon color="primary" />
              )}
            </IconButton>
            {reorderButtons && handleReorder && (
              <>
                <IconButton
                  aria-label="move-forward"
                  onClick={() => handleReorder(idx, "forward")}
                  edge="end"
                  style={{
                    position: "absolute",
                    bottom: "-30px",
                    left: "-10px",
                  }}
                >
                  <ChevronLeftOutlined color="primary" />
                </IconButton>

                <IconButton
                  aria-label="create-new-child"
                  onClick={() => handleReorder(idx, "back")}
                  edge="end"
                  style={{
                    position: "absolute",
                    bottom: "-30px",
                    right: "0",
                  }}
                >
                  <ChevronRightOutlined color="primary" />
                </IconButton>
              </>
            )}
            {showData && (
              <>
                <p></p>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MiniVideoPreview;
