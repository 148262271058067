import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "@pages/userForm/styles";
import { setChosenStatus } from "@redux/reducers/prospectStatus.reducer";
import { Prospect } from "@models/prospect.model";
import { ProspectStatus as ProspectStatusModel } from "@models/prospectStatus.model";

const ProspectStatusComponent: React.FC = () => {
  const pageClasses = useStyles();
  const radioGroupRef = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();

  const selectedProspect: Prospect = useAppSelector(
    (state: RootState) => state.prospects.selectedProspect
  );

  const statuses: ProspectStatusModel[] = useAppSelector(
    (state: RootState) => state.prospectsStatus.prospectStatus
  );

  const [statusHandle, setStatusHandle] = useState<string>(
    selectedProspect?.status ?? ""
  );

  useEffect(() => {
    if (selectedProspect?.status) {
      setStatusHandle(selectedProspect?.status);
      onChangeStatus(selectedProspect?.status);
    }
  }, [selectedProspect]);

  const changeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    setStatusHandle(target.value);
    onChangeStatus(target.value);
  };

  const onChangeStatus = (handle: string) => {
    dispatch(
      setChosenStatus(statuses.find((status) => status.handle === handle) ?? {})
    );
  };

  const renderStatusContent = (status: ProspectStatusModel) => (
    <div>
      <Typography
        title={status.title}
        className={pageClasses.roleOptionName}
        variant="body1"
        component="p"
      >
        {status.title}
      </Typography>
    </div>
  );

  const renderStatusPaper = (status: ProspectStatusModel) => (
    <FormControlLabel
      key={status.handle}
      value={status.handle}
      className={pageClasses.roleControlLabel}
      control={<Radio />}
      label={renderStatusContent(status)}
    />
  );

  return (
    <Grid xs={12} md={6} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        Select prospect status
      </Typography>

      <RadioGroup
        ref={radioGroupRef}
        aria-label="prospect-status"
        name="prospect-status"
        value={statusHandle}
        onChange={changeStatus}
        className={pageClasses.rowContainer}
      >
        {statuses.map((status: ProspectStatusModel) =>
          renderStatusPaper(status)
        )}
      </RadioGroup>
    </Grid>
  );
};

export default ProspectStatusComponent;
