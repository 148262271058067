import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const formURL = process.env.REACT_APP_INTAKE_FORM_URL;

function IntakeForm() {
  const history = useHistory();

  if (!formURL) {
    history.push("/login");
  }
  
  return (
    <Grid container direction="column">
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
      <iframe
        title="Intake Form"
        className="airtable-embed airtable-dynamic-height"
        src={formURL}
        frameBorder={0}
        width="100%"
        height="533"
        style={{
          background: "transparent",
          marginTop: "20px",
        }}
      ></iframe>
    </Grid>
  );
}

export default IntakeForm;
