import gql from "graphql-tag";

export const getCollections = gql`
  query collections {
    collections {
      collections {
        title
        handle
        id
        image
      }
    }
  }
`;

const collectionsResolver = {
  queries: {
    getCollections,
    // getUser,
  },
  //   mutations: {
  //     updateUser,
  //   },
};

export default collectionsResolver;
