import { TableAction, TableColumn } from "@components/Table";
import CollectionParser from "@pages/users/collectionParser";

export const salesEventsColumns: TableColumn[] = [
  {
    id: "status",
    label: "Status",
    type: "custom",
    parser: (item) => {
      if (new Date(item.startDate) < new Date()) {
        if (new Date() > new Date(item.endDate)) {
          return <div style={{ color: "red" }}>Concluded</div>;
        }
        return <div style={{ color: "green" }}>Ongoing</div>;
      } else {
        return <div style={{ color: "blue" }}>Upcoming</div>;
      }
    },
  },
  {
    id: "title",
    label: "Title",
    type: "text",
    stickyColumn: true,
  },
  {
    id: "published",
    label: "Published",
    type: "custom",
    parser: (item) => {
      return item.published === true ? (
        <div style={{ color: "green" }}>YES</div>
      ) : (
        <div style={{ color: "red" }}>No (Draft)</div>
      );
    },
  },
  {
    id: "description",
    label: "Description",
    type: "custom",
    parser: (item) => {
      return item.description.slice(0,50) + '...';
    },
  },
  {
    id: "defaultCollection",
    label: "Default Collection",
    type: "custom",
    parser: (item) => {
      return <CollectionParser collection={item.defaultCollection} />;
    },
  },
  {
    id: "startDate",
    label: "Start Date",
    type: "date",
  },
  {
    id: "endDate",
    label: "End Date",
    type: "date",
  },
  {
    id: "actions",
    label: "Actions",
    type: "actions",
    actions: [
      {
        actionId: "id",
        actionType: "link",
        label: "Edit Event",
        link: "edit-sales-event/<actionId>",
      },
      {
        actionId: "id",
        actionType: "link",
        label: "See Showcases",
        link: "sales-event/<actionId>/showcases",
      },
    ],
  },
];

export const salesEventsActions: TableAction[] = [
  {
    actionId: "id",
    actionType: "link",
    label: "Edit Event",
    link: "edit-sales-event/<actionId>",
  },
  {
    actionId: "id",
    actionType: "link",
    label: "See Showcases",
    link: "sales-event/<actionId>/showcases",
  },
];
