import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import background from "@images/background.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      minHeight: "100vh",
    },
    content: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      height: "100%",
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15),
      },
    },
    background: {
      background: `url(${background})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundColor: "black",
    },
    backgroundMobile: {
      minHeight: "35vh",
      backgroundPositionY: "70%",
    },
    contentContainerMobile: {
      minHeight: "60vh",
      margin: "0 20px",
      transform: "translateY(-60px)",
    },
    contentMobile: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15),
      },
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    backIcon: {
      position: "absolute",
      top: "20px",
      left: "8px",
      color: "#ffffff",
    },
    backButton: {
      position: "absolute",
      top: "80px",
      width: "fit-content",
      paddingLeft: "0",
    },
    logo: {
      marginBottom: "40px",
      maxWidth: "110px",
    },
  })
);

export default useStyles;
