import React from "react";
import { ApolloProvider } from "@apollo/client/react";

import App from "./App";
import useAppApolloClient from "@config/graphqlClient";

function AppContainer() {
  const apolloClient = useAppApolloClient();
  return (
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  );
}

export default AppContainer;
