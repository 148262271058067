import React from 'react';
import { NestedObject } from '@models/common';
import SharedTable from './SharedTable';

export type TableAction = {
  label: string;
  actionId: string;
  actionType: string;
  parentId?: string;
  // In the link always set <actionId> for the dynamic part of the link.
  // If the action needs no link, set an empty string
  link: string;
  pageAction?: (actionId: string) => void;
};

/**
 * @param id: Value to access the data to show
 * @param label: Label of the column. Text in the header cell
 * @param type: Type of the column data.
 * @param sortId: The id to send to the BE for sorting. In some cases it can be diffrent from 'id'
 * @param actions: Array of actions added to the column of type 'actions'
 * @param parser: Receives the item (row) and returns a value parsed from it.
 */
export type TableColumn = {
  id: string;
  label: string;
  type:
    | 'text'
    | 'actions'
    | 'custom'
    | 'date'
  sortId?: string;
  actions?: TableAction[];
  parser?: (
    item: NestedObject,
    otherData?: NestedObject,
  ) => string | number | React.ReactElement;
  isBold?: (item: NestedObject) => boolean;
  stickyColumn?: boolean;
};

export default SharedTable;
