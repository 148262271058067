import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";

import UsersGraphQL from "@graphql/users.resolver";
import { User } from "@models/user.model";
import useStyles from "./styles";
import { debounce } from "lodash";
import { NestedObject } from "@models/common";

export interface UserSelectorProps {
  headerTitle?: string;
  selectedUserEmail: string | null;
  onChangeSelectedUserEmail?: (userEmail: string) => void;
}

const UserSelector: React.FC<UserSelectorProps> = ({
  headerTitle,
  selectedUserEmail,
  onChangeSelectedUserEmail,
}) => {
  const [values, setValues] = useState<NestedObject>({});
  const pageClasses = useStyles();
  const radioGroupRef = useRef<HTMLElement>(null);
  const [filter, setFilter] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  // const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const handleChangeSelectedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    if (target.value && onChangeSelectedUserEmail) {
      onChangeSelectedUserEmail(target.value);
    }
  };

  useEffect(() => {
    if (selectedUserEmail) {
      setFilter(selectedUserEmail);
    }
  }, [selectedUserEmail]);

  const [getData, { loading, error, data }] = useLazyQuery(
    UsersGraphQL.queries.getUsers,
    {
      fetchPolicy: "network-only",
    }
  );

  if (error) {
    console.log("ERROR getData QUERY");
  }

  useEffect(() => {
    if (data?.users && !data?.users?.error) {
      const users = data?.users?.users || [];
      setUsers(
        users
          .filter((user: User) =>
            user.email?.toLowerCase().includes(filter.toLowerCase())
          )
          .slice(0, 4)
      );
    }
  }, [data]);

  const setNameFilterDebounce = useRef(debounce(setValues, 1000));

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setNameFilterDebounce.current({
      ...values,
      email: event.target.value,
    });
  };

  useEffect(() => {
    getData({
      variables: {
        email: filter,
        role: "MERCHANT",
      },
    });
  }, [values]);

  const renderUserContent = (user: User) => (
    <div>
      <Typography
        title={user.email}
        className={pageClasses.userOptionName}
        variant="body1"
        component="p"
      >
        {user.email}
      </Typography>
    </div>
  );

  const renderUserPaper = (user: User) => (
    <Paper
      elevation={1}
      key={user.id}
      className={clsx(
        pageClasses.userContainer,
        pageClasses.userFilter,
        pageClasses.smallBottomMargin,
        {
          [pageClasses.parentWidth]: isMobile,
        }
      )}
    >
      <FormControlLabel
        value={user.email}
        className={pageClasses.userControlLabel}
        control={<Radio />}
        label={renderUserContent(user)}
      />
    </Paper>
  );

  return (
    <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        {headerTitle}
      </Typography>

      <TextField
        variant="outlined"
        type="text"
        id="user-name"
        label="Search by User Name"
        value={filter}
        onChange={handleChangeFilter}
        className={pageClasses.userFilter}
        autoComplete="off"
        inputProps={{
          maxLength: 200,
        }}
        required
        fullWidth
      />
      {loading ? (
        <Grid container className={pageClasses.rootContainer}>
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        !(selectedUserEmail === filter) && (
          <Grid item xs={12} md={12}>
            <RadioGroup
              ref={radioGroupRef}
              aria-label="user-id"
              name="user-id"
              value={selectedUserEmail}
              onChange={handleChangeSelectedUser}
              className={pageClasses.columnContainer}
            >
              {users.map((user: User) => renderUserPaper(user))}
            </RadioGroup>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default UserSelector;
