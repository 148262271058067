import gql from "graphql-tag";

const fragments = {
  authentication: gql`
    fragment authentication on AuthenticationDto {
      user {
        shopifyId
        id
        firstName
        lastName
        acceptsMarketing
        email
        phone
        role
        avatar
        status
      }
      accessToken {
        accessToken
        expiresAt
      }
      error
      messages
    }
  `,
};

export const me = gql`
  query me {
    me {
      ...authentication
    }
  }
  ${fragments.authentication}
`;

export const login = gql`
  query login($user: AuthenticationInput!) {
    login(user: $user) {
      ...authentication
    }
  }
  ${fragments.authentication}
`;

export const logout = gql`
  query logout {
    logout
  }
`;

export const recoverPassword = gql`
  query recoverPasswordEmail($user: AuthenticationInput!) {
    recoverPasswordEmail(user: $user)
  }
`;

// export const resetPassword = gql`
//   query resetPassword($user: AuthenticationInput!) {
//     resetPassword(user: $user)
//   }
// `;

const auth = {
  queries: {
    login,
    logout,
    me,
    recoverPassword,
    // resetPassword,
  },
};

export default auth;
