import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import EventIcon from "@material-ui/icons/Event";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import SalesIcon from "@material-ui/icons/MonetizationOn";
import AnalyticsIcon from "@material-ui/icons/BarChart";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import VideoCamIcon from "@material-ui/icons/Videocam";
import MovieFilterIcon from '@material-ui/icons/MovieFilter';

// import AddCircleIcon from "@material-ui/icons/AddCircle";
import { HowToReg, SvgIconComponent } from "@material-ui/icons";
import clsx from "clsx";

import useStyles from "./styles";

export interface LinkType {
  link: string;
  text: string;
  icon: React.ReactElement;
  sublinks?: LinkType[];
  highlightLinks?: string[];
  roles?: string[];
}

interface Props {
  outerIcon: SvgIconComponent;
  innerIcon: SvgIconComponent;
  primaryInner?: boolean;
}

export const DoubleIcons: React.FC<Props> = ({
  outerIcon: OuterIcon,
  innerIcon: InnerIcon,
  primaryInner = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.multiIconsContainer}>
      <OuterIcon />
      <InnerIcon
        className={clsx(classes.innerIcon, {
          [classes.innerIconPrimary]: primaryInner,
        })}
      />
    </div>
  );
};

// APP (App.tsx) must match roles here
const links: LinkType[] = [
  {
    link: "dashboard",
    text: "Dashboard",
    icon: <HomeIcon />,
    // sublinks: [
    //   {
    //     link: "new-sales-event",
    //     text: "Create Sales Event",
    //     icon: <AddCircleIcon />,
    //   },
    // ],
  },
  {
    link: "prospects",
    text: "Prospects",
    icon: <HowToReg />,
    highlightLinks: ["prospects"],
    roles: ["ADMIN"],
  },
  {
    link: "users",
    text: "Users",
    icon: <PeopleIcon />,
    highlightLinks: ["users"],
    roles: ["ADMIN"],
  },
  {
    link: "sales-events",
    text: "Sales Events",
    icon: <EventIcon />,
    highlightLinks: ["sales-events"],
    roles: ["ADMIN"],
  },
  // {
  //   link: "sales-reports",
  //   text: "Sales Reports",
  //   icon: <SalesIcon />,
  //   highlightLinks: ["sales-reports"],
  //   roles: ["ADMIN", "MERCHANT"],
  // },
  // {
  //   link: "analytics",
  //   text: "Analytics",
  //   icon: <AnalyticsIcon />,
  //   highlightLinks: ["analytics"],
  //   roles: ["ADMIN", "MERCHANT"],
  // },
  // {
  //   link: "replay-archive",
  //   text: "Replay Archive",
  //   icon: <VideoCamIcon />,
  //   highlightLinks: ["replay-archive"],
  //   roles: ["ADMIN"],
  // },
  {
    link: "shop-interests",
    text: "Shop Interests",
    icon: <CollectionsBookmarkIcon />,
    highlightLinks: ["shop-interests"],
    roles: ["ADMIN"],
  },
  {
    link: "trending-showcases",
    text: "Trending Showcases",
    icon: <MovieFilterIcon />,
    highlightLinks: ["trending-showcases"],
    roles: ["ADMIN"],
  },
  {
    link: "settings",
    text: "Settings ",
    icon: <SettingsIcon />,
    highlightLinks: ["settings"],
    roles: ["ADMIN"],
  },
];

export default links;
