import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import { setShowcases } from "@redux/reducers/showcases.reducer";
import ShowcasesGraphQL from "@graphql/showcases.resolver";
import CollectionsGraphQL from "@graphql/collections.resolver";
import InterestsGraphQL from "@graphql/interests.resolver";
import SharedTable, { TableAction, TableColumn } from "@components/Table";
import { showcasesActions, showcasesColumns } from "./columns";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import initialOptions from "./initialFilters";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AlertType } from "@models/common";
import { setAlert } from "@redux/reducers/alert.reducer";
import { setCollections } from "@redux/reducers/collections.reducer";
import { RootState } from "@redux/store";
import { SalesEvent } from "@models/salesEvent.model";
import ConfirmDialog from "@components/ConfirmDialog";
import { setInterests } from "@redux/reducers/interests.reducer";

const ShowcaseListPage: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  const params = useParams<{ id: string }>();
  const [options] = useState<FiltersOptionsGroup[]>(initialOptions);
  const [columns, setColumns] = useState<TableColumn[]>(showcasesColumns);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<string>("");

  const salesEvents: SalesEvent[] = useAppSelector(
    (state: RootState) => state.salesEvents.salesEvents
  );
  const salesEvent = salesEvents.find(
    (salesEvent: SalesEvent) => salesEvent.id === params.id
  );

  if (!params.id) {
    history.push("/dashboard");
  }

  const createShowcaseForm = () => {
    history.push(`/sales-event/${params.id}/showcases/new`);
  };

  const editSalesEventForm = () => {
    history.push(`/edit-sales-event/${params.id}`);
  };

  // Initialize interests
  const [
    getInterests,
    { loading: loadingInterests, error: errorInterests, data: interestsData },
  ] = useLazyQuery(InterestsGraphQL.queries.getInterests, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorInterests) {
      dispatchAlert("Error getting interests data.", "error");
    }
  }, [errorInterests]);

  useEffect(() => {
    if (interestsData) {
      if (!!interestsData.interests?.interests) {
        dispatch(setInterests(interestsData.interests.interests));
      }

      if (interestsData?.interests?.error) {
        dispatchAlert(interestsData?.messages[0], "error");
      }
    }
  }, [interestsData]);

  // Initialize collections
  const [
    getCollections,
    {
      loading: loadingCollections,
      error: errorCollections,
      data: collectionsData,
    },
  ] = useLazyQuery(CollectionsGraphQL.queries.getCollections, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorCollections) {
      dispatchAlert("Error getting collections data.", "error");
    }
  }, [errorCollections]);

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  const deleteShowcaseRequest = (showcaseId: string) => {
    setOpenConfirmDialog(showcaseId);
  };

  const onCloseConfirmDialog = (confirm: boolean) => {
    const showcaseDeleteId = openConfirmDialog;
    setOpenConfirmDialog("");
    if (confirm) {
      deleteShowcase({
        variables: {
          input: {
            id: showcaseDeleteId,
          },
        },
      });
    }
  };

  const [
    deleteShowcase,
    {
      loading: deletingShowcase,
      error: deleteShowcaseError,
      data: deleteShowcaseData,
    },
  ] = useMutation(ShowcasesGraphQL.mutations.deleteShowcase, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (deleteShowcaseError) {
      dispatchAlert("Error deleting showcase", "error");
    }
  }, [deleteShowcaseError]);

  useEffect(() => {
    if (deleteShowcaseData) {
      if (!deleteShowcaseData.deleteShowcase?.error) {
        dispatchAlert("Showcase has been succesfully deleted.", "success");
        getCollections();
      }
      if (deleteShowcaseData.deleteShowcase?.error) {
        dispatchAlert(deleteShowcaseData.deleteShowcase?.messages[0], "error");
      }
    }
  }, [deleteShowcaseData]);

  // Initialize component
  useEffect(() => {
    getCollections();
    getInterests();
    const newColumns: TableColumn[] = showcasesColumns;
    const newActions: TableAction[] = [];
    showcasesActions.forEach((action: TableAction) => newActions.push(action));
    newActions.push({
      actionId: "id",
      actionType: "function",
      label: "Delete Showcase",
      link: "",
      pageAction: deleteShowcaseRequest,
    });
    newColumns[newColumns.length - 1].actions = newActions;
    setColumns(newColumns);
  }, []);

  return (
    <>
      {loadingCollections || deletingShowcase || loadingInterests ? (
        <CircularProgress size={25} />
      ) : (
        <SharedTable
          tableDisplayName={salesEvent?.title ?? "Showcases"}
          tableName="showcases"
          dataPath="showcases"
          columns={columns}
          filtersOptions={options}
          searchBarLabel="Search by name"
          searchBarId="name"
          query={ShowcasesGraphQL.queries.getShowcases}
          tableDispatch={setShowcases}
          displayCreateNew={createShowcaseForm}
          displayEdit={editSalesEventForm}
          filterProps={{
            eventId: params.id,
          }}
        />
      )}
      <ConfirmDialog
        id="confirm-delete-showcase-dialog"
        open={!!openConfirmDialog}
        text={`Are you sure you want to delete this showcase? You will not be able to undo this.`}
        onClose={onCloseConfirmDialog}
      />
    </>
  );
};

export default ShowcaseListPage;
