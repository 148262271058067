// import { isMobile } from "react-device-detect";

import { TableColumn } from "@components/Table";
import CollectionParser from "./collectionParser";

export const usersColumns: TableColumn[] = [
  {
    id: "email",
    label: "Email",
    type: "text",
    // stickyColumn: !isMobile,
  },
  {
    id: "role",
    label: "Role",
    type: "text",
    // stickyColumn: !isMobile,
  },
  {
    id: "status",
    label: "Status",
    type: "text",
  },
  {
    id: "defaultCollection",
    label: "Default Collection",
    type: "custom",
    parser: (item) => {
      return <CollectionParser collection={item.defaultCollection} />;
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "actions",
    actions: [
      {
        actionId: "id",
        actionType: "link",
        label: "Edit user",
        link: "edit-user/<actionId>",
      },
    ],
    // stickyColumn: !isMobile,
  },
  // {
  //   id: "avatar",
  //   label: "Avatar",
  //   type: "text",
  //   stickyColumn: !isMobile,
  // },
  // {
  //   id: "status",
  //   label: "Status",
  //   type: "text",
  //   stickyColumn: !isMobile,
  // },

  // {
  //   id: 'custom',
  //   label: 'Custom',
  //   type: 'custom',
  //   parser: (item) => {
  //     const newType = item.defaultCollection.replace(/_/g, ' ');
  //     return capitalizeFirstLetter(newType);
  //   },
  //   sortId: 'type',
  // },
];
