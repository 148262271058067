import gql from "graphql-tag";

const fragments = {
  userMainData: gql`
    fragment userMainData on UserDto {
      id
      role
      email
      avatar
      defaultCollection
      status
      # createDate
    }
  `,
  userUpdate: gql`
    fragment userUpdate on UserDto {
      id
      error
      messages
    }
  `,
};

export const getUsers = gql`
  query getUsers(
    $email: String
    $role: String
    $sortBy: String
    $sortDirection: String
    $page: Float
    $rowsPerPage: Float
  ) {
    users(
      input: {
        email: $email
        role: $role
        sort_by: $sortBy
        sort_direction: $sortDirection
        page: $page
        rowsPerPage: $rowsPerPage
      }
    ) {
      pagination {
        page
        rowsPerPage
        total
      }
      users {
        ...userMainData
      }
      error
      messages
    }
  }
  ${fragments.userMainData}
`;

export const getUser = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      ...userMainData
      error
      messages
    }
  }
  ${fragments.userMainData}
`;

export const updateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...userUpdate
    }
  }
  ${fragments.userUpdate}
`;

const usersResolver = {
  queries: {
    getUsers,
    // getUser,
  },
  mutations: {
    updateUser,
  },
};

export default usersResolver;
