import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";

import { setSalesEvents } from "@redux/reducers/salesEvents.reducer";
import SalesEventsGraphQL from "@graphql/salesEvents.resolver";
import CollectionsGraphQL from "@graphql/collections.resolver";
import InterestsGraphQL from "@graphql/interests.resolver";
import SharedTable, { TableAction, TableColumn } from "@components/Table";
import { salesEventsActions, salesEventsColumns } from "./columns";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import initialOptions from "./initialFilters";
import ConfirmDialog from "@components/ConfirmDialog";
import { AlertType } from "@models/common";
import { useAppDispatch } from "@redux/hooks";
import { setAlert } from "@redux/reducers/alert.reducer";
import { setCollections } from "@redux/reducers/collections.reducer";
import { setInterests } from "@redux/reducers/interests.reducer";

const SalesEventsPage: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };
  const [options] = useState<FiltersOptionsGroup[]>(initialOptions);
  const [columns, setColumns] = useState<TableColumn[]>(salesEventsColumns);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<string>("");

  // Initialize collections
  const [
    getCollections,
    {
      loading: loadingCollections,
      error: errorCollections,
      data: collectionsData,
    },
  ] = useLazyQuery(CollectionsGraphQL.queries.getCollections, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorCollections) {
      dispatchAlert("Error getting collections data.", "error");
    }
  }, [errorCollections]);

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  // Initialize interests
  const [
    getInterests,
    { loading: loadingInterests, error: errorInterests, data: interestsData },
  ] = useLazyQuery(InterestsGraphQL.queries.getInterests, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorInterests) {
      dispatchAlert("Error getting interests data.", "error");
    }
  }, [errorInterests]);

  useEffect(() => {
    if (interestsData) {
      if (!!interestsData.interests?.interests) {
        dispatch(setInterests(interestsData.interests.interests));
      }

      if (interestsData?.interests?.error) {
        dispatchAlert(interestsData?.messages[0], "error");
      }
    }
  }, [interestsData]);

  // Initialize component
  useEffect(() => {
    getCollections();
    getInterests();
    const newColumns: TableColumn[] = salesEventsColumns;
    const newActions: TableAction[] = [];
    salesEventsActions.forEach((action: TableAction) =>
      newActions.push(action)
    );
    newActions.push({
      actionId: "id",
      actionType: "function",
      label: "Delete Event",
      link: "",
      pageAction: deleteSalesEventRequest,
    });
    newColumns[newColumns.length - 1].actions = newActions;
    setColumns(newColumns);
  }, []);

  const deleteSalesEventRequest = (salesEventId: string) => {
    setOpenConfirmDialog(salesEventId);
  };

  const onCloseConfirmDialog = (confirm: boolean) => {
    const salesEventDeleteId = openConfirmDialog;
    setOpenConfirmDialog("");
    if (confirm) {
      deleteSalesEvent({
        variables: {
          input: {
            id: salesEventDeleteId,
          },
        },
      });
    }
  };

  const [
    deleteSalesEvent,
    {
      loading: deletingSalesEvent,
      error: deleteSalesEventError,
      data: deleteSalesEventData,
    },
  ] = useMutation(SalesEventsGraphQL.mutations.deleteSalesEvent, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (deleteSalesEventError) {
      dispatchAlert("Error deleting sales event", "error");
    }
  }, [deleteSalesEventError]);

  useEffect(() => {
    if (deleteSalesEventData) {
      if (!deleteSalesEventData.deleteSalesEvent?.error) {
        dispatchAlert("Sales Event has been succesfully deleted.", "success");
        getCollections();
      }
      if (deleteSalesEventData.deleteSalesEvent?.error) {
        dispatchAlert(
          deleteSalesEventData.deleteSalesEvent?.messages[0],
          "error"
        );
      }
    }
  }, [deleteSalesEventData]);

  const createSalesEventForm = () => {
    history.push(`/new-sales-event`);
  };

  return (
    <>
      {loadingCollections || loadingInterests || deletingSalesEvent ? (
        <CircularProgress size={25} />
      ) : (
        <SharedTable
          tableDisplayName="Sales Events"
          tableName="salesEvents"
          dataPath="salesEvents"
          columns={columns}
          filtersOptions={options}
          searchBarLabel="Search by name"
          searchBarId="name"
          query={SalesEventsGraphQL.queries.getSalesEvents}
          tableDispatch={setSalesEvents}
          displayCreateNew={createSalesEventForm}
        />
      )}
      <ConfirmDialog
        id="confirm-delete-sales-event-dialog"
        open={!!openConfirmDialog}
        text={
          "Are you sure you want to delete this event? This will also delete all showcases associated to the event.You will not be able to undo this."
        }
        onClose={onCloseConfirmDialog}
      />
    </>
  );
};

export default SalesEventsPage;
