import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import UsersGraphQL from "@graphql/users.resolver";
import RolesGraphQL from "@graphql/roles.resolver";
import UserStatusGraphQL from "@graphql/userStatus.resolver";
import { setUsers } from "@redux/reducers/users.reducer";
import SharedTable from "@components/Table";
import CollectionsGraphQL from "@graphql/collections.resolver";
import { usersColumns } from "./columns";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import initialOptions from "./initialFilters";
import { useAppDispatch } from "@redux/hooks";
import { setCollections } from "@redux/reducers/collections.reducer";
import { setAlert } from "@redux/reducers/alert.reducer";
import { AlertType } from "@models/common";
import { Grid, CircularProgress } from "@material-ui/core";
import { setRoles } from "@redux/reducers/roles.reducer";
import { setUserStatus } from "@redux/reducers/userStatus.reducer";

const UsersPage: React.FC<Record<string, unknown>> = () => {
  const [options] = useState<FiltersOptionsGroup[]>(initialOptions);
  const dispatch = useAppDispatch();

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  // Initialize collections
  const [
    getCollections,
    {
      loading: loadingCollections,
      error: errorCollections,
      data: collectionsData,
    },
  ] = useLazyQuery(CollectionsGraphQL.queries.getCollections, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorCollections) {
      dispatchAlert("Error getting collections data.", "error");
    }
  }, [errorCollections]);

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  // Initialize Roles
  const [
    getUserStatus,
    {
      loading: loadingUserStatus,
      error: errorUserStatus,
      data: userStatusData,
    },
  ] = useLazyQuery(UserStatusGraphQL.queries.getUserStatus, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorRoles) {
      dispatchAlert("Error getting roles data.", "error");
    }
  }, [errorUserStatus]);

  useEffect(() => {
    if (userStatusData) {
      if (!userStatusData.userStatus?.error) {
        dispatch(setUserStatus(userStatusData.userStatus));
      }

      if (userStatusData?.error) {
        dispatchAlert(userStatusData?.messages[0], "error");
      }
    }
  }, [userStatusData]);

  // Initialize User Status
  const [
    getRoles,
    { loading: loadingRoles, error: errorRoles, data: rolesData },
  ] = useLazyQuery(RolesGraphQL.queries.getRoles, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorRoles) {
      dispatchAlert("Error getting roles data.", "error");
    }
  }, [errorRoles]);

  useEffect(() => {
    if (rolesData) {
      if (!rolesData.roles?.error) {
        dispatch(setRoles(rolesData.roles));
      }

      if (rolesData?.error) {
        dispatchAlert(rolesData?.messages[0], "error");
      }
    }
  }, [rolesData]);

  // Initialize component
  useEffect(() => {
    getCollections();
    getRoles();
    getUserStatus();
  }, []);

  return loadingCollections || loadingRoles || loadingUserStatus ? (
    <Grid item xs={12} md={6}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <SharedTable
      tableDisplayName="Users"
      tableName="users"
      dataPath="users"
      columns={usersColumns}
      filtersOptions={options}
      searchBarLabel="Search by email"
      searchBarId="email"
      query={UsersGraphQL.queries.getUsers}
      tableDispatch={setUsers}
    />
  );
};

export default UsersPage;
