import { NestedObject } from "@models/common";
import { User } from "@models/user.model";

const UsersTypes = {
  SET_USERS: "SET_USERS",
  SET_ACTUAL_USER: "SET_ACTUAL_USER",
};

export const setUsers = (data: UsersData) => ({
  type: UsersTypes.SET_USERS,
  data: data,
});

export const setActualUser = (user: User) => ({
  type: UsersTypes.SET_ACTUAL_USER,
  data: user,
});

export type UsersState = {
  users: User[];
  total: number;
  selectedUser: User;
};

// INITIAL STATE
export const usersInitialState: UsersState = {
  users: [],
  total: 0,
  selectedUser: {},
};

export type UsersData = {
  items: User[];
  total: number;
};

export type UsersAction = {
  type: string;
  data: UsersData | User;
};

const usersReducer = (state = usersInitialState, action: UsersAction) => {
  switch (action.type) {
    case UsersTypes.SET_USERS:
      const usersData = action.data as UsersData;
      return {
        ...state,
        users: usersData.items,
        total: usersData.total,
      };

    case UsersTypes.SET_ACTUAL_USER:
      const user = action.data as NestedObject;
      return {
        ...state,
        selectedUser: user,
      };

    default:
      return state;
  }
};

export default usersReducer;
