import gql from "graphql-tag";

export const getRoles = gql`
  query roles {
    roles {
      handle
      title
      hasCollection
      available
    }
  }
`;

const rolesResolver = {
  queries: {
    getRoles,
    // getUser,
  },
  //   mutations: {
  //     updateUser,
  //   },
};

export default rolesResolver;
