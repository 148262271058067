import MiniVideoPreview from "@components/MiniVideoPreview";
import useStyles from "./styles";
import {
  CircularProgress,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import CollectionParser from "@pages/users/collectionParser";
import { Video } from "@models/video.model";
import { Showcase } from "@models/showcase.model";
import { SalesEvent } from "@models/salesEvent.model";

export interface TrendingSelectorProps {
  filterEvent: string;
  handleChangeEventFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingEvents: boolean;
  loadingShowcases: boolean;
  loadingShowcaseDetail: boolean;
  searching: boolean;
  selectedEvent: string;
  selectedShowcase: string;
  events: SalesEvent[];
  showcases: Showcase[];
  selectedShowcaseVideos: Video[];
  selectEvent: (id: string) => void;
  selectShowcase: (id: string) => void;
  addTrending: (id: string) => void;
  handlePrevClick: () => void;
  handleNextClick: () => void;
  moreEvents: boolean;
  lessEvents: boolean;
  error: boolean;
}

const TrendingSelector: React.FC<TrendingSelectorProps> = ({
  filterEvent,
  handleChangeEventFilter,
  loadingEvents,
  searching,
  events,
  selectEvent,
  selectedEvent,
  loadingShowcases,
  showcases,
  selectShowcase,
  selectedShowcase,
  loadingShowcaseDetail,
  selectedShowcaseVideos,
  addTrending,
  handlePrevClick,
  handleNextClick,
  moreEvents,
  lessEvents,
  error,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* Filters */}
      <Grid className={classes.rowContainer}>
        <TextField
          variant="outlined"
          type="text"
          id="event-name"
          label="Search by Event Name"
          value={filterEvent}
          disabled={loadingEvents}
          onChange={handleChangeEventFilter}
          autoComplete="off"
          inputProps={{
            maxLength: 200,
          }}
          fullWidth
        />
      </Grid>

      {/* Selector */}

      {error ? (
        <Typography variant="h6" align="center" style={{ width: "100%" }}>
          Error loading data for selector, please retry.
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid container item spacing={2} alignItems="flex-start">
            <Grid xs={3}>
              <Typography variant="h6" align="center">
                Events
              </Typography>
            </Grid>

            <Grid xs={3}>
              <Typography variant="h6" align="center">
                Showcases
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography variant="h6" align="center">
                Videos
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} alignItems="flex-start">
            <Grid
              item
              xs={3}
              style={{
                textAlign: "center",
                maxHeight: "300px",
                height: "300px",
                overflowY: "auto",
              }}
            >
              {!loadingEvents && events.length === 0 && (
                <div className={classes.listItem}>
                  No matches for search term
                </div>
              )}
              {(loadingEvents || searching) && (
                <Grid container justify="center">
                  <CircularProgress size={25} thickness={2} />
                </Grid>
              )}
              {!loadingEvents &&
                !searching &&
                events.map((item: SalesEvent) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => selectEvent(item?.id || "")}
                      className={
                        item.id === selectedEvent
                          ? classes.selectedListItem
                          : classes.listItem
                      }
                    >
                      {item.title}
                    </div>
                  );
                })}
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                textAlign: "center",
                overflowY: "auto",
                maxHeight: "300px",
                height: "300px",
              }}
            >
              {!selectedEvent && (
                <div className={classes.listItem}>
                  Select an event to see related showcases
                </div>
              )}
              {selectedEvent &&
                !loadingShowcases &&
                showcases?.length === 0 && (
                  <div className={classes.listItem}>
                    The selected event does not have any showcases
                  </div>
                )}
              {selectedEvent && loadingShowcases && (
                <Grid container justify="center">
                  <CircularProgress size={25} thickness={2} />
                </Grid>
              )}

              {!loadingShowcases &&
                showcases &&
                showcases.map((item: Showcase) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => selectShowcase(item?.id || "")}
                      className={
                        item.id === selectedShowcase
                          ? classes.selectedListItem
                          : classes.listItem
                      }
                    >
                      <CollectionParser collection={item.defaultCollection} />
                    </div>
                  );
                })}
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "300px",
                height: "300px",
                overflowY: "auto",
              }}
            >
              {!selectedShowcase && (
                <div className={classes.listItem}>
                  Select a showcase to see related videos
                </div>
              )}
              {selectedShowcase && loadingShowcaseDetail && (
                <Grid container justify="center">
                  <CircularProgress size={25} thickness={2} />
                </Grid>
              )}
              {selectedShowcase &&
                !loadingShowcaseDetail &&
                selectedShowcaseVideos?.length === 0 && (
                  <div className={classes.listItem}>
                    The selected showcase does not have any videos
                  </div>
                )}
              {!loadingShowcases && selectedShowcase && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    overflow: "auto",
                  }}
                >
                  <MiniVideoPreview
                    videos={selectedShowcaseVideos}
                    handleClick={addTrending}
                    reorderButtons={false}
                    showData={true}
                    action="add"
                  />
                </Grid>
              )}
            </Grid>

            {/* Pagination buttons */}
            <Grid container xs={3} justify="center" style={{ gap: "10px" }}>
              {
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handlePrevClick}
                    disabled={!lessEvents || loadingEvents}
                  >
                    Prev
                  </Button>
                </Grid>
              }
              {
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleNextClick}
                    disabled={!moreEvents || loadingEvents}
                  >
                    Next
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TrendingSelector;
