import gql from "graphql-tag";

export const toggleEnabled = gql`
  mutation setVideoPublished($input: VideoPublishedInput!) {
    setVideoPublished(input: $input) {
      id
      published
      error
    }
  }
`;

export const updateTrendingVideos = gql`
  mutation setTrendingVideos($videos: [TrendingVideoInput!]!, $videosToRemove: [TrendingVideoInput!]!) {
    setTrendingVideos(videos: $videos, videosToRemove: $videosToRemove) {
      error
      messages
    }
  }
`;

export const allTrendingVideos = gql`
  query allTrendingVideos {
    allTrendingVideos {
      videos {
        id
        published
        url
        trendingOrder
      }

      error
      messages
    }
  }
`;

const videosResolver = {
  mutations: {
    toggleEnabled,
    updateTrendingVideos,
  },
  queries: {
    allTrendingVideos,
  }
};

export default videosResolver;
