import monthNumberToName from "./monthNumberToName";

const getDashboardMonthLabels = (amountOfLabels: number = 6) => {
  const now = new Date(Date.now());

  const currentMonth = now.getMonth() + 1;

  const labels: string[] = [];

  for (let i = 0; i < amountOfLabels; i++) {
    labels.unshift(monthNumberToName(currentMonth - i));
  }

  return labels;
};

export default getDashboardMonthLabels;
