import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Grid, Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import useStyles from "./styles";
import SalesEventCreateForm from "./salesEventCreate";
import SalesEventEditForm from "./salesEventEdit";
import { AlertType } from "@models/common";
import { useAppDispatch } from "@redux/hooks";
import { setAlert } from "@redux/reducers/alert.reducer";

const SalesEventForm: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  const goBackToSalesEvents = () => {
    history.push("/sales-events");
  };

  const goToSalesEventsDetails = (id?: string) => {
    if (id) {
      history.push(`/sales-event/${id}/showcases`);
    }
  };

  const backButton = (
    <Button
      className={classes.backButton}
      color="primary"
      variant="text"
      type="button"
      startIcon={<ChevronLeftIcon />}
      onClick={goBackToSalesEvents}
    >
      <Typography variant="body1" color="primary">
        Back to Sales Events list
      </Typography>
    </Button>
  );

  return (
    <Grid container>
      {params.id ? (
        <SalesEventEditForm
          id={params.id}
          backButton={backButton}
          // goBackToSalesEvents={goBackToSalesEvents}
          dispatchAlert={dispatchAlert}
        />
      ) : (
        <SalesEventCreateForm
          backButton={backButton}
          navigateOnSave={goToSalesEventsDetails}
          dispatchAlert={dispatchAlert}
        />
      )}
    </Grid>
  );
};

export default SalesEventForm;
