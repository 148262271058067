/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import CollectionsGraphQL from "@graphql/collections.resolver";
import ShowcasesGraphQL from "@graphql/showcases.resolver";
import SalesEventsGraphQL from "@graphql/salesEvents.resolver";
import VideosGraphQL from "@graphql/videos.resolver";
import { setAlert } from "@redux/reducers/alert.reducer";

import _debounce from "lodash/debounce";

import useStyles from "./styles";
import MiniVideoPreview from "@components/MiniVideoPreview";
import { AlertType } from "@models/common";
import { useAppDispatch } from "@redux/hooks";
import { setCollections } from "@redux/reducers/collections.reducer";
import TrendingSelector from "@components/TrendingSelector";
import { Video } from "@models/video.model";
import { SalesEvent } from "@models/salesEvent.model";
import { Showcase } from "@models/showcase.model";

const TrendingShowcasesPage = () => {
  const classes = useStyles();
  const [searching, setSearching] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [trendingVideos, setTrendingVideos] = useState<Video[]>([]);
  const [removedVideos, setRemovedVideos] = useState<Video[]>([]);
  const [events, setEvents] = useState<SalesEvent[]>([]);
  const [showcases, setShowcases] = useState<Showcase[]>([]);
  const [selectedShowcaseVideos, setSelectedShowcaseVideos] = useState([]);
  const [filterEvent, setFilterEvent] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedShowcase, setSelectedShowcase] = useState("");
  const [currentEventsPage, setCurrentEventsPage] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  const eventsPerPage = 8;

  const dispatch = useAppDispatch();
  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  const [getCollections, { error: errorCollections, data: collectionsData }] =
    useLazyQuery(CollectionsGraphQL.queries.getCollections, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  const [
    allTrendingVideos,
    { loading: loadingTrending, error: errorTrending, data: dataTrending },
  ] = useLazyQuery(VideosGraphQL.queries.allTrendingVideos, {
    fetchPolicy: "network-only",
  });

  const [
    getSalesEvents,
    { loading: loadingEvents, error: errorEvents, data: dataEvents },
  ] = useLazyQuery(SalesEventsGraphQL.queries.getSalesEvents, {
    fetchPolicy: "network-only",
  });

  const [
    getShowcases,
    { loading: loadingShowcases, error: errorShowcases, data: dataShowcases },
  ] = useLazyQuery(ShowcasesGraphQL.queries.getShowcases, {
    fetchPolicy: "network-only",
  });

  const [
    getShowcaseDetail,
    {
      loading: loadingShowcaseDetail,
      error: errorShowcaseDetail,
      data: dataShowcaseDetail,
    },
  ] = useLazyQuery(ShowcasesGraphQL.queries.getShowcase, {
    fetchPolicy: "network-only",
  });

  const [
    updateTrendingVideos,
    {
      loading: loadingupdateTrendingVideos,
      error: errorupdateTrendingVideos,
      data: dataupdateTrendingVideos,
    },
  ] = useMutation(VideosGraphQL.mutations.updateTrendingVideos, {
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (
      trendingVideos?.length === 0 &&
      dataTrending?.allTrendingVideos?.videos
    ) {
      setTrendingVideos([...dataTrending.allTrendingVideos.videos]);
    }
  }, [dataTrending]);

  useEffect(() => {
    if (dataEvents?.salesEvents?.salesEvents) {
      setEvents(dataEvents.salesEvents.salesEvents);
      setTotalEvents(dataEvents?.salesEvents?.pagination?.total)
    }
  }, [dataEvents]);

  useEffect(() => {
    if (dataShowcases?.showcases?.showcases) {
      setShowcases(dataShowcases.showcases.showcases);
    }
  }, [dataShowcases]);

  useEffect(() => {
    if (dataShowcaseDetail?.getShowcase?.videos) {
      setSelectedShowcaseVideos(dataShowcaseDetail.getShowcase.videos);
    }
  }, [dataShowcaseDetail]);

  useEffect(() => {
    if (
      dataupdateTrendingVideos?.setTrendingVideos?.messages &&
      dataupdateTrendingVideos?.setTrendingVideos?.messages[0] === "OK"
    ) {
      setRemovedVideos([]);
      dispatchAlert("Trending list updated.", "success");
    } else if (
      dataupdateTrendingVideos?.setTrendingVideos?.errors ||
      errorupdateTrendingVideos
    ) {
      setEnableSave(true);
      dispatchAlert("Unable to update list.", "error");
    }
  }, [dataupdateTrendingVideos, errorupdateTrendingVideos]);

  // Initialize component
  useEffect(() => {
    getSalesEvents({ variables: { page: 0, rowsPerPage: eventsPerPage } });
    allTrendingVideos();
    // get collections so they are available for collection parser
    getCollections();
  }, []);

  useEffect(() => {
    if (selectedShowcase) {
      getShowcaseDetail({ variables: { id: selectedShowcase } });
    }
  }, [selectedShowcase]);

  useEffect(() => {
    getSalesEvents({ variables: { title: filterEvent, page: currentEventsPage, rowsPerPage: eventsPerPage } });
  }, [currentEventsPage]);

  const handlePrevClick = () => {
    if (currentEventsPage === 0) return;
    setCurrentEventsPage(currentEventsPage => currentEventsPage - 1);
  }
  
  const handleNextClick = () => {
    setCurrentEventsPage(currentEventsPage => currentEventsPage + 1);
  }

  const getFilteredSalesEvents = (value: string) => {
    getSalesEvents({ variables: { title: value, page: currentEventsPage, rowsPerPage: eventsPerPage } });
    setSearching(false);
  };

  // clean debounce
  useEffect(() => {
    return () => {
      debouncedGetEvents.current.cancel();
    };
  }, []);

  const debouncedGetEvents = useRef(_debounce(getFilteredSalesEvents, 1500));

  const handleChangeEventFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (selectedEvent) {
      setSelectedEvent("");
      resetShowcase();
    }
    setCurrentEventsPage(0);
    setSearching(true);
    setFilterEvent(event.target.value);
    debouncedGetEvents.current(event.target.value);
  };

  const handleSave = () => {
    updateTrendingVideos({
      variables: {
        videosToRemove: removedVideos.map((item: Video) => ({
          id: item.id,
          trendingOrder: null,
        })),
        videos: trendingVideos.map((item: Video) => ({
          id: item.id,
          trendingOrder: item.trendingOrder,
          published: true,
        })),
      },
    });
    setEnableSave(false);
  };

  const removeTrending = (id: string) => {
    setEnableSave(true);
    if (id) {
      const oldVideo = trendingVideos.find((item: Video) => item.id === id);
      if (oldVideo && !removedVideos.some((item: Video) => item.id === id)) {
        setRemovedVideos([...removedVideos, oldVideo]);
      }
      const updatedVideosAux = [...trendingVideos]
        .filter((item: Video) => item.id !== id)
        .map((video: Video, index: number) => ({
          ...video,
          trendingOrder: index + 1,
        }));
      setTrendingVideos([...updatedVideosAux]);
    }
  };

  const addTrending = (id: string) => {
    if (trendingVideos.length >= 20) {
      dispatchAlert(
        "Trending list already has twenty videos, please remove one first.",
        "error"
      );
      return;
    }
    if (trendingVideos.some((item: Video) => item.id === id)) {
      dispatchAlert("This video is already in the trending list.", "error");
      return;
    }
    setEnableSave(true);
    const newVideo = selectedShowcaseVideos
      .filter((item) => item?.["id"] === id)
      .map((video: Video) => ({
        ...video,
        trendingOrder: trendingVideos.length + 1,
      }));
    if (newVideo) {
      setTrendingVideos([...trendingVideos, newVideo[0]]);
    }
  };

  const reorder = (idx: number, direction: string) => {
    setEnableSave(true);
    const updatedVideos = [...trendingVideos];
    if (direction === "forward") {
      const temp = updatedVideos[idx];
      updatedVideos[idx] = updatedVideos[idx - 1];
      updatedVideos[idx - 1] = temp;
    }
    if (direction === "back") {
      const temp = updatedVideos[idx];
      updatedVideos[idx] = updatedVideos[idx + 1];
      updatedVideos[idx + 1] = temp;
    }
    const updatedVideosAux = updatedVideos.map(
      (video: Video, index: number) => ({
        ...video,
        trendingOrder: index + 1,
      })
    );
    setTrendingVideos(updatedVideosAux);
  };

  const selectEvent = (eventId: string) => {
    if (eventId) {
      setSelectedEvent(eventId);
      resetShowcase();
      getShowcases({ variables: { eventId: eventId, page: 0, rowsPerPage: 100 } });
    }
  };

  const selectShowcase = (showId: string) => {
    if (showId) {
      setSelectedShowcaseVideos([]);
      setSelectedShowcase(showId);
    }
  };

  const resetShowcase = () => {
    if (selectedShowcase) {
      setSelectedShowcase("");
      setSelectedShowcaseVideos([]);
    }
  };

  return loadingTrending ? (
    <Grid item xs={12} className={classes.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ gap: "10px" }}
        >
          <Grid container xs={11} md={11}>
            <Typography variant="h1" color="textPrimary" gutterBottom={true}>
              Trending showcases
            </Typography>
          </Grid>
          {true && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="button"
                onClick={handleSave}
                disabled={!enableSave || loadingupdateTrendingVideos}
              >
                {!loadingupdateTrendingVideos ? (
                  "Save"
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </div>
          )}
        </Grid>

        <Typography variant="h3" color="textPrimary" gutterBottom={true}>
          Currently Trending (5 from within this list will be shown as trending)
        </Typography>
        {/* Preview */}
        <Grid
          container
          xs={12}
          md={12}
          style={{
            border: "1px solid black",
            justifyContent: "space-around",
            paddingTop: "20px",
            paddingBottom: "60px",
            textAlign: "center",
          }}
        >
          {!loadingTrending && trendingVideos.length === 0 && (
            <Typography variant="h2" color="textPrimary" gutterBottom={true}>
              No trending showcases
            </Typography>
          )}
          <MiniVideoPreview
            videos={trendingVideos}
            handleClick={removeTrending}
            // Do not show reorder buttons since randomizer is used
            reorderButtons={false}
            // handleReorder={reorder}
            showData={false}
            action="remove"
          />
        </Grid>

        <TrendingSelector
          handleChangeEventFilter={handleChangeEventFilter}
          selectEvent={selectEvent}
          selectShowcase={selectShowcase}
          events={events}
          showcases={showcases}
          searching={searching}
          loadingEvents={loadingEvents}
          loadingShowcases={loadingShowcases}
          loadingShowcaseDetail={loadingShowcaseDetail}
          addTrending={addTrending}
          filterEvent={filterEvent}
          selectedEvent={selectedEvent}
          selectedShowcase={selectedShowcase}
          selectedShowcaseVideos={selectedShowcaseVideos}
          handleNextClick={handleNextClick}
          handlePrevClick={handlePrevClick}
          moreEvents={Math.floor(totalEvents / (eventsPerPage + 0.1)) > currentEventsPage}
          lessEvents={!(currentEventsPage === 0)}
          error={
            !!errorCollections ||
            !!errorTrending ||
            !!errorEvents ||
            !!errorShowcases ||
            !!errorShowcaseDetail
          }
        />
      </Grid>
    </Grid>
  );
};

export default TrendingShowcasesPage;
