import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Grid, Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import useStyles from "./styles";
import UserEditForm from "./userEdit";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import { User } from "@models/user.model";
import { setActualUser } from "@redux/reducers/users.reducer";

const UserForm: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const allUsers: User[] = useAppSelector(
    (state: RootState) => state.users.users
  );
  useEffect(() => {
    const actualUser = allUsers.find((user: User) => user.id === params.id);
    if (actualUser) {
      dispatch(setActualUser(actualUser));
    } else {
      history.push("/users");
    }
    // Resetting the values that must be reset
    return () => {
      dispatch(setActualUser({}));
    };
  }, [params.id, allUsers]);

  const goBackToUsers = () => {
    history.push("/users");
  };

  const backButton = (
    <Button
      className={classes.backButton}
      color="primary"
      variant="text"
      type="button"
      startIcon={<ChevronLeftIcon />}
      onClick={goBackToUsers}
    >
      <Typography variant="body1" color="primary">
        Back to users list
      </Typography>
    </Button>
  );

  return (
    <Grid container>
      <UserEditForm backButton={backButton} goBackToUsers={goBackToUsers} />
    </Grid>
  );
};

export default UserForm;
