import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteSalesEvent: {
      marginRight: "20px",
    },
    timeError: {
      color: "red",
    },
    smallBottomMargin: {
      marginBottom: "16px",
    },
    bottomMargin: {
      marginBottom: "30px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    switch: {
      marginLeft: "38px",
      paddingBottom: "10px",
    },
    backButton: {
      width: "fit-content",
      paddingLeft: "0",
    },
    spacedContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
    },
    centeredAlign: {
      alignItems: "center",
    },
    salesEventTitleInput: {
      maxWidth: "520px",
    },
    controlLabel: {
      width: "100%",
    },
    chipsContainer: {
      marginBottom: "20px",
    },
    chip: {
      marginRight: "10px",
      marginTop: "5px",
    },
    paperContainer: {
      padding: "30px",
      backgroundColor: theme.palette.common.white,
    },
    toastContainer: {
      display: "flex",
      alignItems: "center",
    },
    toast: {
      marginRight: "30px",
    },
    seeShowcases: {
      marginRight: "20px",
    },
    parentWidth: {
      width: "100%",
    },
    datePicker: {
      backgroundColor: "white",
      marginRight: "60px",
      marginTop: "1em",
    },
  })
);

export const salesEventEditStyles = makeStyles(() =>
  createStyles({
    outerContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    lastUpdateContainer: {
      marginRight: "20px",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      marginBottom: "5px",
    },
    iconContainer: {
      marginRight: "10px",
    },
    icon: {
      fontSize: "18px",
    },
    editBtn: {
      marginLeft: "20px",
    },
  })
);

export default useStyles;
