import { Interest } from "@models/interest.model";

const InterestTypes = {
  SET_INTERESTS: "SET_INTERESTS",
};

export const setInterests = (interests: Interest[]) => ({
  type: InterestTypes.SET_INTERESTS,
  data: interests,
});

export type InterestsState = {
  interests: Interest[];
};

// INITIAL STATE
export const interestsInitialState: InterestsState = {
  interests: [],
};

export type InterestsAction = {
  type: string;
  data: Interest[];
};

const interestsReducer = (
  state = interestsInitialState,
  action: InterestsAction
) => {
  switch (action.type) {
    case InterestTypes.SET_INTERESTS:
      return {
        ...state,
        interests: action.data as Interest[],
      };

    default:
      return state;
  }
};

export default interestsReducer;
