import { UserStatus } from "@models/userStatus.model";

const UserStatusTypes = {
  SET_USER_STATUS: "SET_USER_STATUS",
  SET_ACTUAL_USER_STATUS: "SET_ACTUAL_USER_STATUS",
};

export const setUserStatus = (userStatus: UserStatus[]) => ({
  type: UserStatusTypes.SET_USER_STATUS,
  data: userStatus,
});

export const setChosenUserStatus = (userStatus: UserStatus) => ({
  type: UserStatusTypes.SET_ACTUAL_USER_STATUS,
  data: userStatus,
});

export type UserStatusState = {
  userStatus: UserStatus[];
  chosenUserStatus: UserStatus;
};

// INITIAL STATE
export const userStatusInitialState: UserStatusState = {
  userStatus: [],
  chosenUserStatus: {},
};

export type UserStatusAction = {
  type: string;
  data: UserStatus[] | UserStatus;
};

const userStatusReducer = (
  state = userStatusInitialState,
  action: UserStatusAction
) => {
  switch (action.type) {
    case UserStatusTypes.SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.data as UserStatus[],
      };

    case UserStatusTypes.SET_ACTUAL_USER_STATUS:
      return {
        ...state,
        chosenUserStatus: action.data as UserStatus,
      };

    default:
      return state;
  }
};

export default userStatusReducer;
