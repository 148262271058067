import { NestedObject } from "@models/common";
import { Prospect } from "@models/prospect.model";

const ProspectTypes = {
  SET_PROSPECTS: "SET_PROSPECTS",
  SET_ACTUAL_PROSPECT: "SET_ACTUAL_PROSPECT",
};

export const setProspects = (data: ProspectData) => ({
  type: ProspectTypes.SET_PROSPECTS,
  data: data,
});

export const setActualProspect = (prospect: Prospect) => ({
  type: ProspectTypes.SET_ACTUAL_PROSPECT,
  data: prospect,
});

export type ProspectsState = {
  prospects: Prospect[];
  total: number;
  selectedProspect: Prospect;
};

// INITIAL STATE
export const prospectsInitialState: ProspectsState = {
  prospects: [],
  total: 0,
  selectedProspect: {},
};

export type ProspectData = {
  items: Prospect[];
  total: number;
};

export type ProspectsAction = {
  type: string;
  data: ProspectData | Prospect;
};

const prospectReducer = (
  state = prospectsInitialState,
  action: ProspectsAction
) => {
  switch (action.type) {
    case ProspectTypes.SET_PROSPECTS:
      const prospectData = action.data as ProspectData;
      return {
        ...state,
        prospects: prospectData.items,
        total: prospectData.total,
      };

    case ProspectTypes.SET_ACTUAL_PROSPECT:
      const prospect = action.data as NestedObject;
      return {
        ...state,
        selectedProspect: prospect,
      };

    default:
      return state;
  }
};

export default prospectReducer;
