import { FiltersOption } from '@components/Filters/FiltersDialog';
import { capitalizeFirstLetter } from '.';

export const getOptionsFromArray = (array: string[]) => {
  const options: FiltersOption[] = [];
  array.forEach((item) => {
    options.push({
      label: capitalizeFirstLetter(item.replace(/_/g, ' ')),
      value: item,
    });
  });
  return options;
};
