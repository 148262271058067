import React, { useRef } from "react";

import ReactHlsPlayer from "react-hls-player";

import { Video } from "@models/video.model";

export interface VideoViewerProps {
  video: Video;
}

const VideoViewer: React.FC<VideoViewerProps> = ({ video }) => {
  const playerRef = useRef();

  console.log("ref", playerRef, video.url)

  return (
    <>
      <ReactHlsPlayer
        // @ts-ignore
        playerRef={playerRef}
        src={video.url}
        autoPlay={false}
        controls={true}
        height="250px"
        style={{ maxWidth: "140px", width: "140px" }}
      />
    </>
  );
};

export default VideoViewer;
