const TOKEN_NAME = 'authToken';

export const setToken = (token?: string) => {
  if (token) {
    window.localStorage.setItem(TOKEN_NAME, token);
  }
};

export const getToken = () => {
  return window.localStorage.getItem(TOKEN_NAME);
};

export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_NAME);
};
