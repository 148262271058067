import { FiltersOptionsGroup } from '@components/Filters/FiltersDialog';
import { NestedObject } from '@models/common';
import { FilterChipData } from '@components/Filters/FiltersChips';

const getFiltersChips = (
  filters: NestedObject,
  filtersGroups: FiltersOptionsGroup[],
) => {
  const result: FilterChipData[] = [];
  let chipKey = 0;
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const filter = filters[key];
      const isString = typeof filter === 'string';
      const isArray = Array.isArray(filter);
      if ((isString && filter !== '') || (isArray && filter.length !== 0)) {
        for (let i = 0; i < filtersGroups.length; i++) {
          const filtersGroup = filtersGroups[i];
          if (filtersGroup.groupId === key) {
            for (let j = 0; j < filtersGroup.options.length; j++) {
              const option = filtersGroup.options[j];
              if (
                (isString && option.value === filter) ||
                (isArray && filter.includes(option.value))
              ) {
                result.push({
                  filterId: key,
                  label: option.label,
                  key: chipKey,
                  value: option.value,
                });
                chipKey++;
              }
            }
            break;
          }
        }
      }
    }
  }
  return result;
};

export default getFiltersChips;
