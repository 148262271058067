import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import ProspectsGraphQL from "@graphql/prospects.resolver";

import google from "@images/google.png";
import mastercard from "@images/mastercard.jpg";

import useStyles from "./styles";

enum IntakeStatus {
  LOADING = "LOADING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  ERROR = "ERROR",
}

function IntakeForm() {
  const classes = useStyles();

  const [intakeStatus, setIntakeStatus] = useState<IntakeStatus>(
    IntakeStatus.LOADING
  );

  // Get intake status
  const [getIntakeStatus, { loading, error, data }] = useLazyQuery(
    ProspectsGraphQL.queries.getIntakeStatus,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (error) {
      setIntakeStatus(IntakeStatus.ERROR);
    }

    if (data) {
      console.log("data", JSON.stringify(data, null, 2));
      if (!!data.getIntakeStatus?.status) {
        setIntakeStatus(data.getIntakeStatus.status);
      }

      if (data?.getIntakeStatus?.error) {
        setIntakeStatus(IntakeStatus.ERROR);
      }
    }
  }, [data, error]);

  // Initialize component
  useEffect(() => {
    getIntakeStatus();
  }, []);

  if (loading || intakeStatus === IntakeStatus.LOADING) {
    return (
      <Grid container direction="column">
        <CircularProgress size={50} thickness={5} />
      </Grid>
    );
  }

  if (intakeStatus === IntakeStatus.ERROR) {
    return (
      <Grid container direction="column">
        There is an issue with your application, please contact the system
        administrator
      </Grid>
    );
  }

  if (intakeStatus === IntakeStatus.PENDING) {
    return (
      <Grid container direction="column">
        <div className={classes.text}>
          KINfolk, <br />
          <br />
          Thank you for taking the time to complete our merchant evaluation
          form. We have a few additional questions to help us further evaluate
          your application. <br />
          <br />
          As a next step, please schedule some time to connect with our team
          using this{" "}
          <a
            href="https://calendly.com/marvin-305/30min"
            target="_blank"
            className={classes.calendly}
          >
            Calendly link.
          </a>
          <br />
          <br />
          We look forward to getting to know you and your business better.
          <br />
          <br />
          Talk soon!
        </div>
      </Grid>
    );
  }

  if (intakeStatus === IntakeStatus.APPROVED) {
    return (
      <Grid container direction="column">
        <div className={classes.text}>
          Welcome to the oneKIN family! <br />
          <br />
          Thank you for taking the time to complete our merchant evaluation
          form. We're excited to kick off your onboarding process. <br />
          <br />
          As a next step, please schedule some time to connect with our team
          using this{" "}
          <a
            href="https://calendly.com/marvin-305/30min"
            target="_blank"
            className={classes.calendly}
          >
            Calendly link
          </a>
          . <br />
          <br />
          We look forward to getting to know you and your business better.
          <br />
          <br />
          Talk soon!
          <br />
        </div>
      </Grid>
    );
  }

  if (intakeStatus === IntakeStatus.REJECTED) {
    return (
      <Grid container direction="column">
        <div className={classes.text}>
          KINfolk,
          <br />
          <br />
          Thank you for taking the time to complete our merchant evaluation
          form. <br />
          <br />
          We are currently evaluating countless applications and will follow up
          with any additional questions or next steps once we've completed our
          review. We look forward to getting to know you and your business.{" "}
          <br />
          <br />
          In the interim, we recommend you take advantage of the free resources
          below to help accelerate your business goals and growth.
          <br />
          <br />
          <a
            href="https://grow.google/grow-your-business/#?modal_active=none"
            target={"_blank"}
          >
            <img className={classes.banner} src={google} alt="onekin-logo" />
          </a>
          <a
            href="https://www.mastercard.us/en-us/business/overview/grow-your-business/digital-curriculum.html"
            target={"_blank"}
          >
            <img
              className={classes.banner}
              src={mastercard}
              alt="onekin-logo"
            />
          </a>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      We couldn't find your application, please contact the system administrator
    </Grid>
  );
}

export default IntakeForm;
