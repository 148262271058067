import React, { useEffect } from "react";
import {
  Typography,
  Snackbar,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/ClearRounded";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";

import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import { closeAlert } from "@redux/reducers/alert.reducer";
import { toastTimer } from "@constants";
import useStyles from "./styles";

interface ToastProps {
  isSnackBar?: boolean;
  className?: string;
}

const Toast: React.FC<ToastProps> = ({ className, isSnackBar = true }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const alert = useAppSelector((state: RootState) => state.alerts.alert);

  const classes = useStyles({ status: alert.type });
  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
    dispatch(closeAlert(false));
  };

  useEffect(() => {
    if (alert.show) {
      setOpen(alert.show);
    }
  }, [alert]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  const vertical = isMobile ? "bottom" : "top";
  const horizontal = isMobile ? "center" : "right";

  return isSnackBar ? (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={toastTimer}
      onClose={handleClose}
      className={classes.snackBar}
    >
      <ToastConent
        type={alert.type}
        message={alert.message}
        handleClose={handleClose}
      />
    </Snackbar>
  ) : open ? (
    <div className={className}>
      <ToastConent
        type={alert.type}
        message={alert.message}
        handleClose={handleClose}
      />
    </div>
  ) : (
    <></>
  );
};

interface ToastContentProps {
  type: "error" | "success";
  message: string;
  handleClose: () => void;
}

export const ToastConent: React.FC<ToastContentProps> = ({
  type,
  message,
  handleClose,
}) => {
  const classes = useStyles({ status: type });
  return (
    <div className={classes.container}>
      <Typography variant="body1" className={classes.content}>
        {type === "error" && (
          <ErrorIcon fontSize="small" className={classes.icon} />
        )}
        {type === "success" && (
          <CheckIcon fontSize="small" className={classes.icon} />
        )}
        {message}
      </Typography>
      <IconButton
        className={classes.clearIconBtn}
        aria-label="close-toast"
        size="small"
        onClick={handleClose}
        edge="end"
      >
        <ClearIcon className={classes.clearIcon} />
      </IconButton>
    </div>
  );
};

export default Toast;
