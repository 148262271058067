import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import clsx from "clsx";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import DateTimePickerTextField from "@mui/material/TextField";
import { useParams } from "react-router";

import ShowcasesGraphQL from "@graphql/showcases.resolver";
import { AlertType } from "@models/common";
import { ShowcaseCreate } from "@models/showcase.model";
import getShowcaseInputValues from "@helpers/getShowcaseInputValues";
import useStyles from "./styles";
import CollectionSelector from "@components/CollectionSelector";
import UserSelector from "@components/UserSelector";
import { SalesEvent } from "@models/salesEvent.model";
import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import InterestsSelector from "@components/InterestsSelector";
import { Interest } from "@models/interest.model";

export interface ShowcaseFormProps {
  id?: string;
  showcaseId?: string;
  backButton: React.ReactElement;
  goBackToShowcases: () => void;
  dispatchAlert: (message: string, type: AlertType) => void;
}

const ShowcaseCreateForm: React.FC<ShowcaseFormProps> = ({
  backButton,
  goBackToShowcases,
  dispatchAlert,
}) => {
  const pageClasses = useStyles();
  const params = useParams<{ id: string }>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [published, setPublished] = useState<boolean>(true);
  const [collectionId, setCollectionId] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);

  const [startDateError, setStartDateError] = useState<boolean>(false);
  const salesEvents: SalesEvent[] = useAppSelector(
    (state: RootState) => state.salesEvents.salesEvents
  );
  const salesEvent = salesEvents.find(
    (salesEvent: SalesEvent) => salesEvent.id === params.id
  );

  const [
    createShowcase,
    {
      loading: creatingShowcase,
      error: errorCreating,
      data: createShowcaseData,
    },
  ] = useMutation(ShowcasesGraphQL.mutations.createShowcase, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorCreating) {
      dispatchAlert("Unexpected error creating the showcase", "error");
    }
  }, [errorCreating]);

  useEffect(() => {
    if (createShowcaseData) {
      if (!createShowcaseData.createShowcase?.error) {
        dispatchAlert("Your showcase has been sucesfully created.", "success");
        goBackToShowcases();
      }
      if (createShowcaseData.createShowcase?.error) {
        dispatchAlert(createShowcaseData.createShowcase?.messages[0], "error");
      }
    }
  }, [createShowcaseData]);

  const handleSaveShowcase = () => {
    const showcase: ShowcaseCreate = getShowcaseInputValues({
      startDate,
      endDate,
      published,
      eventId: params.id,
      collectionId,
      userEmail,
      interests: selectedInterests,
    });
    createShowcase({
      variables: {
        input: showcase,
      },
    });
  };

  useEffect(() => {
    evalDates();
  }, [startDate, endDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue || new Date());
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue || new Date());
  };

  const evalDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  const togglePublished = () => {
    setPublished(!published);
  };

  const handleSelectCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  const handleSelectUser = (userEmail: string) => {
    setUserEmail(userEmail);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={clsx(
          pageClasses.spacedContainer,
          pageClasses.smallBottomMargin
        )}
      >
        {backButton}
        <div className={pageClasses.toastContainer}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={handleSaveShowcase}
            disabled={creatingShowcase || startDateError}
          >
            {!creatingShowcase ? "Save" : <CircularProgress size={25} />}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} className={pageClasses.bottomMargin}>
        <div className={pageClasses.row}>
          <Typography variant="h1" color="textPrimary" gutterBottom={true}>
            Create a new Showcase
          </Typography>
          <FormControlLabel
            className={pageClasses.switch}
            control={
              <Switch
                checked={published}
                onChange={togglePublished}
                color="secondary"
              />
            }
            label={published ? "Published Showcase" : "Draft"}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={pageClasses.bottomMargin}>
        <Grid container spacing={5}>
          <UserSelector
            selectedUserEmail={userEmail}
            headerTitle="Select user for the showcase stream"
            onChangeSelectedUserEmail={handleSelectUser}
          />
        </Grid>
        <Grid container>
          <InterestsSelector
            selectedInterests={selectedInterests}
            headerTitle="Select interests for showcase"
            onChangeSelection={(selection: Interest[]) => {
              setSelectedInterests(selection);
            }}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Grid
            className={clsx(pageClasses.bottomMargin, pageClasses.rowContainer)}
          >
            <div className={pageClasses.columnContainer}>
              <DateTimePicker
                label="Start Date"
                minDateTime={salesEvent?.startDate}
                maxDateTime={salesEvent?.endDate}
                value={startDate}
                onChange={handleStartDateChange}
                onAccept={evalDates}
                disableMaskedInput
                renderInput={(params) => (
                  <DateTimePickerTextField
                    {...params}
                    style={{
                      backgroundColor: "white",
                      marginRight: "60px",
                      marginTop: "1em",
                    }}
                  />
                )}
              />
              <div className={pageClasses.timeError}>
                {startDateError && `Start date should be before end date`}
              </div>
            </div>
            <div className={pageClasses.columnContainer}>
              <DateTimePicker
                label="End Date"
                value={endDate}
                minDateTime={salesEvent?.startDate}
                maxDateTime={salesEvent?.endDate}
                onChange={handleEndDateChange}
                onAccept={evalDates}
                disableMaskedInput
                renderInput={(params) => (
                  <DateTimePickerTextField
                    {...params}
                    style={{ backgroundColor: "white", marginTop: "1em" }}
                  />
                )}
              />
              <div className={pageClasses.timeError}>
                {startDateError && `End date should be after start date`}
              </div>
            </div>
          </Grid>
        </LocalizationProvider>
        <Grid container spacing={5}>
          <CollectionSelector
            selectedCollectionId={collectionId}
            headerTitle="Select collection for showcase"
            onChangeCollection={handleSelectCollection}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ShowcaseCreateForm;
