import { pagesTitlesMap } from '@constants';

/**
 * Path example '/users'. First split by '/'
 * Then get the string in the position 1.
 * Change all '-' in path to '_'
 * Get the page title from the map in constants.
 * @param path string
 * @returns string => Page Title
 */
const getPageTitle = (path: string) => {
  const pathParts = path.split('/');
  const key = pathParts[1]?.replace(/-/, '_');
  return pagesTitlesMap[key] ?? '';
};

export default getPageTitle;
