import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: "0px",
      marginBottom: "24px",
    },
    subtitle: {
      margin: "0px",
      marginBottom: "28px",
    },
    accessInput: {
      [theme.breakpoints.up("md")]: {
        maxWidth: "450px",
      },
    },
    pointer: {
      cursor: "pointer",
    },
    errorContainer: {
      marginBottom: "20px",
    },
    homepageLink: {
      display: "inline",
      marginTop: "20px",
    },
  })
);

export default useStyles;
