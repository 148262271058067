import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

import useStyles from "./styles";

const AnalyticsPage: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles();

  // false = loading
  return false ? (
    <Grid item xs={12} className={classes.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <Grid container>
      <Grid container alignItems="center">
        <Grid container justify="space-between">
          <Typography variant="h1" color="textPrimary" gutterBottom={true}>
            Analytics
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticsPage;
