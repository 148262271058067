import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useStyles from "./styles";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import { Prospect } from "@models/prospect.model";
import { setAlert } from "@redux/reducers/alert.reducer";
import { AlertType } from "@models/common";
import clsx from "clsx";
import { setActualProspect } from "@redux/reducers/prospects.reducer";
import ProspectStatusComponent from "./components/prospectStatus";
import { ProspectStatus as ProspectStatusModel } from "@models/prospectStatus.model";
import ProspectsGraphQL from "@graphql/prospects.resolver";
import { useMutation } from "@apollo/client";

const ProspectForm: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const allProspects: Prospect[] = useAppSelector(
    (state: RootState) => state.prospects.prospects
  );

  const selectedProspect: Prospect = useAppSelector(
    (state: RootState) => state.prospects.selectedProspect
  );

  const chosenProspectStatus: ProspectStatusModel = useAppSelector(
    (state: RootState) => state.prospectsStatus.chosenProspectStatus
  );

  const goBackToProspects = () => {
    history.push("/prospects");
  };

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  const [addOrUpdateProspect, { loading, error, data }] = useMutation(
    ProspectsGraphQL.mutations.addOrUpdateProspect,
    {
      onError(error) {
        // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
        console.log(error);
      },
    }
  );

  useEffect(() => {
    const actualProspect = allProspects.find(
      (prospect: Prospect) => prospect.id === params.id
    );
    if (actualProspect) {
      dispatch(setActualProspect(actualProspect));
    } else {
      history.push("/prospects");
    }
    // Resetting the values that must be reset
    return () => {
      dispatch(setActualProspect({}));
    };
  }, [params.id, allProspects]);

  useEffect(() => {
    if (error) {
      dispatchAlert("Unexpected error updating the prospect", "error");
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (!data.addOrUpdateProspect?.error) {
        dispatchAlert("Your prospect has been sucesfully updated.", "success");
        goBackToProspects();
      }
      if (data.addOrUpdateProspect?.error) {
        dispatchAlert(data.addOrUpdateProspect?.messages[0], "error");
      }
    }
  }, [data, dispatchAlert, goBackToProspects]);

  const handleSaveProspect = () => {
    const prospect = {
      id: selectedProspect.id,
      status: chosenProspectStatus.handle,
    };

    addOrUpdateProspect({ variables: { ...prospect } });
  };

  const backButton = (
    <Button
      className={classes.backButton}
      color="primary"
      variant="text"
      type="button"
      startIcon={<ChevronLeftIcon />}
      onClick={goBackToProspects}
    >
      <Typography variant="body1" color="primary">
        Back to prospects list
      </Typography>
    </Button>
  );

  return (
    <Grid container>
      <>
        <Grid
          md={12}
          xs={12}
          className={clsx(classes.spacedContainer, classes.smallBottomMargin)}
        >
          {backButton}
          <div className={classes.toastContainer}>
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={handleSaveProspect}
              disabled={loading}
            >
              {!loading ? "Save" : <CircularProgress size={25} />}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12} className={classes.bottomMargin}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography
                component="h1"
                variant="h1"
                color="textPrimary"
                gutterBottom={false}
              >
                {selectedProspect?.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <ProspectStatusComponent />
        </Grid>
      </>
    </Grid>
  );
};

export default ProspectForm;
