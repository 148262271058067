import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useHistory } from "react-router";
import React, { useState } from "react";
import { reduxForm, InjectedFormProps } from "redux-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";

import { PasswordField, TextField } from "@components/Form";
import { ToastConent } from "@components/Toast/Toast";
import useStyles from "@components/Form/styles";
import { LoginInput } from "./login";
import loginStyles from "./styles";

export interface AccessFormProps {
  loading?: boolean;
  loginError?: string;
  handleClose?: () => void;
}

const LoginForm: React.FC<
  AccessFormProps & InjectedFormProps<LoginInput, AccessFormProps, string>
> = ({ handleSubmit, submitting, loading, loginError, handleClose }) => {
  const history = useHistory();
  const classes = loginStyles();
  const formClasses = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const goToForgetPass = () => {
    history.push("/recover-password");
  };

  const goToIntakeForm = () => {
    history.push("/join-onekin");
  };

  return (
    <form onSubmit={handleSubmit} className={formClasses.formContainer}>
      {loginError && handleClose && (
        <div className={clsx(classes.accessInput, classes.errorContainer)}>
          <ToastConent
            type="error"
            message={loginError}
            handleClose={handleClose}
          />
        </div>
      )}
      <TextField
        name="email"
        label="Email Address"
        required={true}
        meta={{
          variant: "outlined",
        }}
        inputClass={classes.accessInput}
      />
      <PasswordField
        name="password"
        label="Password *"
        placeholder="Password"
        id="login-passowrd"
        required={true}
        showPassword={showPassword}
        inputClass={classes.accessInput}
        meta={{
          variant: "outlined",
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        className={formClasses.submitButton}
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitting || loading}
      >
        {!loading ? "Sign In" : <CircularProgress size={25} />}
      </Button>

      <Button
        className={formClasses.linkButton}
        color="primary"
        variant="text"
        type="button"
        onClick={goToForgetPass}
      >
        Forgot password?
      </Button>
      <Button
        className={clsx(formClasses.linkButton, classes.homepageLink)}
        color="secondary"
        variant="outlined"
        type="button"
        onClick={goToIntakeForm}
      >
        Join the oneKIN family
      </Button>
    </form>
  );
};

const LoginReduxForm = reduxForm<LoginInput, AccessFormProps>({
  form: "LoginForm",
})(LoginForm);

export default LoginReduxForm;
