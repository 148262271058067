import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomMargin: {
      marginBottom: "30px",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    timeError: {
      color: "red",
    },
  })
);

export default useStyles;
