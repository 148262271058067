import React from "react";

import { Collection } from "@models/collection.model";
import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";

interface Props {
  collection?: string;
}

const CollectionParser: React.FC<Props> = ({ collection }) => {
  const collections: Collection[] = useAppSelector(
    (state: RootState) => state.collections.collections
  );

  const collectionTitle =
    collections?.find((collectionItem) => collectionItem.id === collection)
      ?.title ?? "";

  return <div>{collectionTitle}</div>;
};

export default CollectionParser;
