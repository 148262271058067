
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: "3em",
        marginBottom: "3em",
        width: "100%",
      },
      listItem: {
        marginTop: "10px",
        marginBottom: "10px",
        cursor: "pointer",
      },
      selectedListItem: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        marginTop: "15px",
        marginBottom: "15px",
      }
  })
);

export default useStyles;
