import { Collection } from "@models/collection.model";

const CollectionTypes = {
  SET_COLLECTIONS: "SET_COLLECTIONS",
};

export const setCollections = (collections: Collection[]) => ({
  type: CollectionTypes.SET_COLLECTIONS,
  data: collections,
});

export type CollectionsState = {
  collections: Collection[];
  chosenCollection: Collection;
};

// INITIAL STATE
export const collectionsInitialState: CollectionsState = {
  collections: [],
  chosenCollection: {},
};

export type CollectionsAction = {
  type: string;
  data: Collection[] | Collection;
};

const collectionsReducer = (
  state = collectionsInitialState,
  action: CollectionsAction
) => {
  switch (action.type) {
    case CollectionTypes.SET_COLLECTIONS:
      return {
        ...state,
        collections: action.data as Collection[],
      };

    default:
      return state;
  }
};

export default collectionsReducer;
