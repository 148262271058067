import gql from "graphql-tag";

export const getUserStatus = gql`
  query userStatus {
    userStatus {
      handle
      title
    }
  }
`;

const userStatusResolver = {
  queries: {
    getUserStatus,
    // getUser,
  },
  //   mutations: {
  //     updateUser,
  //   },
};

export default userStatusResolver;
