import React, { useState, useEffect } from "react";
import { DocumentNode, useLazyQuery } from "@apollo/client";
import {
  Table,
  TableContainer,
  TablePagination,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import isEqual from "lodash/isEqual";

import { useAppDispatch } from "@redux/hooks";
import { MappedObject, NestedObject } from "@models/common";
import { parseFilters } from "@helpers";
import { Filters } from "@components/Filters";
import { FiltersOptionsGroup } from "@components/Filters/FiltersDialog";
import SharedTableHeader from "./SharedTableHeader";
import SharedTableBody from "./SharedTableBody";
import TablePaginationActions from "./TablePaginationActions";
import useStyles from "./styles";
import { TableColumn } from ".";
import { UsersData } from "@redux/reducers/users.reducer";
import { SalesEventsData } from "@redux/reducers/salesEvents.reducer";

interface TableProps {
  tableDisplayName: string;
  tableName: string;
  columns: TableColumn[];
  query: DocumentNode;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  filtersOptions?: FiltersOptionsGroup[];
  searchBarLabel?: string;
  searchBarId?: string | string[];
  tableDispatch?:
    | ((data: UsersData | SalesEventsData) => {
        type: string;
        data: UsersData | SalesEventsData;
      })
    | undefined;
  dataPath: string;
  displayCreateNew?: () => void;
  displayEdit?: () => void;
  filterProps?: NestedObject;
}

const SharedTable: React.FC<TableProps> = ({
  tableDisplayName,
  tableName,
  columns,
  query,
  primaryButton,
  secondaryButton,
  filtersOptions,
  searchBarLabel,
  searchBarId,
  tableDispatch,
  dataPath,
  displayCreateNew,
  displayEdit,
  filterProps,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<NestedObject[]>([]);
  const [total, setTotal] = useState(0);

  const [filters, setFilters] = useState<NestedObject>({
    page: 0,
    rowsPerPage: 12,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleRequestSort = (property: string) => {
    setFilters({
      ...filters,
      sortBy: property,
      sortDirection: filters.sortDirection === "DESC" ? "ASC" : "DESC",
    });
  };

  const handleChangeFilters = (newFilters: MappedObject) => {
    if (!isEqual(filters, newFilters)) {
      setFilters({
        ...filters,
        ...newFilters,
      });
    }
  };

  const handleRefresh = () => {
    getInfoForTable();
  };

  const [getData, { loading, error, data }] = useLazyQuery(query, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("ERROR getData QUERY");
  }

  useEffect(() => {
    // Example: data.users
    const dataType = data?.[dataPath];
    if (dataType && !dataType.error) {
      // Example: data.users.users
      const items = dataType[tableName];
      // Example: data.users.pagination
      const total = dataType.pagination?.total;
      setRows(items);
      setTotal(total);

      if (tableDispatch) {
        dispatch(
          tableDispatch({
            items,
            total,
          })
        );
      }
    }
  }, [data, dataPath, tableName, tableDispatch, dispatch]);

  useEffect(() => {
    getInfoForTable();
  }, [filters]);

  const getInfoForTable = () => {
    const parsedFilters = parseFilters(filters);

    getData({
      variables: {
        ...parsedFilters,
        ...filterProps,
      },
    });
  };

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Typography variant="h1" color="textPrimary" gutterBottom={true}>
          {tableDisplayName}
        </Typography>
        {displayCreateNew && (
          <IconButton
            aria-label="create-new-child"
            onClick={displayCreateNew}
            edge="end"
            className={classes.createNewContainer}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        )}
        {displayEdit && (
          <IconButton
            aria-label="edit-parent"
            onClick={displayEdit}
            edge="end"
            className={classes.editContainer}
          >
            <EditIcon color="primary" />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12} md={12} className={classes.filtersContainer}>
        <Filters
          filters={filters}
          optionsGroups={filtersOptions ?? []}
          onChangeFilters={handleChangeFilters}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          onRefresh={handleRefresh}
          searchBarLabel={searchBarLabel}
          searchBarId={searchBarId}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <TableContainer component="div" className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="custom pagination table"
            className={classes.table}
          >
            <SharedTableHeader
              columns={columns}
              onRequestSort={handleRequestSort}
              sortBy={filters.sortBy}
              sortDirection={filters.sortDirection}
            />
            <SharedTableBody columns={columns} rows={rows} loading={loading} />
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          component="div"
          count={total}
          rowsPerPage={filters?.rowsPerPage || 12}
          rowsPerPageOptions={[]}
          page={total ? filters.page : 0}
          onChangePage={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Grid>
    </Grid>
  );
};

export default SharedTable;
