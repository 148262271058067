import { Role } from '@models/role.model';

const RoleTypes = {
  SET_ROLES: 'SET_ROLES',
  SET_ACTUAL_ROLE: 'SET_ACTUAL_ROLE',
};

export const setRoles = (roles: Role[]) => ({
  type: RoleTypes.SET_ROLES,
  data: roles,
});

export const setChosenRole = (roles: Role) => ({
  type: RoleTypes.SET_ACTUAL_ROLE,
  data: roles,
});

export type RolesState = {
  roles: Role[];
  chosenRole: Role;
};

// INITIAL STATE
export const rolesInitialState: RolesState = {
  roles: [],
  chosenRole: {},
};

export type RolesAction = {
  type: string;
  data: Role[] | Role;
};

const rolesReducer = (
  state = rolesInitialState,
  action: RolesAction,
) => {
  switch (action.type) {
    case RoleTypes.SET_ROLES:
      return {
        ...state,
        roles: action.data as Role[],
      };

    case RoleTypes.SET_ACTUAL_ROLE:
      return {
        ...state,
        chosenRole: action.data as Role,
      };

    default:
      return state;
  }
};

export default rolesReducer;
