import { SalesEvent } from "@models/salesEvent.model";

const SalesEventsTypes = {
  SET_SALES_EVENTS: "SET_SALES_EVENTS",
};

export const setSalesEvents = (data: SalesEventsData) => ({
  type: SalesEventsTypes.SET_SALES_EVENTS,
  data: data,
});


export type SalesEventState = {
  salesEvents: SalesEvent[];
  total: number;
  selectedSalesEvent: SalesEvent;
};

// INITIAL STATE
export const salesEventsInitialState: SalesEventState = {
  salesEvents: [],
  total: 0,
  selectedSalesEvent: {},
};

export type SalesEventsData = {
  items: SalesEvent[];
  total: number;
};

export type SalesEventsAction = {
  type: string;
  data: SalesEventsData | SalesEvent;
};

const salesEventsReducer = (state = salesEventsInitialState, action: SalesEventsAction) => {
  switch (action.type) {
    case SalesEventsTypes.SET_SALES_EVENTS:
      const salesEventsData = action.data as SalesEventsData;
      return {
        ...state,
        salesEvents: salesEventsData.items,
        total: salesEventsData.total,
      };


    default:
      return state;
  }
};

export default salesEventsReducer;
