const AlertTypes = {
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
};

export const setAlert = (alert: AlertData) => ({
  type: AlertTypes.SET_ALERT,
  data: alert,
});

export const closeAlert = (show: boolean) => ({
  type: AlertTypes.CLOSE_ALERT,
  data: show,
});

type ActualAlertState = {
  show: boolean;
  message: string;
  type: 'error' | 'success';
};

export type AlertState = {
  alert: ActualAlertState;
};

// INITIAL STATE
export const alertInitialState: AlertState = {
  alert: {
    show: false,
    message: '',
    type: 'success',
  },
};

type AlertData = {
  message: string;
  type: 'error' | 'success';
};

export type AlertAction = {
  type: string;
  data: AlertData | boolean;
};

const alertReducer = (state = alertInitialState, action: AlertAction) => {
  switch (action.type) {
    case AlertTypes.SET_ALERT:
      const alert = action.data as AlertData;
      return {
        ...state,
        alert: {
          show: true,
          message: alert.message,
          type: alert.type,
        },
      };

    case AlertTypes.CLOSE_ALERT:
      const { data } = action;
      return {
        ...state,
        alert: {
          ...state.alert,
          show: data as boolean,
        },
      };

    default:
      return state;
  }
};

export default alertReducer;
