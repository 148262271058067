/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import CollectionsGraphQL from "@graphql/collections.resolver";
import InterestsGraphQL from "@graphql/interests.resolver";
import { AlertType } from "@models/common";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setAlert } from "@redux/reducers/alert.reducer";
import { setCollections } from "@redux/reducers/collections.reducer";
import { setInterests } from "@redux/reducers/interests.reducer";
import useStyles from "./styles";
import { Interest } from "@models/interest.model";
import { RootState } from "@redux/store";
import InterestConfigurator from "@components/InterestConfigurator";

const InterestsManagerPage: React.FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };
  const classes = useStyles();
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [currentInterests, setCurrentInterests] = useState<Interest[]>([]);

  const baseInterests: Interest[] = useAppSelector(
    (state: RootState) => state.interests.interests
  );

  useEffect(() => {
    setCurrentInterests([...baseInterests]);
  }, [baseInterests]);

  // Initialize collections
  const [
    getCollections,
    {
      loading: loadingCollections,
      error: errorCollections,
      data: collectionsData,
    },
  ] = useLazyQuery(CollectionsGraphQL.queries.getCollections, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorCollections) {
      dispatchAlert("Error getting collections data.", "error");
    }
  }, [errorCollections]);

  useEffect(() => {
    if (collectionsData) {
      if (!collectionsData.collections?.error) {
        dispatch(setCollections(collectionsData.collections.collections));
      }

      if (collectionsData?.error) {
        dispatchAlert(collectionsData?.messages[0], "error");
      }
    }
  }, [collectionsData]);

  // Initialize interests
  const [
    shopInterests,
    { loading: loadingInterests, error: errorInterests, data: interestsData },
  ] = useLazyQuery(InterestsGraphQL.queries.shopInterests, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (errorInterests) {
      dispatchAlert("Error getting interests data.", "error");
    }
  }, [errorInterests]);

  useEffect(() => {
    if (interestsData) {
      if (!!interestsData.shopInterests?.interests) {
        dispatch(setInterests(interestsData.shopInterests.interests));
      }

      if (interestsData?.shopInterests?.error) {
        dispatchAlert(interestsData?.messages[0], "error");
      }
    }
  }, [interestsData]);

  // Update interests
  const [updateInterests, { loading, error, data }] = useMutation(
    InterestsGraphQL.mutations.updateInterests,
    {
      onError(error) {
        // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (error) {
      dispatchAlert("Unexpected error updating interests", "error");
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setEnableSave(false);
      dispatchAlert('Interests saved successfully', "success");
    }
  }, [data]);

  // Initialize component
  useEffect(() => {
    getCollections();
    shopInterests();
  }, [getCollections, shopInterests]);

  // local handling
  const selectCollection = (idx: number, collectionId: string | null) => {
    const newCurrentInterests = [...currentInterests];
    newCurrentInterests[idx].collectionId = collectionId;
    setCurrentInterests(newCurrentInterests);
    setEnableSave(true);
  };

  const moveUp = (idx: number) => {
    const newCurrentInterests = [...currentInterests];

    newCurrentInterests[idx - 1] = {
      ...currentInterests[idx],
      order: idx - 1,
    };
    newCurrentInterests[idx] = {
      ...currentInterests[idx - 1],
      order: idx,
    };

    setCurrentInterests(newCurrentInterests);
    setEnableSave(true);
  };

  const moveDown = (idx: number) => {
    const newCurrentInterests = [...currentInterests];

    newCurrentInterests[idx + 1] = {
      ...currentInterests[idx],
      order: idx + 1,
    };
    newCurrentInterests[idx] = {
      ...currentInterests[idx + 1],
      order: idx,
    };

    setCurrentInterests(newCurrentInterests);
    setEnableSave(true);
  };

  const updateInterestName = (idx: number, newName: string) => {
    const newCurrentInterests = [...currentInterests];
    newCurrentInterests[idx].name = newName;
    setCurrentInterests(newCurrentInterests);
    setEnableSave(true);
  }

  const saveInterests = () => {
    updateInterests({
      variables: {
        input: {
          interests: currentInterests.map((interest) => {
            delete interest.__typename;
            return interest;
          }),
        },
      },
    });
  };

  return loadingCollections || loadingInterests ? (
    <Grid item xs={12} className={classes.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={12}
          md={12}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid container xs={11} md={11}>
            <Typography variant="h1" color="textPrimary" gutterBottom={true}>
              Interests Manager
            </Typography>
            {/* <IconButton
              aria-label="create-new-child"
              onClick={() => {
                alert("Create new child");
              }}
              edge="end"
              style={{
                marginLeft: "10px",
                marginBottom: "12px",
              }}
            >
              <AddCircleIcon color="primary" />
            </IconButton> */}
          </Grid>
          {true && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="button"
                onClick={saveInterests}
                disabled={!enableSave || loading}
              >
                {!loading ? "Save" : <CircularProgress size={25} />}
              </Button>
            </div>
          )}
        </Grid>

        <Grid container xs={12} md={12} style={{ border: "1px solid black" }}>
          {currentInterests.map((interest, idx) => {
            return (
              <InterestConfigurator
                key={`interests-${interest.id}-${idx}`}
                interest={interest}
                showMoveUp={idx > 0}
                showMoveDown={idx < currentInterests.length - 1}
                selectCollection={(collectionId) =>
                  selectCollection(idx, collectionId)
                }
                moveUp={() => moveUp(idx)}
                moveDown={() => moveDown(idx)}
                updateInterestName={(newName) => updateInterestName(idx, newName)}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InterestsManagerPage;
