import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import clsx from "clsx";

import UsersGraphQL from "@graphql/users.resolver";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "./styles";
import { User } from "@models/user.model";
import { AlertType } from "@models/common";
import { setAlert } from "@redux/reducers/alert.reducer";
import UserRole from "./components/userRole";
import { Role } from "@models/role.model";
import CollectionSelector from "@components/CollectionSelector";
import { UserStatus } from "@models/userStatus.model";
import UserStatusComponent from "./components/userStatus";

const UserEditForm: React.FC<any> = ({ backButton, goBackToUsers }) => {
  const pageClasses = useStyles();
  const dispatch = useAppDispatch();
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [showCollections, setShowCollections] = useState<boolean>(false);

  const [collectionId, setCollectionId] = useState<string | null>(null);

  const loggedInUser: User = useAppSelector(
    (state: RootState) => state.user.user
  );
  const selectedUser: User = useAppSelector(
    (state: RootState) => state.users.selectedUser
  );

  const chosenRole: Role = useAppSelector(
    (state: RootState) => state.roles.chosenRole
  );

  const chosenUserStatus: UserStatus = useAppSelector(
    (state: RootState) => state.userStatus.chosenUserStatus
  );

  useEffect(() => {
    setCollectionId(selectedUser?.defaultCollection || null);
  }, [selectedUser]);

  const [
    updateUser,
    { loading: updatingUser, error: errorUpdating, data: updateUserData },
  ] = useMutation(UsersGraphQL.mutations.updateUser, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  const dispatchAlert = (message: string, type: AlertType) => {
    dispatch(setAlert({ message, type }));
  };

  useEffect(() => {
    if (errorUpdating) {
      if (
        loggedInUser.role === "ADMIN" &&
        chosenRole.handle !== "ADMIN" &&
        selectedUser.id === loggedInUser.id
      ) {
        dispatchAlert("You can't un-admin yourself", "error");
      } else {
        dispatchAlert("Unexpected error updating the user", "error");
      }
    }
  }, [errorUpdating]);

  useEffect(() => {
    if (updateUserData) {
      if (!updateUserData.updateUser?.error) {
        dispatchAlert("Your user has been sucesfully updated.", "success");
        goBackToUsers();
      }
      if (updateUserData.updateUser?.error) {
        dispatchAlert(updateUserData.updateUser?.messages[0], "error");
      }
    }
  }, [updateUserData, dispatchAlert, goBackToUsers]);

  useEffect(() => {
    if (
      selectedUser.id !== loggedInUser.id &&
      (chosenRole?.handle !== selectedUser?.role ||
        (chosenRole?.hasCollection &&
          collectionId !== selectedUser?.defaultCollection) ||
        chosenUserStatus?.handle !== selectedUser?.status)
    ) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }

    if (chosenRole?.hasCollection) {
      setShowCollections(true);
    } else {
      setShowCollections(false);
    }
  }, [collectionId, chosenRole, chosenUserStatus]);

  const handleSaveUser = () => {
    const user = {
      id: selectedUser.id,
      defaultCollection: chosenRole.hasCollection ? collectionId : null,
      role: chosenRole.handle,
      status: chosenUserStatus.handle,
      lastUpdatedBy: "BACKOFFICE_ADMIN",
    };
    updateUser({
      variables: {
        input: {
          ...user,
        },
      },
    });
  };

  const onChangeCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  return (
    <>
      <Grid
        md={12}
        xs={12}
        className={clsx(
          pageClasses.spacedContainer,
          pageClasses.smallBottomMargin
        )}
      >
        {backButton}
        <div className={pageClasses.toastContainer}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={handleSaveUser}
            disabled={!enableSave || updatingUser}
          >
            {!updatingUser ? "Save" : <CircularProgress size={25} />}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Typography
              component="h1"
              variant="h1"
              color="textPrimary"
              gutterBottom={false}
            >
              {selectedUser?.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <div style={{ marginBottom: "32px" }}>
          <div style={{ width: "100px", textAlign: "center" }}>
            User Avatar {!selectedUser.avatar && ": No Avatar"}
          </div>
          {selectedUser.avatar && (
            <img
              src={selectedUser.avatar}
              width="100"
              height="100"
              style={{ borderRadius: "50px", marginTop: "10px" }}
            />
          )}
        </div>
      </Grid>

      <Grid xs={12}>
        <UserRole />
      </Grid>

      <Grid xs={12}>
        <UserStatusComponent />
      </Grid>

      {showCollections && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={5}>
            <CollectionSelector
              selectedCollectionId={collectionId}
              headerTitle="Select default collection"
              onChangeCollection={onChangeCollection}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserEditForm;
