import { CombinedState, combineReducers } from "redux";
import { FormStateMap, reducer as reduxFormReducer } from "redux-form";

import userReducer, {
  UserState,
  UserAction,
  UserTypes,
} from "./reducers/user.reducer";
import alertReducer, {
  AlertAction,
  AlertState,
} from "./reducers/alert.reducer";
import usersReducer, {
  UsersAction,
  UsersState,
} from "./reducers/users.reducer";
import salesEventsReducer, {
  SalesEventsAction,
  SalesEventState,
} from "./reducers/salesEvents.reducer";
import showcaseReducer, {
  ShowcasesAction,
  ShowcaseState,
} from "./reducers/showcases.reducer";
import collectionsReducer, {
  CollectionsAction,
  CollectionsState,
} from "./reducers/collections.reducer";
import rolesReducer, {
  RolesAction,
  RolesState,
} from "./reducers/roles.reducer";
import userStatusReducer, {
  UserStatusAction,
  UserStatusState,
} from "./reducers/userStatus.reducer";
import prospectsStatusReducer, {
  ProspectsStatusAction,
  ProspectsStatusState,
} from "./reducers/prospectStatus.reducer";
import interestsReducer, {
  InterestsAction,
  InterestsState,
} from "./reducers/interests.reducer";
import prospectsReducer, {
  ProspectsAction,
  ProspectsState,
} from "./reducers/prospects.reducer";

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  alerts: alertReducer,
  collections: collectionsReducer,
  form: reduxFormReducer,
  roles: rolesReducer,
  userStatus: userStatusReducer,
  user: userReducer,
  users: usersReducer,
  salesEvents: salesEventsReducer,
  showcases: showcaseReducer,
  interests: interestsReducer,
  prospects: prospectsReducer,
  prospectsStatus: prospectsStatusReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        alerts: AlertState;
        collections: CollectionsState;
        form: FormStateMap;
        roles: RolesState;
        userStatus: UserStatusState;
        user: UserState;
        users: UsersState;
        salesEvents: SalesEventState;
        showcases: ShowcaseState;
        interests: InterestsState;
        prospects: ProspectsState;
        prospectsStatus: ProspectsStatusState;
      }>
    | undefined,
  action:
    | AlertAction
    | CollectionsAction
    | RolesAction
    | UserStatusAction
    | UserAction
    | UsersAction
    | SalesEventsAction
    | ShowcasesAction
    | InterestsAction
    | ProspectsAction
    | ProspectsStatusAction
) => {
  if (action.type === UserTypes.LOGOUT_USER) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
