import React, { useEffect } from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { isSafari, isMobile } from "react-device-detect";
import clsx from "clsx";

import AuthGraphQL from "@graphql/authentication.resolver";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { logoutUser } from "@redux/reducers/user.reducer";
import { RootState } from "@redux/store";
import { User } from "@models/user.model";
import Toast from "@components/Toast";
import { getPageTitle } from "@helpers";
import logo from "@images/logo.png";
import links, { LinkType } from "./links";
import useStyles from "./styles";

interface DrawerProps {
  component: React.FC;
  path: string;
  withToast: boolean;
  topPadding: boolean;
}

const ResponsiveDrawer: React.FC<DrawerProps> = ({
  component: Component,
  path,
  withToast,
  topPadding,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user: User = useAppSelector((state: RootState) => state.user.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [logout, { loading: _loading, error, data }] = useLazyQuery(
    AuthGraphQL.queries.logout
  );

  if (error) {
    console.log("ERROR LOGOUT QUERY");
  }

  useEffect(() => {
    if (data) {
      dispatch(logoutUser());
      history.push("/login");
    }
  }, [data, logoutUser, history]);

  const goToPage = (link: string) => () => {
    if (link === "login") {
      logout();
    } else {
      history.push(`/${link}`);
      setMobileOpen(false);
    }
  };

  const highLightItem = (link: string, otherLinks?: string[]) => {
    if (path.indexOf(link) !== -1) {
      return true;
    }

    return !otherLinks
      ? false
      : otherLinks.some((otherlink) => path.indexOf(otherlink) !== -1);
  };

  const renderListItem = (link: LinkType) => {
    const isSelected = highLightItem(link.link, link.highlightLinks);
    if (!!link.roles && !!user.role && !link.roles.includes(user.role)) {
      return null;
    }
    
    return (
      <ListItem
        button
        component={React.forwardRef((props, _ref) => (
          <Link
            color="textPrimary"
            {...props}
            onClick={goToPage(link.link)}
            underline="none"
          />
        ))}
        selected={isSelected}
      >
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText primary={link.text} />
        {isSelected && <div className={classes.selectedItem} />}
      </ListItem>
    );
  };

  const drawer = (
    <div className={classes.drawerInnerContainer}>
      <div>
        <Toolbar style={{ backgroundColor: "white" }}>
          <img src={logo} className={classes.companyLogo} alt="company-logo" />
        </Toolbar>
        <Divider />
        <div className={classes.userInfoContainer}>
          <Typography variant="h6" noWrap color="primary">
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="subtitle2" noWrap color="textSecondary">
            {user.email}
          </Typography>
        </div>
      </div>
      <Divider />
      <List className={classes.drawerList} component="div">
        <div>
          {links.map((link: LinkType, index: number) => {
            return (
              <div key={`${link.text}-${index}`}>
                {link.sublinks ? (
                  <>
                    {renderListItem(link)}
                    <List
                      component="div"
                      disablePadding
                      className={classes.nestedList}
                    >
                      {link.sublinks.map((sublink: LinkType, i: number) => {
                        const isSelected = highLightItem(
                          sublink.link,
                          sublink.highlightLinks
                        );
                        return (
                          <ListItem
                            key={`${sublink.text}-${i}`}
                            button
                            component={React.forwardRef((props, _ref) => (
                              <Link
                                color="primary"
                                {...props}
                                onClick={goToPage(sublink.link)}
                                underline="none"
                              />
                            ))}
                            selected={isSelected}
                          >
                            <ListItemIcon>{sublink.icon}</ListItemIcon>
                            <ListItemText primary={sublink.text} />
                            {isSelected && (
                              <div
                                className={clsx(
                                  classes.selectedItem,
                                  classes.selectedSubItem
                                )}
                              />
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                ) : (
                  renderListItem(link)
                )}
              </div>
            );
          })}
        </div>
        <div>
          <ListItem
            button
            component={React.forwardRef((props, _ref) => (
              <Link
                color="textPrimary"
                {...props}
                onClick={goToPage("login")}
                underline="none"
              />
            ))}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </div>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden only={["md", "lg", "xl"]} implementation="css">
        <AppBar position="fixed" color="transparent" elevation={2}>
          <Toolbar style={{ backgroundColor: "white" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h2" color="textPrimary" gutterBottom={false}>
              {getPageTitle(path)}
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden only={["md", "lg", "xl"]} implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden only={["xs", "sm"]} implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.listContent]: topPadding,
          [classes.notSafariContent]: !isSafari,
        })}
      >
        {withToast && <Toast />}
        <Component></Component>
        <div
          className={clsx(classes.appLogoContainer, {
            [classes.appLogoContainerMobile]: isMobile,
          })}
        >
          <Typography variant="overline">POWERED BY:</Typography>
          <img src={logo} className={classes.appLogo} alt="onekin-logo" />
        </div>
      </main>
    </div>
  );
};

export default ResponsiveDrawer;
