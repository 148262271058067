import gql from "graphql-tag";

export const getInterests = gql`
  query interests {
    interests {
      interests {
        id
        name
        collectionId
        order
      }
    }
  }
`;

export const shopInterests = gql`
  query shopInterests {
    shopInterests {
      interests {
        id
        name
        collectionId
        order
      }
    }
  }
`;

export const updateInterests = gql`
  mutation updateInterests($input: UpdateInterestsInput!) {
    updateInterests(input: $input) {
      interests {
        id
        name
        order
        collectionId
      }
      error
      messages
    }
  }
`;

const interestsResolver = {
  queries: {
    getInterests,
    shopInterests,
  },
  mutations: {
    updateInterests,
  },
};

export default interestsResolver;
