import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidthDesktop = 256;
const drawerWidthMobile = 315;
const mobileHeaderHeight = 56;
const appLogoContainerMargin = 40;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidthDesktop,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      boxSizing: 'border-box',
      width: drawerWidthDesktop,
    },
    drawerPaperMobile: {
      boxSizing: 'border-box',
      width: drawerWidthMobile,
    },
    drawerInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh)',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh)',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    companyLogo: {
      maxWidth: '200px',
      maxHeight: '100px',
      marginTop: '25px',
      marginBottom: '10px',
    },
    userInfoContainer: {
      padding: '12px 24px',
    },
    drawerList: {
      flex: 1,
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: '30px',
    },
    content: {
      width: '100%',
      backgroundColor: theme.palette.grey[100],
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: {
        height: '100vh',
        padding: '40px 60px',
      },
      [theme.breakpoints.down('lg')]: {
        height: '100vh',
        padding: '40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '25px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        // The bar where the menu button is held
        marginTop: `${mobileHeaderHeight}px`,
        height: `calc(100vh - ${mobileHeaderHeight}px - ${appLogoContainerMargin}px)`,
      },
    },
    notSafariContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    listContent: {
      [theme.breakpoints.up('lg')]: {
        paddingTop: '40px',
      },
    },
    appLogoContainer: {
      marginTop: '20px',
    },
    appLogoContainerMobile: {
      marginTop: `${appLogoContainerMargin}px`,
    },
    appLogo: {
      marginLeft: '10px',
      maxWidth: '72px',
      verticalAlign: 'middle',
    },
    nestedList: {
      marginLeft: '20px',
    },
    selectedItem: {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      height: '100%',
      // To achieve a 5px width set the width at 10
      width: '10px',
      // 12px margin left of the ListItem + 5px half of the width
      left: '-17px',
      // And the border radius to achieve the exact look we want
      borderRadius: '4px',
    },
    selectedSubItem: {
      // 12px margin left of the ListItem +  20px of the nested list + 5px half of the width
      left: '-37px',
    },
    multiIconsContainer: {
      display: 'flex',
      position: 'relative',
    },
    innerIcon: {
      position: 'absolute',
      color: theme.palette.common.white,
      transform: 'translate(-50%, -50%)',
      left: '45%',
      top: '50%',
      fontSize: '16px',
    },
    innerIconPrimary: {
      color: theme.palette.secondary.main,
    },
  }),
);

export default useStyles;
