import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import clsx from "clsx";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import DateTimePickerTextField from "@mui/material/TextField";

import SalesEventGraphQL from "@graphql/salesEvents.resolver";

import { SalesEventCreate } from "@models/salesEvent.model";
import getSalesEventInputValues from "@helpers/getSalesEventInputValues";
import { SalesEventFormProps } from "./salesEventCreate";
import FileSelector from "@components/Uploads/FileSelector";
import useStyles from "./styles"; // salesEventEditStyles
import CollectionSelector from "@components/CollectionSelector";
import ConfirmDialog from "@components/ConfirmDialog";
import InterestsSelector from "@components/InterestsSelector";
import { Interest } from "@models/interest.model";

const SalesEventEditForm: React.FC<SalesEventFormProps> = ({
  id,
  backButton,
  dispatchAlert,
}) => {
  // const classes = salesEventEditStyles();
  const pageClasses = useStyles();
  const history = useHistory();

  const [title, setTitle] = useState<string>("");
  // const [editTitle, setEditTitle] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [file, setFile] = useState<File | null>();
  const [published, setPublished] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [collectionId, setCollectionId] = useState<string>("");
  const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const [startDateError, setStartDateError] = useState<boolean>(false);

  const [
    getSalesEvent,
    {
      loading: loadingSalesEvent,
      error: errorSalesEvent,
      data: salesEventData,
    },
  ] = useLazyQuery(SalesEventGraphQL.queries.getSalesEvent, {
    fetchPolicy: "network-only",
  });

  const getData = () => {
    const variables = {
      variables: {
        id,
      },
    };
    getSalesEvent(variables);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (errorSalesEvent) {
      dispatchAlert("Error getting Sales Event data.", "error");
    }
  }, [errorSalesEvent]);

  useEffect(() => {
    if (salesEventData) {
      if (!salesEventData.getSalesEvent?.error) {
        const {
          published,
          title,
          description,
          startDate,
          endDate,
          image,
          defaultCollection,
          interests,
        } = salesEventData.getSalesEvent;
        if (published) setPublished(published);
        if (title) setTitle(title);
        if (description) setDescription(description);
        if (startDate) setStartDate(startDate);
        if (endDate) setEndDate(endDate);
        if (image) setImage(image);
        if (defaultCollection) setCollectionId(defaultCollection);
        if (interests) setSelectedInterests(interests);
      }
      if (salesEventData.getSalesEvent?.error) {
        dispatchAlert(salesEventData.getSalesEvent.messages[0], "error");
      }
    }
  }, [salesEventData]);

  const [
    updateSalesEvent,
    {
      loading: updatingSalesEvent,
      error: errorUpdating,
      data: updateSalesEventData,
    },
  ] = useMutation(SalesEventGraphQL.mutations.updateSalesEvent, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (errorUpdating) {
      dispatchAlert("Unexpected error updating the Sales Event", "error");
    }
  }, [errorUpdating]);

  useEffect(() => {
    if (updateSalesEventData) {
      if (!updateSalesEventData.updateSalesEvent?.error) {
        dispatchAlert(
          "Your Sales Event has been sucesfully updated.",
          "success"
        );
      }
      if (updateSalesEventData.updateSalesEvent?.error) {
        dispatchAlert(
          updateSalesEventData.updateSalesEvent?.messages[0],
          "error"
        );
      }
    }
  }, [updateSalesEventData]);

  const handleSaveSalesEvent = () => {
    const salesEvent: SalesEventCreate = getSalesEventInputValues({
      title,
      description,
      startDate,
      endDate,
      file: file || null,
      fileType: file?.type || null,
      published,
      collectionId,
      interests: selectedInterests,
    });
    updateSalesEvent({
      variables: {
        input: {
          id,
          ...salesEvent,
        },
      },
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    evalDates();
  }, [startDate, endDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue || new Date());
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue || new Date());
  };

  const evalDates = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  const handleSelectCollection = (collectionId: string) => {
    setCollectionId(collectionId);
  };

  const handleSelectHeroImage = (file: File | null) => {
    setFile(file);
  };

  const togglePublished = () => {
    setPublished(!published);
  };

  const navigateToEventShowcases = () => {
    history.push(`/sales-event/${id}/showcases`);
  };

  const deleteSalesEventRequest = () => {
    setOpenConfirmDialog(true);
  };

  const onCloseConfirmDialog = (confirm: boolean) => {
    setOpenConfirmDialog(false);
    if (confirm) {
      deleteSalesEvent({
        variables: {
          input: {
            id,
          },
        },
      });
    }
  };

  const [
    deleteSalesEvent,
    {
      loading: deletingSalesEvent,
      error: deleteSalesEventError,
      data: deleteSalesEventData,
    },
  ] = useMutation(SalesEventGraphQL.mutations.deleteSalesEvent, {
    onError(error) {
      // Declaring onError here prevents GraphQL of crashing in case an unexpected error happens in the backend
      console.log(error);
    },
  });

  useEffect(() => {
    if (deleteSalesEventError) {
      dispatchAlert("Error deleting SalesEvent", "error");
    }
  }, [deleteSalesEventError]);

  useEffect(() => {
    if (deleteSalesEventData) {
      if (!deleteSalesEventData.deleteSalesEvent?.error) {
        dispatchAlert("Showcase has been succesfully deleted.", "success");
        history.push(`/sales-events`);
      }
      if (deleteSalesEventData.deleteSalesEvent?.error) {
        dispatchAlert(
          deleteSalesEventData.deleteSalesEvent?.messages[0],
          "error"
        );
      }
    }
  }, [deleteSalesEventData]);

  return loadingSalesEvent || deletingSalesEvent ? (
    <Grid item xs={12} className={pageClasses.bottomMargin}>
      <CircularProgress size={50} thickness={3} />
    </Grid>
  ) : (
    <>
      <Grid
        item
        xs={12}
        className={clsx(
          pageClasses.spacedContainer,
          pageClasses.smallBottomMargin
        )}
      >
        {backButton}
        <div className={pageClasses.toastContainer}>
          <Button
            className={pageClasses.deleteSalesEvent}
            variant="contained"
            type="button"
            color="secondary"
            onClick={deleteSalesEventRequest}
          >
            Delete Sales Event
          </Button>
          <Button
            className={pageClasses.seeShowcases}
            variant="contained"
            type="button"
            onClick={navigateToEventShowcases}
          >
            See Showcases
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={handleSaveSalesEvent}
            disabled={!title || updatingSalesEvent || startDateError}
          >
            {!updatingSalesEvent ? "Save" : <CircularProgress size={25} />}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} className={pageClasses.bottomMargin}>
        <Grid container>
          <div className={pageClasses.row}>
            <Typography variant="h1" color="textPrimary" gutterBottom={true}>
              Edit a Sales Event
            </Typography>
            <FormControlLabel
              className={pageClasses.switch}
              control={
                <Switch
                  checked={published}
                  onChange={togglePublished}
                  color="secondary"
                />
              }
              label={published ? "Published Event" : "Draft"}
            />
          </div>
        </Grid>
        <Grid>
          <TextField
            variant="outlined"
            type="text"
            id="sales-event-name"
            label="Add Sales Event Name"
            value={title}
            onChange={handleTitleChange}
            className={pageClasses.salesEventTitleInput}
            autoComplete="off"
            inputProps={{
              maxLength: 200,
            }}
            helperText={`${title.length}/200`}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <TextField
              variant="outlined"
              label="Description"
              multiline
              rows={4}
              defaultValue={description}
              inputProps={{
                maxLength: 200,
              }}
              onChange={handleDescriptionChange}
              helperText={`${description.length}/200`}
              className={pageClasses.salesEventTitleInput}
              required
              fullWidth
            />
          </Grid>
          <Grid container spacing={5}>
            <InterestsSelector
              selectedInterests={selectedInterests}
              headerTitle="Select interests for event"
              onChangeSelection={(selection: Interest[]) => {
                setSelectedInterests(selection);
              }}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Grid className={pageClasses.rowContainer}>
              <div className={pageClasses.columnContainer}>
                <DateTimePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  onAccept={evalDates}
                  disableMaskedInput
                  renderInput={(params) => (
                    <DateTimePickerTextField
                      {...params}
                      style={{
                        backgroundColor: "white",
                        marginRight: "60px",
                        marginTop: "1em",
                      }}
                    />
                  )}
                />
                <span className={pageClasses.timeError}>
                  {startDateError && `Start date should be before end date`}
                </span>
              </div>
              <div className={pageClasses.columnContainer}>
                <DateTimePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  onAccept={evalDates}
                  disableMaskedInput
                  renderInput={(params) => (
                    <DateTimePickerTextField
                      {...params}
                      style={{ backgroundColor: "white", marginTop: "1em" }}
                    />
                  )}
                />
                <span className={pageClasses.timeError}>
                  {startDateError && `End date should be after start date`}
                </span>
              </div>
            </Grid>
          </LocalizationProvider>
          <Grid>
            <FileSelector
              onSelectFile={handleSelectHeroImage}
              defaultFile={image}
            />
          </Grid>
          <Grid container spacing={5}>
            <CollectionSelector
              selectedCollectionId={collectionId}
              headerTitle="Select collection for event"
              onChangeCollection={handleSelectCollection}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}></Grid>
      </Grid>
      <ConfirmDialog
        id="confirm-delete-sales-event-dialog"
        open={!!openConfirmDialog}
        text={
          "Are you sure you want to delete this event? This will also delete all showcases associated to the event.You will not be able to undo this."
        }
        onClose={onCloseConfirmDialog}
      />
    </>
  );
};

export default SalesEventEditForm;
