import { Showcase } from "@models/showcase.model";

const ShowcasesTypes = {
  SET_SHOWCASES: "SET_SHOWCASES",
};

export const setShowcases = (data: ShowcasesData) => ({
  type: ShowcasesTypes.SET_SHOWCASES,
  data: data,
});


export type ShowcaseState = {
  showcases: Showcase[];
  total: number;
  selectedShowcase: Showcase;
};

// INITIAL STATE
export const showcasesInitialState: ShowcaseState = {
  showcases: [],
  total: 0,
  selectedShowcase: {},
};

export type ShowcasesData = {
  items: Showcase[];
  total: number;
};

export type ShowcasesAction = {
  type: string;
  data: ShowcasesData | Showcase;
};

const showcasesReducer = (state = showcasesInitialState, action: ShowcasesAction) => {
  switch (action.type) {
    case ShowcasesTypes.SET_SHOWCASES:
      const showcasesData = action.data as ShowcasesData;
      return {
        ...state,
        showcases: showcasesData.items,
        total: showcasesData.total,
      };


    default:
      return state;
  }
};

export default showcasesReducer;
