import React from "react";
import { Field } from "redux-form";
import { TextField } from "@material-ui/core";
import clsx from "clsx";

import { FieldProps } from "./index";
import useStyles from "./styles";

const renderTextField: React.FC<FieldProps> = ({
  label,
  placeholder,
  required,
  disabled,
  input,
  meta,
  ...restProps
}) => {
  return (
    <TextField
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder ? placeholder : label}
      error={meta?.touched && meta?.invalid}
      variant={meta?.variant}
      autoComplete="off"
      // helperText={touched && error}
      {...input}
      {...restProps}
    />
  );
};

const InputReduxTextField: React.FC<FieldProps> = ({
  name,
  label,
  placeholder,
  required = false,
  disabled = false,
  inputClass,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Field
      type="text"
      name={name}
      label={label}
      placeholder={placeholder}
      component={renderTextField}
      required={required}
      disabled={disabled}
      fullWidth
      className={
        inputClass ? clsx(classes.textInput, inputClass) : classes.textInput
      }
      {...restProps}
    />
  );
};

export default InputReduxTextField;
