import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    smallBottomMargin: {
      marginBottom: "16px",
    },
    bottomMargin: {
      marginBottom: "30px",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    userFilter: {
      maxWidth: "510px",
      marginBottom: "12px",
    },
    userOptionName: {
      marginTop: "6px",
      marginBottom: "10px",
    },
    userControlLabel: {
      width: "100%",
    },
    userContainer: {
      padding: "6px",
      marginRight: "16px",
    },
    parentWidth: {
      width: "100%",
    },
  })
);

export default useStyles;
