import React from "react";
import {
  Paper,
  TableCell,
  IconButton,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { NestedObject } from "@models/common";
import { TableAction } from "@components/Table";

interface Props {
  index: number;
  actions?: TableAction[];
  row: NestedObject;
  goToLink: (path: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    actionsPopper: {
      zIndex: 100,
    },
    button: {
      padding: 0,
    },
    actionOption: {
      zIndex: 100,
      backgroundColor: "white",
    },
  })
);

const StatusCell: React.JSXElementConstructor<Props> = ({
  index,
  actions,
  row,
  goToLink,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (action?: TableAction, row?: NestedObject) => () => {
    setOpen(false);
    if (action && row) {
      if (action.actionType === "link") {
        let link = action.link?.replace("<actionId>", row[action.actionId]);
        if (action?.parentId && row[action?.parentId]) {
          link = link.replace("<parentId>", row[action.parentId]);
        }
        goToLink(link);
      } else {
        if (action.pageAction) {
          action.pageAction(row[action.actionId]);
        }
      }
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <TableCell>
      <IconButton
        id={`user-row-${index}-actions-button`}
        ref={anchorRef}
        aria-label={`user-row-${index}-actions`}
        onClick={handleToggle}
        edge="end"
        className={classes.button}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.actionsPopper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose()}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {actions?.map((action: TableAction, i: number) => (
                    <MenuItem
                      className={classes.actionOption}
                      key={`user-row-${index}-action-${i}`}
                      onClick={handleClose(action, row)}
                    >
                      <Typography variant="body1" color="primary">
                        {action.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TableCell>
  );
};

export default StatusCell;
