import React, { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import useStyles from "./styles";
import { Collection } from "@models/collection.model";

export interface CollectionSelectorProps {
  headerTitle?: string;
  selectedCollectionId: string | null;
  onChangeCollection?: (collectionId: string) => void;
  showClearCollection?: boolean;
  clearCollection?: () => void
}

const CollectionSelector: React.FC<CollectionSelectorProps> = ({
  headerTitle,
  selectedCollectionId,
  onChangeCollection,
  showClearCollection,
  clearCollection
}) => {
  const pageClasses = useStyles();
  const radioGroupRef = useRef<HTMLElement>(null);
  const [filter, setFilter] = useState<string>("");
  const [filteredCollections, setFilteredCollections] = useState<Collection[]>(
    []
  );

  const collections: Collection[] = useAppSelector(
    (state: RootState) => state.collections.collections
  );

  const handleChangeCollection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    if (target.value && onChangeCollection) {
      onChangeCollection(target.value);
    }
  };

  useEffect(() => {
    if (selectedCollectionId) {
      const foundCollection = collections.find(
        (collection) => collection.id === selectedCollectionId
      );
      if (foundCollection?.title) {
        setFilter(foundCollection.title);
      }
    }
  }, [selectedCollectionId]);

  useEffect(() => {
    setFilteredCollections(
      collections
        .filter((collection) =>
          collection.title?.toLowerCase().includes(filter.toLowerCase())
        )
        .slice(0, 9)
    );
  }, [collections, filter]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const _clearCollection = () => {
    setFilter('');
    clearCollection && clearCollection();
  }

  const renderCollectionContent = (collection: Collection) => (
    <div>
      <Typography
        title={collection.title}
        className={pageClasses.collectionOptionName}
        variant="body1"
        component="p"
      >
        {collection.title}
      </Typography>
      {collection?.image && (
        <img
          src={collection?.image}
          style={{ width: 100, height: 100, objectFit: "cover" }}
        />
      )}
    </div>
  );

  const renderCollectionPaper = (collection: Collection) => (
    <Paper
      elevation={1}
      key={collection.id}
      className={clsx(
        pageClasses.collectionContainer,
        pageClasses.smallBottomMargin,
        {
          [pageClasses.parentWidth]: isMobile,
        }
      )}
    >
      <FormControlLabel
        value={collection.id}
        className={pageClasses.collectionControlLabel}
        control={<Radio />}
        label={renderCollectionContent(collection)}
      />
    </Paper>
  );

  return (
    <Grid item xs={12} md={12} className={pageClasses.bottomMargin}>
      <Typography
        variant="h2"
        color="textPrimary"
        gutterBottom={true}
        className={pageClasses.smallBottomMargin}
      >
        {headerTitle}
      </Typography>

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <TextField
          variant="outlined"
          type="text"
          id="collection-name"
          label="Search by Collection Name"
          value={filter}
          onChange={handleChangeFilter}
          className={pageClasses.collectionFilter}
          autoComplete="off"
          inputProps={{
            maxLength: 200,
          }}
          required
          fullWidth
        />
        {showClearCollection && (
          <div
            style={{
              marginLeft: "1em",
              paddingBottom: "1em",
              cursor: "pointer",
            }}
            onClick={_clearCollection}
          >
            Clear
          </div>
        )}
      </div>

      <RadioGroup
        ref={radioGroupRef}
        aria-label="user-collection"
        name="user-collection"
        value={selectedCollectionId}
        onChange={handleChangeCollection}
        className={pageClasses.rowWrapContainer}
      >
        {filteredCollections.map((collection: Collection) =>
          renderCollectionPaper(collection)
        )}
      </RadioGroup>
    </Grid>
  );
};

export default CollectionSelector;
